import React, { useState, useEffect } from 'react';
import { bizConfig } from '../../../utils/bizConfig';
import TableInput from '../../../components/Table';
import { toDate } from '../../../utils/utils';

function ListaCubasPage(props) {
    const { idProducao, setSelectedMenu, setOperacao, setItemSelecionado, setIdCuba, status } = props;
    const [cubas, setCubas] = useState(null);

    useEffect(() => {
        if (!idProducao) return;
        ConsultarCubas();
    }, [idProducao]);

    const ConsultarCubas = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqProducaoCubas/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setCubas(response);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }
    return (
        <>
            <div className="w-100 my-2" style={{ textAlign: "right" }}>
                {!bizConfig.online && status == 'IN' &&
                    <a
                        className='b-1 p-1'
                        onClick={() => { 
                            setSelectedMenu('cadastroCuba'); 
                            setOperacao(1);  
                            setIdCuba("");  
                        }}
                    >
                        + Nova cuba
                    </a>}
                <a
                    className='b-1 p-1'
                    style={{ marginLeft: "20px" }}
                    onClick={() => {
                        setSelectedMenu('apontamentos');
                    }}
                >Voltar</a>
            </div>
            {cubas && <TableInput
                children={
                    [
                        <thead key={'thead-cuba'}>
                            <tr>
                                <td>Nº cuba estoque</td>
                                <td>Descrição</td>
                                <td>Lote</td>
                                <td>Fórmula</td>
                                <td className='text-center'>Fabricação</td>
                                <td className='text-center'>Validade</td>
                                <td className='text-right'>Densidade</td>
                                <td className='text-center'>Hora da troca</td>
                                <td>Inspetor</td>
                                <td>Operador</td>
                                <td className='text-center'>#</td>
                            </tr>
                        </thead>,
                        <tbody key={'tbody-cuba'}>
                            {
                                cubas.map((cuba, index) =>
                                (
                                    
                                    <tr className='desktop' key={`tr-cuba-${index}`}>
                                        <td>{cuba.inq_num_cuba_estoque}</td>
                                        <td>{cuba.inq_descricao_cuba}</td>
                                        <td>{cuba.inq_lote}</td>
                                        <td>{cuba.inq_formula}</td>
                                        <td className='text-center'>{toDate(cuba.inq_fabricacao)}</td>
                                        <td className='text-center'>{toDate(cuba.inq_validade)}</td>
                                        <td className='text-right'>{cuba.inq_densidade.toString().replace(".", ",") + ' ' + cuba.inq_nome_densidade}</td>
                                        <td className='text-center'>{cuba.inq_horario_troca}</td>
                                        <td>{cuba.inq_apelido_responsavel}</td>
                                        <td>{cuba.inq_nome_operador}</td>
                                        <td className='text-center'>
                                            {!bizConfig.online && status == 'IN' &&
                                                <a
                                                    onClick={() => {
                                                        setSelectedMenu('cadastroCuba');
                                                        setOperacao(2);
                                                        setItemSelecionado(cuba);
                                                        setIdCuba(cuba.inq_id_cuba);
                                                    }}
                                                >
                                                    Editar
                                                </a>}
                                            {!bizConfig.online && status == 'IN' &&
                                                <a
                                                    style={{ color: "red" }}
                                                    className='ml-1'
                                                    onClick={() => {
                                                        setSelectedMenu('cadastroCuba');
                                                        setOperacao(3);
                                                        setItemSelecionado(cuba);
                                                        setIdCuba(cuba.inq_id_cuba);
                                                    }}
                                                >
                                                    Excluir
                                                </a>}
                                        </td>
                                    </tr>
                                ))
                            }
                            {
                                cubas.map((cuba, index) =>
                                (
                                    <tr className='mobile' key={`tr2-cuba-${index}`}>
                                        <td>
                                            <span className='td2Colunas'><strong>Nº cuba estoque:&nbsp;</strong>{cuba.inq_num_cuba_estoque}</span>
                                            <span><strong>Descrição:&nbsp;</strong>{cuba.inq_descricao_cuba}</span>
                                        </td>
                                        <td>
                                            <span className='td2Colunas'><strong>Lote:&nbsp;</strong>{cuba.inq_lote}</span>
                                            <span><strong>Fórmula:&nbsp;</strong>{cuba.inq_formula}</span>
                                        </td>
                                        <td>
                                            <span className='td2Colunas'><strong>Fabricação:&nbsp;</strong>{toDate(cuba.inq_fabricacao)}</span>
                                            <span><strong>Validade:&nbsp;</strong>{toDate(cuba.inq_validade)}</span>
                                        </td>
                                        <td>
                                            <span className='td2Colunas'><strong>Densidade:&nbsp;</strong>{cuba.inq_densidade + ' ' + cuba.inq_nome_densidade}</span>
                                            <span><strong>Hora da troca:&nbsp;</strong>{cuba.inq_horario_troca}</span>
                                        </td>
                                        <td>
                                            <span className='td2Colunas'><strong>Responsável:&nbsp;</strong>{cuba.inq_apelido_responsavel}</span>
                                            </td>
                                        <td>
                                        <span className='td2Colunas'><strong>Responsável:&nbsp;</strong>{cuba.inq_nome_operador}</span>
                                            <span>
                                                {!bizConfig.online && status == 'IN' &&
                                                    <a
                                                        onClick={() => {
                                                            setSelectedMenu('cadastroCuba');
                                                            setOperacao(2);
                                                            setItemSelecionado(cuba);
                                                            setIdCuba(cuba.inq_id_cuba);
                                                        }}
                                                    >
                                                        Editar
                                                    </a>}
                                                {!bizConfig.online && status == 'IN' &&
                                                    <a
                                                        style={{ color: "red" }}
                                                        className='ml-1'
                                                        onClick={() => {
                                                            setSelectedMenu('cadastroCuba');
                                                            setOperacao(3);
                                                            setItemSelecionado(cuba);
                                                            setIdCuba(cuba.inq_id_cuba);
                                                        }}
                                                    >
                                                        Excluir
                                                    </a>}
                                            </span>
                                        </td>
                                    </tr>
                                ))

                            }
                        </tbody>
                    ]
                }
            />}
        </>
    );
}

export default ListaCubasPage;