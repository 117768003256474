import React from 'react';
import {
  HashRouter as Router,
  Switch, Redirect,
  Route,
} from "react-router-dom";

import './index.css';
import ThemeStyle from './styles/Theme';
import { AuthProvider } from './context/Auth';
import { isAuthenticated } from './utils/token';
import Logout from './pages/Logout';
import Login from './pages/Login';
import ChangePassword from './pages/ChangePassword';
import ForgotMyPassword from './pages/ForgotMyPassword';
import PasswordRecovery from './pages/PasswordRecovery';
import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
import Barcode from './pages/Barcode';
import NovaProducao from './pages/Producao/NovaProducao';
import ListaProducao from './pages/Producao/ListaProducao';
import Producao from './pages/Producao/Producao';
import ListaApontamentos from './pages/Apontamentos/ListaApontamentos';
function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => isAuthenticated() === true
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
      />
    )
  }

  return (
    <AuthProvider>
      <ThemeStyle />
      <Router>
        <Switch>
          <Route path="/redirect" component={props => {
            const queryParams = new URLSearchParams(props.location.search);
            window.location.href = (queryParams.get('to'));
            return null;
          }} />
          <Route path="/logout" component={Logout} />
          <Route path="/login" component={Login} />
          <Route path="/entrar" component={Login} />
          <Route path="/trocar-senha" component={ChangePassword} />
          <Route path="/esqueci-minha-senha" component={ForgotMyPassword} />
          <Route path="/recuperacao-de-senha" component={PasswordRecovery} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/barcode" component={Barcode} />
          <PrivateRoute path="/nova-producao" component={NovaProducao} />
          <PrivateRoute path="/producao-editar/:idProducao/:possuiPesagemEdicao/:status" component={NovaProducao} />
          <PrivateRoute path="/lista-producao/:status" component={ListaProducao} />
          <PrivateRoute path="/producao/:idProducao/:possuiPesagemProducao/:status" component={Producao} />
          <PrivateRoute path="/editar-producao/:idProducao/:possuiPesagemProducao/:status/:idFormularioEdicao/:idSequenciaEdicao" component={Producao} />
          <PrivateRoute path="/lista-apontamentos/:idProducao/:idFormulario/:status/:possuiPesagemProducao" component={ListaApontamentos} />
          <PrivateRoute path="/producao" component={Producao} />
          <PrivateRoute path="/" component={ListaProducao} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
