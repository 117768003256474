import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from "react-router-dom";
import logo from 'assets/logo/logo.svg';
import logoUniversal from 'assets/logo/unichem 2 color.webp';
import menuOpen from 'assets/menu-opened.svg';
import menuClose from 'assets/menu-closed.svg';
import arrowDown from 'assets/icon-arrow-down.svg';
import { h2r, h3r, body3, body4 } from 'components/Type';
import Button from 'components/Button';

const Menu = styled.header`
  min-width: 272px;
  width: 272px;
  height: 100vh;
  position: relative;

  @media(max-width: 1024px)  /* 940px */ {
    min-width: 100vw;
    width: 100vw;
    position: fixed;
    transform: translateX(-100vw);
    transition: transform .7s ease-in;
    ${props => props.opened ? 'transform: translateX(0px);' : ''}
    z-index: 900;
  }
`;

const MenuWrapper = styled.div`
  width: 272px;
  height: 100vh;
  background: #F6F6F4;
  margin: 0;
  box-sizing: border-box;
  padding: 40px 40px 56px;

  @media(max-width: 1024px) /* 940px */  {
    width: 100vw;
    padding-bottom: 90px;
  }
`;

const MenuContainer = styled.div`
  background: #F6F6F4;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
`;

const MenuLogo = styled.img`
  max-height: 38px;
  height: 38px;
`;

const MenuNav = styled.nav`
  display: flex;
  flex-flow: column wrap;
  width: 100%;

  a {
    ${h3r}
    text-decoration: none;

    &::before {
      content: '● ';
      font-size: 0px;
      line-height: 32px;
      vertical-align: text-bottom;
      transition: font-size .3s ease-in-out;
    }

    &:hover {
      text-decoration: none;

      &::before {
        content: '● ';
        font-size: 24px;
      }
    }

    &.active {
      text-decoration: none;

      &::before {
        content: '● ';
        font-size: 24px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    @media(max-width: 1024px)  /* 940px */ {
      ${h2r}
    }
  }
`;

const MenuNavContainer = styled.div`
  height: 100%;
  color: #1A1A1A;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const UserNav = styled.nav`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  position: relative;

  .myaccount {
    ${body3}
    padding: 0;
    display: block;
    width: 100%;
    padding: 10px 0;
    text-align: left;
    background: transparent;
    border: none;
    color: #1A1A1A;
    text-decoration: none;

    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    outline: none;

    &:hover:not(:disabled) {
      text-decoration: underline;
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.9;
    }

    img {
      margin-left: 14px;
      vertical-align: middle;
      transform: rotate(180deg);
    }
  }

  .helpus {
    ${body4}
    color: #807E7D;
    margin: 14px 0px 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .header__dropdownCbx {
    display: none;

    &:checked + .myaccount img {
      transform: rotate(0deg);
    }

    &:checked + .myaccount + .header__dropdown {
      position: absolute;
      inset: 0px auto auto 0px;
      margin: 0px;
      transform: translate3d(0px, calc(-100% - 82px),0px);
      display: block;
      top: 100%;
    }
  }

  .header__dropdown {
    ${body4}
    position: absolute;
    z-index: 900;
    display: none;
    min-width: 192px;
    box-sizing: border-box;
    padding: 8px 0;
    margin: 0;
    color: #1A1A1A;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.08);

    li {
      ${body4}
      color: #1A1A1A;
      text-align: left;
      list-style: none;
      cursor: pointer;
    }

    a {
      ${body4}
      display: block;
      width: 100%;
      padding: 12px 16px;
      clear: both;
      color: #1A1A1A;
      text-align: inherit;
      text-decoration: none;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
      box-sizing: border-box;

      &:hover {
        background-color: #F6F6F4;
      }
    }
  }
`;

const MobileButton = styled.button`
  display: none;
  position: fixed;
  padding: 0;
  width: 60px;
  height: 60px;
  left: 32px;
  bottom: 8px;
  background: #009051;
  box-shadow: -2px 0px 5px rgba(26, 26, 26, 0.08);
  border-radius: 50px;
  z-index: 901;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;

  @media(max-width: 1024px)  /* 940px */ {
    display: block;
  }

  .header__buttons {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header__img {
    position: absolute;
    opacity: 0;
    transition: opacity .4s ease-in;
  }

  .header__img--open {
    opacity: ${props => props.opened ? '1' : '0'};
  }

  .header__img--close {
    opacity: ${props => props.opened ? '0' : '1'};
  }
`;

function MenuComponent({
  variant,
}) {
  const [opened, setOpened] = useState(false);
  let location = useLocation();

  return (
    <>
      <MobileButton opened={opened} onClick={ () => setOpened(!opened) }>
        <div className="header__buttons">
          <img className="header__img header__img--open" src={menuOpen} alt="" />
          <img className="header__img header__img--close" src={menuClose} alt="" />
        </div>
      </MobileButton>
      <Menu opened={opened} variant={variant}>
        <MenuWrapper>
          <MenuContainer>
            {/* <MenuLogo src={logo} alt="Universal Chemical" /> */}
            <MenuLogo src={logoUniversal} alt="Universal Chemical" />
            <MenuNavContainer>
              <MenuNav>
                <Button className={location.pathname === '/lista-producao' ? 'active' : ''} variant="tertiary" to="/lista-producao/IN">Em andamento</Button>
                <Button className={location.pathname === '/lista-producao' ? 'active' : ''} variant="tertiary" to="/lista-producao/FI">Finalizadas</Button>
              </MenuNav>
            </MenuNavContainer>
            <UserNav>
              <input className="header__dropdownCbx" id={`header--cbx`} type="checkbox" />
              <label htmlFor="header--cbx" className="myaccount" variant="tertiary">
                Minha Conta
                <img src={arrowDown} alt="Minha Conta" />
              </label>
              <ul className="header__dropdown">
                <li style={{ display: 'none' }}>
                  <Link className="header__dropdownItem" to="/perfil">
                    Meu Perfil
                  </Link>
                </li>
                <li>
                  <Link className="header__dropdownItem" to="/logout">
                    Sair
                  </Link>
                </li>
              </ul>
              <Button className="helpus" variant="tertiary" target="_blank" to="/redirect?to=mailto:meajuda@simplesnologia.biz">Reportar problemas</Button>
            </UserNav>
          </MenuContainer>
        </MenuWrapper>
      </Menu>
    </>
  )
};

MenuComponent.defaultProps = {
  variant: 'default'
};

export default MenuComponent;
