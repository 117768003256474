import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import InternalLayout from '../../../components/InternalLayout';
import Divider from '../../../components/Divider';
import Loading from '../../../components/Loading';
import Typography from '../../../components/Typography';
import InputWrapper from '../../../components/Input/Default';
import MaskedInputWrapper from '../../../components/Input/Masked';
import SimpleSelectInput from '../../../components/Input/SimpleSelect';
import Button from 'components/Button';
import { toDateSQL, toDate8, dataValida } from '../../../utils/utils';
import { bizConfig } from '../../../utils/bizConfig';
import { getData } from 'utils/token';


const Section = styled.section`
  hr {
    margin: 0;
  }
`;


const Header = styled.header`
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-weight: 400;
    width: 100%;
    text-align: left;
  }
`;

function NovaProducaoPage(props) {
    const { match } = props;

    const idProducao = match?.params?.idProducao;
    const status = match?.params?.status;
    const possuiPesagem = match?.params?.possuiPesagemEdicao;

    const [codigoProduto, setCodigoProduto] = useState();
    const [dataAcondicionamento, setDataAcondicionamento] = useState();
    const [linha, setLinha] = useState();
    const [lote, setLote] = useState();
    const [unificadorLote, setUnificadorLote] = useState();
    const [dun1, setDun1] = useState();
    const [dun2, setDun2] = useState();
    const [dun3, setDun3] = useState();
    const [qrcode1, setQrcode1] = useState();
    const [qrcode2, setQrcode2] = useState();
    const [qrcode3, setQrcode3] = useState();
    const [validadeLote, setValidadeLote] = useState();
    const [turno, setTurno] = useState();
    const [turnoEdicao, setTurnoEdicao] = useState();
    const [linhaEdicao, setLinhaEdicao] = useState();

    const [validForm, setValidForm] = useState();
    const [disabledForm, setDisabledForm] = useState(false);

    const [showLoader, setShowLoader] = useState(true);
    const [infosProduto, setInfosProduto] = useState();
    const [optionsLinhas, setOptionsLinhas] = useState(null);
    const [optionsTurnos, setOptionsTurnos] = useState(null);
    const userData = getData();

    useEffect(() => {
        ConsultarOptionLinhas();
    }, []);

    useEffect(() => {
        if (!codigoProduto) return;
        ConsultarInformacoesProduto();
    }, [codigoProduto]);

    useEffect(() => {

        if (!codigoProduto || !infosProduto || !infosProduto?.prd_codigo) {
            setValidForm(false);
            return;
        }

        if (!dataValida(dataAcondicionamento)) {
            setValidForm(false);
            return;
        }

        if (!linha || linha.length < 3) {
            setValidForm(false);
            return;
        }

        if (!lote || lote.length < 4) {
            setValidForm(false);
            return;
        }

        if (!validadeLote) {
            setValidForm(false);
            return;
        }

        if (!turno || turno.length < 3) {
            setValidForm(false);
            return;
        }


        setValidForm(true);

    }, [codigoProduto, dataAcondicionamento, linha, lote, validadeLote, infosProduto, turno, unificadorLote, dun1, dun2, dun3, qrcode1, qrcode2, qrcode3]);

    const ConsultarOptionLinhas = () => {
        let requestInfo = {
            method: 'POST',
            headers: bizConfig.requestHeaders,
            body: JSON.stringify([
                {
                    "nome": "cad_empresa",
                    "valor": bizConfig.cad_empresa
                },
                {
                    "nome": "cad_filial",
                    "valor": bizConfig.cad_filial
                },
                {
                    "nome": "tipo",
                    "valor": "ddl_inq_linhas"
                }
            ])
        };
        fetch(`${bizConfig.rootApi}/DropDown`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setOptionsLinhas(response)
                setLinha(response[0].codigo);
                setShowLoader(false);
                ConsultarOptionTurnos();
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const ConsultarOptionTurnos = () => {
        let requestInfo = {
            method: 'POST',
            headers: bizConfig.requestHeaders,
            body: JSON.stringify([
                {
                    "nome": "cad_empresa",
                    "valor": bizConfig.cad_empresa
                },
                {
                    "nome": "cad_filial",
                    "valor": bizConfig.cad_filial
                },
                {
                    "nome": "tipo",
                    "valor": "ddl_inq_turnos"
                }
            ])
        };
        fetch(`${bizConfig.rootApi}/DropDown`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setOptionsTurnos(response)
                setTurno(response[0].codigo);
                setShowLoader(false);

                if ((idProducao) && (status)) {
                    ConsultarDadosProducao();
                }

            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const ConsultarInformacoesProduto = () => {
        setInfosProduto(null);
        setDisabledForm(true);
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/produto/${bizConfig.cad_empresa}/${codigoProduto}/${codigoProduto}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setInfosProduto(response);
                setDisabledForm(false);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
                setDisabledForm(false);
            })
    }

    const ConsultarDadosProducao = () => {
        setDisabledForm(true);
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqProducao/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}/${status}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                if (response.prd_codigo) {
                    setCodigoProduto(response.prd_codigo);
                    setDataAcondicionamento(toDate8(response.inq_data_acondicionamento));
                    setLinha(response.inq_id_linha);
                    setLote(response.inq_lote);
                    setValidadeLote(response.inq_data_validade_lote);
                    setUnificadorLote(response.inq_unificador_lote);
                    setDun1(response.inq_dun_1);
                    setDun2(response.inq_dun_2);
                    setDun3(response.inq_dun_3);
                    setQrcode1(response.inq_qrcode_1);
                    setQrcode2(response.inq_qrcode_2);
                    setQrcode3(response.inq_qrcode_3);
                    setTurno(response.inq_id_turno);
                    setTurnoEdicao((<option value={response.inq_id_turno}>{response.inq_nome_turno}</option>));
                    setLinhaEdicao((<option value={response.inq_id_linha}>{response.inq_nome_linha}</option>));
                }
                setDisabledForm(false);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
                setDisabledForm(false);
            })
    }

    const onSubmit = async (e) => {
        if (e) e.preventDefault();

        let requestInfo = {
            method: 'POST',
            headers: bizConfig.requestHeaders,
            body: JSON.stringify(
                {
                    "cad_empresa": bizConfig.cad_empresa,
                    "cad_filial": bizConfig.cad_filial,
                    "prd_referencia": infosProduto.prd_codigo,
                    "prd_codigo": infosProduto.prd_codigo,
                    "inq_id_linha": linha,
                    "inq_lote": lote,
                    "inq_unificador_lote": unificadorLote ? unificadorLote : "",
                    "inq_dun_1": dun1 ? dun1 : "",
                    "inq_dun_2": dun2 ? dun2 : "",
                    "inq_dun_3": dun3 ? dun3 : "",
                    "inq_qrcode_1": qrcode1 ? qrcode1 : "",
                    "inq_qrcode_2": qrcode2 ? qrcode2 : "",
                    "inq_qrcode_3": qrcode3 ? qrcode3 : "",
                    "inq_data_acondicionamento": toDateSQL(dataAcondicionamento),
                    "inq_data_validade_lote": validadeLote,
                    "inq_motivo_cancelamento": "",
                    "inq_usuario": userData.usuario,
                    "inq_motivo_alteracao": "",
                    "inq_id_status_producao": "",
                    "inq_id_turno": turno,
                    "inq_id_producao": idProducao ? idProducao : "",
                    "operacao": idProducao ? 2 : 1
                }
            )
        };
        fetch(`${bizConfig.rootApi}/InqProducao`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response;
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                if (idProducao) {
                    window.location.href = `/#/producao/${idProducao}/${possuiPesagem}/${status}`;
                }
                else {
                    window.location.href = '/#/lista-producao';
                }
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    return (
        <InternalLayout>
            <Loading show={showLoader} size="big" />
            <Section>
                <Header>
                    <Typography variant="h1">Inspeção de qualidade</Typography>
                </Header>
                <Divider variant="dark" />
            </Section>
            <Section>
                <Header>
                    <Typography variant="h3">Nova linha de produção</Typography>
                </Header>
                <form noValidate onSubmit={onSubmit} autoComplete="off">
                    <div className={'colsForm'}>
                        <InputWrapper
                            id="codigoProduto"
                            name="codigoProduto"
                            label="Código"
                            onBlur={(e) => {
                                setCodigoProduto(e.target.value);
                            }}
                            value={codigoProduto}
                            disabled={idProducao}
                        />
                        <InputWrapper
                            id="nomeProduto"
                            name="nomeProduto"
                            label="Produto"
                            value={infosProduto?.prd_descricao || ""}
                            disabled={true}
                        />
                        <MaskedInputWrapper
                            id="dataAcondicionamento"
                            name="dataAcondicionamento"
                            label="Data de acondicionamento"
                            mask="99/99/99"
                            onChange={(e) => {
                                setDataAcondicionamento(e.target.value);
                            }}
                            value={dataAcondicionamento}
                            disabled={disabledForm}
                        />
                        {optionsLinhas && <SimpleSelectInput
                            id="linha"
                            name="linha"
                            label="Linha"
                            options={optionsLinhas.map(item => (<option value={item.codigo}>{item.display}</option>))}
                            onChange={(e) => {
                                setLinha(e?.props?.value);
                                setLinhaEdicao(e);
                            }}
                            disabled={disabledForm}
                            value={linhaEdicao}
                        />}
                        <InputWrapper
                            id="lote"
                            name="lote"
                            label="Lote"
                            onChange={(e) => {
                                setLote(e.target.value);
                            }}
                            disabled={disabledForm}
                            value={lote}
                        />
                        <InputWrapper
                            id="unificadorLote"
                            name="unificadorLote"
                            label="Unificador dos lotes"
                            onChange={(e) => {
                                setUnificadorLote(e.target.value);
                            }}
                            disabled={disabledForm || infosProduto?.cad_solicita_unificador_lote != 1}
                            value={unificadorLote}
                        />

                        <InputWrapper
                            id="dun1"
                            name="dun1"
                            label="DUN 1"
                            onChange={(e) => {
                                setDun1(e.target.value);
                            }}
                            disabled={disabledForm || infosProduto?.cad_solicita_unificador_lote != 1}
                            value={dun1}
                        />
                        <InputWrapper
                            id="dun2"
                            name="dun2"
                            label="DUN 2"
                            onChange={(e) => {
                                setDun2(e.target.value);
                            }}
                            disabled={disabledForm || infosProduto?.cad_solicita_unificador_lote != 1}
                            value={dun2}
                        />
                        <InputWrapper
                            id="dun3"
                            name="dun3"
                            label="DUN 3"
                            onChange={(e) => {
                                setDun3(e.target.value);
                            }}
                            disabled={disabledForm || infosProduto?.cad_solicita_unificador_lote != 1}
                            value={dun3}
                        />

                        <InputWrapper
                            id="qrcode1"
                            name="qrcode1"
                            label="QR Code 1"
                            onChange={(e) => {
                                setQrcode1(e.target.value);
                            }}
                            disabled={disabledForm || infosProduto?.cad_solicita_unificador_lote != 1}
                            value={qrcode1}
                        />
                        <InputWrapper
                            id="qrcode2"
                            name="qrcode2"
                            label="QR Code 2"
                            onChange={(e) => {
                                setQrcode2(e.target.value);
                            }}
                            disabled={disabledForm || infosProduto?.cad_solicita_unificador_lote != 1}
                            value={qrcode2}
                        />
                        <InputWrapper
                            id="qrcode3"
                            name="qrcode3"
                            label="QR Code 3"
                            onChange={(e) => {
                                setQrcode3(e.target.value);
                            }}
                            disabled={disabledForm || infosProduto?.cad_solicita_unificador_lote != 1}
                            value={qrcode3}
                        />


                        <MaskedInputWrapper
                            id="validadeLote"
                            name="validadeLote"
                            label="Validade do lote (mês e ano)"
                            mask="99/99"
                            onChange={(e) => {
                                setValidadeLote(e.target.value);
                            }}
                            disabled={disabledForm}
                            value={validadeLote}
                        />
                        {optionsTurnos && <SimpleSelectInput
                            id="turno"
                            name="turno"
                            label="Turno"
                            options={optionsTurnos.map(item => (<option value={item.codigo}>{item.display}</option>))}
                            onChange={(e) => {
                                setTurno(e?.props?.value);
                                setTurnoEdicao(e);
                            }}
                            disabled={disabledForm}
                            value={turnoEdicao}
                        />}
                    </div>
                </form>
                <div className='w-100 text-right'>
                    <Button
                        label="Cancelar"
                        theme="danger"
                        onClick={() => {
                            if (idProducao) {
                                window.location.href = `/#/producao/${idProducao}/${possuiPesagem}/${status}`;
                            }
                            else {
                                window.location.href = '/#/lista-producao'
                            }
                        }}
                    />
                    <Button
                        label="Salvar"
                        className={'ml-1'}
                        disabled={!validForm}
                        onClick={() => {
                            onSubmit();
                        }}
                    />
                </div>
            </Section>
        </InternalLayout >
    );
}

export default NovaProducaoPage;