import React, { useState, useEffect } from 'react';
import ListaCubas from '../../Cubas/ListaCubas';
import CadastroCubaPage from '../../Cubas/CadastroCuba';
import ListaFormularios from '../../Apontamentos/ListaFormularios';
import LancamentosFormularios from '../../Apontamentos/LancamentosFormularios';
import Documentos from '../../Documentos';

function LancamentoProducaoPage(props) {
    const {idProducao, possuiPesagemProducao, status, idFormularioEdicao, idSequenciaEdicao}  = props;
    const [selectedMenu, setSelectedMenu] = useState("apontamentos");
    const [operacao, setOperacao] = useState();
    const [idCuba, setIdCuba] = useState("");
    const [itemSelecionado, setItemSelecionado] = useState();
    const [idFormulario, setIdFormulario] = useState();
    const [possuiPesagem, setPossuiPesagem] = useState(idFormularioEdicao && possuiPesagemProducao == "1" ? possuiPesagemProducao : null);
    const [periodicidade, setPeriodicidade] = useState();
    const [nomeFormulario, setNomeFormulario] = useState();
    const [lancamentoPOSFinalizar, setLancamentoPOSFinalizar] = useState();

    let divTopo = document.getElementsByClassName("sc-gGCDDS")[0];
    if (divTopo){
      divTopo.scrollTop = 0;
    }
    
    return (
        <div>
            {selectedMenu == 'cuba' && <ListaCubas idProducao={idProducao} setSelectedMenu={setSelectedMenu} setOperacao={setOperacao} setItemSelecionado={setItemSelecionado} setIdCuba={setIdCuba} status={status}/>}
            {selectedMenu == 'cadastroCuba' && <CadastroCubaPage idProducao={idProducao} setSelectedMenu={setSelectedMenu} operacao={operacao} itemSelecionado={itemSelecionado} idCuba={idCuba} />}
            {selectedMenu == 'apontamentos' && <ListaFormularios
                idProducao={idProducao}
                idFormulario={idFormulario}
                idFormularioEdicao={idFormularioEdicao}
                idSequenciaEdicao={idSequenciaEdicao}
                setSelectedMenu={setSelectedMenu}
                setIdFormulario={setIdFormulario}
                setNomeFormulario={setNomeFormulario}
                setPeriodicidade={setPeriodicidade}
                setPossuiPesagem={setPossuiPesagem}
                possuiPesagem={possuiPesagem || possuiPesagemProducao}
                setLancamentoPOSFinalizar={setLancamentoPOSFinalizar}
                status={status}
            />}
            {selectedMenu == 'apontamentosFormulario' && <LancamentosFormularios
                idProducao={idProducao}
                idFormulario={idFormulario}
                idFormularioEdicao={idFormularioEdicao}
                idSequenciaEdicao={idSequenciaEdicao}
                setSelectedMenu={setSelectedMenu}
                nomeFormulario={nomeFormulario}
                periodicidade={periodicidade}
                possuiPesagem={possuiPesagem}
                lancamentoPOSFinalizar={lancamentoPOSFinalizar}
                status={status}
            />}
            {selectedMenu == 'documentos' && <Documentos />}
        </div>
    );
}

export default LancamentoProducaoPage;