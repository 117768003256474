import React from 'react';
import styled, { css } from 'styled-components';
import Loader from 'components/Loader';
import { Link } from 'react-router-dom';
import { h5m, body3 } from 'components/Type';

const Button = ({ theme }) => css`
  ${h5m}
  border: none;
  border-radius: 40px;
  background-clip: padding-box;
  background: ${theme.background};
  color: ${theme.color};
  border: ${(theme.border ? `1px solid ${theme.border}` : 'inherit')};
  text-decoration: none;

  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;

  ${props => props.loading ? `
  position: relative;
  user-select: none;
  color: transparent !important;
  ` : ''}

  .loader {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;

    > div {
      position: absolute;
    }
  }

  &:hover {
    background: ${theme.backgroundHover};
    cursor: pointer;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed !important;
    opacity: 0.9;
    background: #D0D0D0 !important;
    color: #B2B1B0;
    border: ${(theme.borderDisabled ? `1px solid ${theme.borderDisabled}` : 'inherit')};
  }
`;

const ButtonObject = styled.button`
  padding: 12px 40px;
  ${Button};
`;

const ButtonLink = styled(Link)`
  padding: 18px 40px;
  ${Button};
`;

const buttonThemes = {
  default: {
    background: '#009051',
    backgroundHover: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #009051',
    color: '#000',
  },
  danger: {
    background: '#b13128',
    backgroundHover: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #b13128',
    color: '#fff',
  },
  inverse: {
    background: '#1A1A1A',
    backgroundHover: 'linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #1A1A1A',
    color: '#fff',
  },
  ghost: {
    background: 'transparent',
    backgroundHover: 'rgba(255, 255, 255, 0.1)',
    color: '#FFFDFB',
    border: 'rgba(255, 253, 251, 1)',
    borderDisabled: 'rgba(255, 253, 251, 0.9)'
  },
  ghostInverse: {
    background: 'transparent',
    backgroundHover: 'rgba(26, 26, 26, 0.1)',
    color: '#1A1A1A',
    border: '#B3B1B0',
    borderDisabled: 'rgba(26, 26, 26, 0.9)'
  },
};

const ButtonSecondary = ({ theme }) => css`
  ${h5m}
  background: transparent;
  border: none;
  color: ${theme.color};
  text-decoration: none;

  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:disabled,
  &[disabled] {
    text-decoration: none !important;
    cursor: not-allowed !important;
    color: #B2B1B0;
    pointer-events: none;
  }
`;

const ButtonSecondaryObject = styled.button`
  ${ButtonSecondary};
`;

const ButtonSecondaryLink = styled(Link)`
  ${ButtonSecondary};
`;

const buttonSecondaryThemes = {
  default: {
    color: '#FFFFFF',
  },
  primary: {
    color: '#009051',
  }
};

const ButtonTertiary = ({ theme }) => css`
  ${body3}
  background: transparent;
  border: none;
  color: ${theme.color};
  text-decoration: none;

  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:disabled,
  &[disabled] {
    text-decoration: none !important;
    cursor: not-allowed;
    color: #B2B1B0;
    pointer-events: none;
  }
`;

const ButtonTertiaryObject = styled.button`
  ${ButtonTertiary};
`;

const ButtonTertiaryLink = styled(Link)`
  ${ButtonTertiary};
`;

const buttonTertiaryThemes = {
  default: {
    color: '#000',
  },
  primary: {
    color: '#009051',
  },
};

const ButtonIcon = ({ theme }) => css`
  background: ${theme.background};
  border: ${(theme.border ? `1px solid ${theme.border}` : 'inherit')};
  border-radius: 32px;
  width: 32px;
  height: 32px;
  padding: 12px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background .4s ease-in;

  img {
    filter: ${theme.filter};
    width: 12px;
  }

  &:hover {
    background: ${theme.backgroundHover};
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed !important;
    opacity: 0.9;
    background: #D0D0D0 !important;
    color: #B2B1B0;
    border: ${(theme.borderDisabled ? `1px solid ${theme.borderDisabled}` : 'inherit')};
  }
`;

const ButtonIconObject = styled.button`
  ${ButtonIcon};
`;

const ButtonIconLink = styled(Link)`
  ${ButtonIcon};
`;

const buttonIconThemes = {
  default: {
    background: '#009051',
    backgroundHover: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #009051',
    color: '#000',
    filter: 'brightness(0) invert(1)',
  },
  inverse: {
    background: '#1A1A1A',
    backgroundHover: 'linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #1A1A1A',
    color: '#fff',
    filter: 'brightness(100) invert(1)'
  },
  ghost: {
    background: 'transparent',
    backgroundHover: 'rgba(255, 255, 255, 0.1)',
    color: '#FFFDFB',
    border: 'rgba(255, 253, 251, 1)',
    borderDisabled: 'rgba(255, 253, 251, 0.9)',
    filter: 'brightness(0) invert(1)'
  },
  ghostInverse: {
    background: 'transparent',
    backgroundHover: 'rgba(26, 26, 26, 0.1)',
    color: '#1A1A1A',
    border: '#B3B1B0',
    borderDisabled: 'rgba(26, 26, 26, 0.9)',
    filter: 'brightness(100) invert(1)'
  },
};

function ButtonInput({
  variant, label, to, onClick, theme, children, disabled, loading, ...otherProps
}) {
  let newTheme = buttonThemes[theme];

  if (variant === 'secondary') {
    newTheme = buttonSecondaryThemes[theme];

    if (to) {
      return (
        <ButtonSecondaryLink
          theme={newTheme}
          onClick={onClick}
          to={to}
          disabled={disabled}
          {...otherProps}
        >
          {label || children}
        </ButtonSecondaryLink>
      );
    } else {
      return (
        <ButtonSecondaryObject
          theme={newTheme}
          onClick={onClick}
          to={to}
          disabled={disabled}
          {...otherProps}
        >
          {label || children}
        </ButtonSecondaryObject>
      );
    }
  } else if (variant === 'tertiary') {
    newTheme = buttonTertiaryThemes[theme];

    if (to) {
      return (
        <ButtonTertiaryLink
          theme={newTheme}
          onClick={onClick}
          to={to}
          disabled={disabled}
          {...otherProps}
        >
          {label || children}
        </ButtonTertiaryLink>
      );
    } else {
      return (
        <ButtonTertiaryObject
          theme={newTheme}
          onClick={onClick}
          to={to}
          disabled={disabled}
          {...otherProps}
        >
          {label || children}
        </ButtonTertiaryObject>
      );
    }
  } else if (variant === 'icon') {
    newTheme = buttonIconThemes[theme];

    if (to) {
      return (
        <ButtonIconLink
          theme={newTheme}
          onClick={onClick}
          to={to}
          disabled={disabled}
          {...otherProps}
        >
          {label || children}
        </ButtonIconLink>
      );
    } else {
      return (
        <ButtonIconObject
          theme={newTheme}
          onClick={onClick}
          to={to}
          disabled={disabled}
          {...otherProps}
        >
          {label || children}
        </ButtonIconObject>
      );
    }
  }

  if (loading === true) {
    disabled = true;
  }

  if (to) {
    return (
      <ButtonLink
        theme={newTheme}
        onClick={onClick}
        to={to}
        loading={loading}
        disabled={disabled}
        {...otherProps}
      >
        {loading ? (<Loader size="medium" />) : null}
        {
          (label || children)
        }
      </ButtonLink>
    );
  } else {
    return (
      <ButtonObject
        theme={newTheme}
        onClick={onClick}
        to={to}
        loading={loading}
        disabled={disabled}
        {...otherProps}
      >
        {loading ? (<Loader size="medium" />) : null}
        {
          (label || children)
        }
      </ButtonObject>
    );
  }
};

ButtonInput.defaultProps = {
  variant: 'default',
  theme: 'default'
}

export default ButtonInput;
