import React from 'react';
import styled from 'styled-components'

import logoWhite from 'assets/logo/logo-white.svg';

import {
  ImageContainer,
  MainImage,
  IconsRelative,
} from 'styles/AuthStyles';

const MainImageContent = styled(MainImage)`

`;

function LoginPortrait() {
  return (
    <ImageContainer>
      <IconsRelative>
        <MainImageContent src={logoWhite} alt="loginImage" />
      </IconsRelative>
    </ImageContainer>
  )
};

export default LoginPortrait;
