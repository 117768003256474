import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { bizConfig } from 'utils/bizConfig';
import {
  AuthContainer, AuthFormContainer,
  AuthImageContainer, AuthFormContent,
  AuthFormTitle,
  DefaultLink, ButtonsBox,
} from 'styles/AuthStyles';
import LoginPortrait from 'components/LoginPortrait';
import DefaultInput from 'components/Input/Default';
import PasswordInput from 'components/Input/Password';
import Button from 'components/Button';
import Loading from 'components/Loading';
import ErrorBox from 'components/ErrorBox';
import LogoLink from 'components/LogoLink';
import { setData } from 'utils/token';

function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState();
  const [validForm, setValidForm] = useState();
  const [password, setPassword] = useState();
  const [response, setResponse] = useState({});

  useEffect(() => {
    setValidForm((password && password.length >= 5));
  }, [password, login]);

  const onSubmit = async (e) => {
    e.preventDefault();

    let requestInfo = {
      method: 'POST',
      headers: bizConfig.requestHeaders,
      body: JSON.stringify({
        cad_user_login: login,
        cad_senha: password,
      })
    };

    setLoading(true);

    fetch(`${bizConfig.rootApi}/Usuarios`, requestInfo)
        .then(response => {
            if (response.status === 200) {
                return response.text();
            } else {
                throw new Error(response.text());
            }
        })
        .then(response => {
          const result = response.split('|');

          if (response[0] === '1') {
            setData({
              usuario: result[1],
              login: result[2],
              codinome: result[3],
              email: result[4],
              imagemUsuario: result[5]
            });

            setResponse({
              success: true,
            });
          } else {
            setResponse({
              success: false,
              message: 'Usuário ou Senha inválidos!',
            });
          }

          setLoading(false);
        })
        .catch((error) => {
            console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            setLoading(false);
        })
  }

  return (
    <AuthContainer>
      {response.success && <Redirect to="/dashboard" />}
      <Loading show={loading} size="big" />
      <AuthFormContainer>
        <AuthFormContent>
          <LogoLink variant="inverse" />
          <AuthFormTitle>Entrar</AuthFormTitle>
          {response.success === false && (
            <ErrorBox>{response.message}</ErrorBox>
          )}
          <form noValidate onSubmit={onSubmit} autoComplete="off">
            <DefaultInput
              id="login"
              name="login"
              label="Login"
              onChange={(e) => {
                setLogin(e.target.value);
              }}
            />
            <PasswordInput
              id="password"
              name="password"
              label="Senha"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <ButtonsBox>
              <Button
                label="Entrar"
                disabled={!validForm}
              />
            </ButtonsBox>
            {/* <DefaultLink>
              <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
            </DefaultLink> */}
          </form>
        </AuthFormContent>
      </AuthFormContainer>
      <AuthImageContainer>
        <LoginPortrait />
      </AuthImageContainer>
    </AuthContainer>
  );
}

export default LoginPage;
