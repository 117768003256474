import React, { useState }  from 'react';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Divider from 'components/Divider';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Typography from 'components/Typography';
import Barcode from 'components/Input/Barcode';

const Section = styled.section`
  hr {
    margin: 0;
  }
`;

const Header = styled.header`
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-weight: 400;
    width: 100%;
    text-align: left;
  }
`;

function DashboardPage() {
  const [barcode, setBarcode] = useState("");

  return (
    <InternalLayout>
      <Loading show={false} size="big" />
      <Section>
        <Header>
          <Typography variant="h1">Dashboard</Typography>
        </Header>
        <Divider variant="dark" />
        <Barcode
          id="barcodeTest"
          name="barcodeTest"
          label="Campo de código de barras v2"
          onChange={(e) => {
            setBarcode(e.target.value);
          }}
          value={barcode}
        />
        <Button
          label="Testar código"
          onClick={() => {
            alert(barcode);
          }}
        />
      </Section>
    </InternalLayout>
  );
}

export default DashboardPage;
