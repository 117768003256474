import React from 'react';
import styled from 'styled-components';
import { body3, body4 } from 'components/Type';

const TextareaWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;

  .field-error {
    ${body4}
    white-space: normal;
    word-wrap: break-word;
    width: 100%;
    max-width: 635px;
    position: relative;
    color: #CE2C30;
    margin-top: 2px;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    display: block;
    overflow: hidden;
    opacity: ${props => props.error ? '1' : '0'};
    max-height: ${props => props.error ? '200px' : '0'};
  }
`

const TextareaInputStyled = styled.textarea`
  ${body3}
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  border: 1px solid #D0D0D0;
  border-color: ${props => props.error ? '#CE2C30' : '#D0D0D0'};
  border-radius: 5px;
  transition: border-color .3s ease-in-out, color .3s ease-in-out;
  outline: none;
  color: #4E4D4C;
  resize: vertical;
  white-space: pre-wrap;

  &:focus {
    outline: none;
    border-color: ${props => props.error ? '#CE2C30' : '#1A1A1A'};
    box-shadow: 0px 0px 1px #999;
    color: #1A1A1A;
  }

  &:disabled {
    color: #B2B1B0
  }

  ::placeholder {
    color: #B3B1B0;
  }
`
const Label = styled.label`
  ${body4}
  display: flex;
  margin-bottom: 5px;

  ${props => !props.error ? `color: #807E7D;` : null}

  ${props => props.disabled ? `color: #B2B1B0;` : null}

  ${props => !!props.error ? `color: #CE2C30;` : null}
`

function TextareaInput({
  id, name, label, className, errorMessage,
  onChange, value, type, placeholder, disabled, ...props
}) {
  return (
    <TextareaWrapper className={className} error={!!errorMessage}>
      {label  && (<Label error={!!errorMessage} disabled={disabled}>{label}</Label>)}
      <TextareaInputStyled
        id={id}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        error={!!errorMessage}
        rows="4"
        {...props}
      />
      <div className="field-error">{errorMessage}</div>
    </TextareaWrapper>
  )
};

TextareaInput.defaultProps = {
  type: 'text'
}

export default TextareaInput;
