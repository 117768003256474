import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import InternalLayout from '../../../components/InternalLayout';
import Divider from '../../../components/Divider';
import Loading from '../../../components/Loading';
import Typography from '../../../components/Typography';
import CardInput from '../../../components/Card';
import styles from '../../../styles/style.module.css';
import { toDate } from '../../../utils/utils';
import { bizConfig } from '../../../utils/bizConfig';
import { getData } from 'utils/token';

const Section = styled.section`
  hr {
    margin: 0;
  }
`;

const Header = styled.header`
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-weight: 400;
    width: 100%;
    text-align: left;
  }
`;

function ListaProducaoPage(props) {
    const { history, match } = props;
    const [producoes, setProducoes] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [acessosUsuario, setAcessosUsuario] = useState(null);
    const status = match?.params?.status;
    const userData = getData();

    useEffect(() => {
        if (!status) return;
        ConsultarProducoes();
    }, [status]);

    useEffect(() => {
        if (!status) window.location.href = '/#/lista-producao/IN';

        ConsultarProducoes();
    }, []);

    const ConsultarProducoes = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqProducaoStatus/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${status}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setProducoes(response);
                ConsultarAcessosUsuario();
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
                setShowLoader(false);
            })
    }

    const ConsultarAcessosUsuario = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqAcessosProducao/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${0}/${userData.usuario}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setAcessosUsuario(response);
                setShowLoader(false);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    return (
        <InternalLayout>
            <Loading show={showLoader} size="big" />
            <Section>
                <Header>
                    <Typography variant="h1">Inspeção de qualidade</Typography>
                </Header>
                <Divider variant="dark" />
            </Section>
            <Section>
                <Header style={{ justifyContent: 'space-between' }}>
                    {status == 'IN'
                        && <>
                            <Typography variant="h3" >Linhas de produção em andamento</Typography>
                            { !bizConfig.online && acessosUsuario && acessosUsuario.length > 0 && (acessosUsuario[0].novaProducao == 1)
                                && <>
                                <a
                                    className='b-1 p-1'
                                    style={{ cursor: 'pointer' }}
                                    href={'/#/nova-producao'}
                                >
                                    + Nova produção
                                </a>
                                </>
                            }
                        </>
                    }
                    {status == 'FI'
                        && <>
                            <Typography variant="h3" >Linhas de produção finalizadas</Typography>
                        </>
                    }
                </Header>
                <div className={styles.cardsProducao}>
                    {
                        producoes.map((element, index) => (
                            <CardInput
                                key={`card-${index}`}
                                title={`${element.prd_codigo} - ${element.prd_descricao} - ${element.inq_nome_linha} - Lote: ${element.inq_lote} ${element.inq_unificador_lote ? ' - Unificador de lotes: ' + element.inq_unificador_lote: ''}`}
                                link={`/#/producao/${element.inq_id_producao}/${element.inq_possui_pesagem}/${status}`}
                                linkTitle={'Abrir'}
                            />)
                        )
                    }
                </div>
            </Section>
        </InternalLayout >
    );
}

export default ListaProducaoPage;