import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { body3, body4 } from 'components/Type';

import arrowDown from 'assets/icon-arrow-down.svg';

const SelectStyled = styled.div`
  position: relative;
  user-select: none;
  width: 100%;
  margin-bottom: 24px;

  .custom-select {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .custom-select__trigger {
    ${body4}
    height: 56px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 14px 20px;
    border: 1px solid #D0D0D0;
    border-color: '#D0D0D0';
    border-radius: 5px;
    cursor: pointer;
    background: #FFF;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    outline: none;
    color: #4E4D4C;
  }

  @media (max-width: 600px) {
    .custom-select__trigger {
      padding-right: 5px;
    }
  }

  .custom-options {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    border-top: 0;
    background: #fff;
    transition: all 0.5s;
    box-shadow: 0px 8px 15px 0px #00000014;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 2;
  }
  .custom-select.open .custom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  .custom-option {
    ${body4}
    position: relative;
    display: block;
    display: flex;
    justify-content: space-between;
    padding: 14px 16px;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      background-color: #F6F6F4;
    }

    &.disabled {
      color: #B2B1B0;
      cursor: default;

      input {
        cursor: default;
      }
    }

    input {
      position: relative;
      top: 1px;
    }

    span {
      padding-left: 8px;
    }
  }
  .custom-option:hover {
    cursor: pointer;

    &.disabled {
      cursor: default;
    }
  }

  .arrow {
    position: relative;
    height: 7px;
    width: 11px;
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    background-position: center center;
  }

  .open .arrow {
    transform: rotate(180deg);
  }

`
const Label = styled.label`
  ${body4}
  display: flex;
  margin-bottom: 5px;
  color: #807E7D;
`

function SimpleSelectInput({
  id, name, label, options,
  onChange, value, defaultSelected, disabled
}) {

  const [open, setOpen] = useState();
  const [selected, setSelected] = useState(value);
  const [defaultIsSelected, setDefaultIsSelected] = useState(false);

  window.addEventListener('click', function (e) {
    const select = document.querySelector(`.custom-select-${name}`)
    if (select && !select.contains(e.target)) {
      setOpen(false);
    }
  });

  useEffect(() => {
    if (!defaultIsSelected) {
      setDefaultIsSelected(true);
      if (defaultSelected && options.length) {
        const selectedIndex = options.findIndex((item) => item?.props?.value === defaultSelected?.props?.value);
        setSelected(options[selectedIndex]);
      } else {
        setSelected(options[0]);
      }
    }
  }, [defaultSelected]);

  return (
    <div style={{ width: '100%' }}>
      {label && (<Label>{label}</Label>)}
      <SelectStyled onClick={() => { if (!disabled) setOpen(!open); }}>
        <div id={`${id}`} className={`custom-select custom-select-${name} ${open ? 'open' : ''}`}>
          <div className="custom-select__trigger">
            {value || selected ? (
              <span>
                {value || selected}
              </span>
            ) : <span />}
            <div className="arrow"></div>
          </div>
          <div className="custom-options">
            {options.map((item, i) => (
              <div
                className={`custom-option ${selected === item ? 'selected' : ''}`}
                key={i}
                onClick={() => {
                  setSelected(item);
                  onChange(item);
                }}
                data-value={item}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      </SelectStyled>
    </div>
  )
};

SimpleSelectInput.defaultProps = {
  options: [],
}

export default SimpleSelectInput;
