import React, { useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { AuthContext } from 'context/Auth';
import {
  AuthContainer, AuthFormContainer,
  AuthImageContainer, AuthFormContent,
  AuthFormTitle, AuthFormText,
  ButtonsBox,
} from 'styles/AuthStyles';
import LoginPortrait from 'components/LoginPortrait';
import DefaultInput from 'components/Input/Default';
import EmailInput from 'components/Input/Email';
import PasswordInput from 'components/Input/Password';
import Button from 'components/Button';
import Loading from 'components/Loading';
import ErrorBox from 'components/ErrorBox';
import LogoLink from 'components/LogoLink';

const testEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function PasswordRecoveryPage() {
  const { submitVerify, loginContent, clearAuthContext } = useContext(AuthContext);
  const [validEmail, setValidEmail] = useState();
  const [validForm, setValidForm] = useState();
  const [email, setEmail] = useState();
  const [newPassword, setNewPassword] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [response, setResponse] = useState({});

  const { loading } = loginContent;

  useEffect(() => {
    return function cleanup() {
      clearAuthContext();
    };
  }, []);

  useEffect(() => {
    if (email && email.length > 5) {
      setValidEmail(testEmail.test(email));
    } else {
      setValidEmail(false);
    }
  }, [email]);

  useEffect(() => {
    if (verificationCode && newPassword && email && validEmail) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [verificationCode, newPassword, email, validEmail]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const result = await submitVerify({
      email,
      verificationCode,
      newPassword,
    });

    setResponse({
      ...result,
    });
  }

  return (
    <AuthContainer>
      {response.success && <Redirect to="/login" />}
      <Loading show={loading} size="big" />
      <AuthFormContainer>
        <AuthFormContent>
          <LogoLink variant="inverse" />
          <AuthFormTitle>Nova Senha</AuthFormTitle>
          <AuthFormText>Digite o código de recuperação que você recebeu no seu celular ou e-mail.</AuthFormText>
          {response.success === false && (
            <ErrorBox>{response.message}</ErrorBox>
          )}
          <form noValidate onSubmit={onSubmit} autoComplete="off">
            <EmailInput
              id="email"
              name="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <DefaultInput
              id="verificationCode"
              name="verificationCode"
              label="Código de Verificação"
              onChange={(e) => {
                setVerificationCode(e.target.value);
              }}
            />
            <PasswordInput
              id="newPassword"
              name="newPassword"
              label="Nova senha"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
            <ButtonsBox>
              <Button
                label="Enviar"
                disabled={!validForm}
              />
            </ButtonsBox>
          </form>
        </AuthFormContent>
      </AuthFormContainer>
      <AuthImageContainer>
        <LoginPortrait />
      </AuthImageContainer>
    </AuthContainer>
  );
}

export default PasswordRecoveryPage;
