import React from 'react';
import styled from 'styled-components';
import { h5m, body3 } from 'components/Type';

const CardNok = styled.div`
  background: #ffe4e1;
  border-radius: 4px;
  box-sizing: border-box;
  border-style: solid;
  padding: 20px;

  img {
    display: block;
    margin: 0 auto 24px;
    text-align: center;
    max-width: 100%;
  }

  h4 {
    ${h5m}
    text-align: center;
    margin-top: 0px;
    margin-bottom: 24px;
    color: #1a1a1a;

    @media(max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  p {
    ${body3}
    margin: 0px;
    padding: 0;
    color: #1A1A1A;
    text-align: center;

    @media(max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  a {
    ${h5m}
    margin: 32px auto 0;
    color: #009051;
    display: block;
    text-align: center;
    text-decoration: none;
  }
`

function CardInputNok({
  imgSrc, title, text, link, linkTitle, children,
}) {
  return (
    <CardNok>
      { imgSrc && (<img src={imgSrc} alt={`${title} - ${text}`}></img>) }
      { title && (<h4>{ title }</h4>) }
      { (text || children) && (<p>{ text || children }</p>) }
      { link && <a href={link}>{ linkTitle }</a> }
    </CardNok>
  )
};

export default CardInputNok;

