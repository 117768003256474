import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { bizConfig } from '../../../utils/bizConfig';
import Typography from '../../../components/Typography';
import InputWrapper from '../../../components/Input/Default';
import SimpleSelectInput from '../../../components/Input/SimpleSelect';
import MaskedInputWrapper from '../../../components/Input/Masked';
import Button from '../../../components/Button';
import { dataValida, toFloat, toDateSQL, toDate8 } from '../../../utils/utils';
import TextareaWrapper from '../../../components/Input/Textarea';
import { getData } from 'utils/token';

const Section = styled.section`
  hr {
    margin: 0;
  }
`;


const Header = styled.header`
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-weight: 400;
    width: 100%;
    text-align: left;
  }
`;

function CadastroCubaPage(props) {
    const { idProducao, setSelectedMenu, operacao, itemSelecionado, idCuba } = props;
    const [numeroCubaEstoque, setNumeroCubaEstoque] = useState("");
    const [descricaoCuba, setDescricaoCuba] = useState("");
    const [lote, setLote] = useState("");
    const [formula, setFormula] = useState("");
    const [fabricacao, setFabricacao] = useState("");
    const [validade, setValidade] = useState("");
    const [densidade, setDensidade] = useState("");
    const [tipoDensidade, setTipoDensidade] = useState("");
    const [responsavel, setResponsavel] = useState("");
    const [operador, setOperador] = useState("");
    const [horaTroca, setHoraTroca] = useState("");
    const [motivoAlteracao, setMotivoAleracao] = useState("");
    const [validForm, setValidForm] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [optionsResponsavel, setOptionsResponsavel] = useState(null);
    const [optionsOperador, setOptionsOperador] = useState(null);
    const [optionsTipoDensidade, setOptionsTipoDensidade] = useState(null);
    const userData = getData();

    useEffect(() => {
        ConsultarOptionResponsavel();
        ConsultarOptionOperador();
        ConsultarOptionTipoDensidade();
    }, []);

    useEffect(() => {
        if (!itemSelecionado || operacao == 1) return;

        setNumeroCubaEstoque(itemSelecionado.inq_num_cuba_estoque);
        setDescricaoCuba(itemSelecionado.inq_descricao_cuba);
        setLote(itemSelecionado.inq_lote);
        setFormula(itemSelecionado.inq_formula);
        setFabricacao(toDate8(itemSelecionado.inq_fabricacao));
        setValidade(toDate8(itemSelecionado.inq_validade));
        setDensidade(itemSelecionado.inq_densidade.toString().replace(".", ","));
        setTipoDensidade(itemSelecionado.inq_id_densidade);
        setResponsavel(itemSelecionado.inq_id_responsavel);
        setOperador(itemSelecionado.inq_id_operador);
        setHoraTroca(itemSelecionado.inq_horario_troca);

    }, [itemSelecionado])
    const ConsultarOptionResponsavel = () => {
        let requestInfo = {
            method: 'POST',
            headers: bizConfig.requestHeaders,
            body: JSON.stringify([
                {
                    "nome": "cad_empresa",
                    "valor": bizConfig.cad_empresa
                },
                {
                    "nome": "cad_filial",
                    "valor": bizConfig.cad_filial
                },
                {
                    "nome": "tipo",
                    "valor": "ddl_inq_pessoas"
                },
                {
                    "nome": "inq_id_tipo_pessoa",
                    "valor": "O|;I|;4|;5|;6|;B|;C|;D|;"
                }

            ])
        };
        fetch(`${bizConfig.rootApi}/DropDown`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setOptionsResponsavel(response);
                setResponsavel(response[0].codigo);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const ConsultarOptionOperador = () => {
        let requestInfo = {
            method: 'POST',
            headers: bizConfig.requestHeaders,
            body: JSON.stringify([
                {
                    "nome": "cad_empresa",
                    "valor": bizConfig.cad_empresa
                },
                {
                    "nome": "cad_filial",
                    "valor": bizConfig.cad_filial
                },
                {
                    "nome": "tipo",
                    "valor": "ddl_inq_pessoas"
                },
                {
                    "nome": "inq_id_tipo_pessoa",
                    "valor": "O|;I|;4|;5|;6|;B|;C|;D|;"
                }

            ])
        };
        fetch(`${bizConfig.rootApi}/DropDown`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setOptionsOperador(response);
                setOperador(response[0].codigo);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }


    const ConsultarOptionTipoDensidade = () => {
        let requestInfo = {
            method: 'POST',
            headers: bizConfig.requestHeaders,
            body: JSON.stringify([
                {
                    "nome": "cad_empresa",
                    "valor": bizConfig.cad_empresa
                },
                {
                    "nome": "cad_filial",
                    "valor": bizConfig.cad_filial
                },
                {
                    "nome": "tipo",
                    "valor": "ddl_inq_densidades"
                }
            ])
        };
        fetch(`${bizConfig.rootApi}/DropDown`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setOptionsTipoDensidade(response);
                setTipoDensidade(response[0].codigo);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    useEffect(() => {
        if (!numeroCubaEstoque || numeroCubaEstoque.length < 3) {
            setValidForm(false);
            return;
        }

        if (!descricaoCuba || descricaoCuba.length < 3) {
            setValidForm(false);
            return;
        }

        if (!lote || lote.length < 3) {
            setValidForm(false);
            return;
        }

        if (!formula || formula.length < 3) {
            setValidForm(false);
            return;
        }

        if (!dataValida(fabricacao)) {
            setValidForm(false);
            return;
        }

        if (!dataValida(validade)) {
            setValidForm(false);
            return;
        }

        if (!tipoDensidade) {
            setValidForm(false);
            return;
        }

        if (!densidade || densidade.length < 1) {
            setValidForm(false);
            return;
        }

        if (!horaTroca || horaTroca.length != 5) {
            setValidForm(false);
            return;
        }

        if (operacao == 2 && (!motivoAlteracao || motivoAlteracao.length < 5)) {
            setValidForm(false);
            return;
        }

        setValidForm(true);
    }, [numeroCubaEstoque, descricaoCuba, lote, formula, fabricacao, densidade, tipoDensidade, validade, horaTroca, responsavel, motivoAlteracao, operador]);

    const onSubmit = async (e) => {
        setLoadingSubmit(true);

        if (e) e.preventDefault();

        let requestInfo = {
            method: 'POST',
            headers: bizConfig.requestHeaders,
            body: JSON.stringify(
                {
                    "operacao": operacao,
                    "inq_id_cuba": idCuba,
                    "inq_num_cuba_estoque": numeroCubaEstoque,
                    "cad_empresa": bizConfig.cad_empresa,
                    "cad_filial": bizConfig.cad_filial,
                    "inq_id_producao": idProducao,
                    "inq_descricao_cuba": descricaoCuba,
                    "inq_lote": lote,
                    "inq_formula": formula,
                    "inq_fabricacao": toDateSQL(fabricacao),
                    "inq_validade": toDateSQL(validade),
                    "inq_densidade": toFloat(densidade, 6),
                    "inq_id_densidade": tipoDensidade,
                    "inq_id_responsavel": responsavel,
                    "inq_id_operador": operador,
                    "inq_horario_troca": horaTroca,
                    "inq_usuario": userData.usuario,
                    "inq_motivo_alteracao": motivoAlteracao || ""
                }
            )
        };
        fetch(`${bizConfig.rootApi}/InqProducaoCubas`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response;
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setLoadingSubmit(false);
                setSelectedMenu('cuba');
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    return (
        <>
            <Section>
                <Header>
                    <Typography variant="h3">{operacao == 1 ? 'Nova cuba' : operacao == 2 ? 'Alteração da cuba' : 'Você tem certeza que deseja excluir essa cuba?'}</Typography>
                </Header>
            </Section>
            <form noValidate onSubmit={onSubmit} autoComplete="off">
                <div className={'colsForm'}>
                    <InputWrapper
                        id="numeroCubaEstoque"
                        name="numeroCubaEstoque"
                        label="Nº cuba estoque"
                        onChange={(e) => {
                            setNumeroCubaEstoque(e.target.value);
                        }}
                        disabled={operacao == 3}
                        value={numeroCubaEstoque}
                    />
                    <InputWrapper
                        id="descricaoCuba"
                        name="descricaoCuba"
                        label="*Descrição"
                        onChange={(e) => {
                            setDescricaoCuba(e.target.value);
                        }}
                        disabled={operacao == 3}
                        value={descricaoCuba}
                    />
                    <InputWrapper
                        id="lote"
                        name="lote"
                        label="*Lote"
                        onChange={(e) => {
                            setLote(e.target.value);
                        }}
                        disabled={operacao == 3}
                        value={lote}
                    />
                    <InputWrapper
                        id="formula"
                        name="formula"
                        label="*Fórmula"
                        onChange={(e) => {
                            setFormula(e.target.value);
                        }}
                        disabled={operacao == 3}
                        value={formula}
                    />
                    <MaskedInputWrapper
                        id="fabricacao"
                        name="fabricacao"
                        label="*Data de fabricação"
                        mask="99/99/99"
                        onChange={(e) => {
                            setFabricacao(e.target.value);
                        }}
                        disabled={operacao == 3}
                        value={fabricacao}
                    />
                    <MaskedInputWrapper
                        id="validade"
                        name="validade"
                        label="*Validade"
                        mask="99/99/99"
                        onChange={(e) => {
                            setValidade(e.target.value);
                        }}
                        disabled={operacao == 3}
                        value={validade}

                    />
                    <InputWrapper
                        id="densidade"
                        name="densidade"
                        label="*Densidade"
                        onChange={(e) => {
                            setDensidade(e.target.value);
                        }}
                        disabled={operacao == 3}
                        value={densidade}
                    />
                    {optionsTipoDensidade && <SimpleSelectInput
                        id="tipoDensidade"
                        name="tipoDensidade"
                        label="*Tipo da densidade"
                        options={optionsTipoDensidade.map((item, index) => (<option key={`option-densidade-${index}`} value={item.codigo}>{item.display}</option>))}
                        onChange={(e) => {
                            setTipoDensidade(e.props.value);
                        }}
                        disabled={operacao == 3}
                        defaultSelected={operacao != 1 ? (<option value={itemSelecionado.inq_id_densidade}>{itemSelecionado.inq_nome_densidade}</option>) : null}
                    />}
                    <MaskedInputWrapper
                        id="horaTroca"
                        name="horaTroca"
                        label="*Hora da troca"
                        mask="99:99"
                        onChange={(e) => {
                            setHoraTroca(e.target.value);
                        }}
                        disabled={operacao == 3}
                        value={horaTroca}
                    />
                    {optionsResponsavel && <SimpleSelectInput
                        id="responsavel"
                        name="responsavel"
                        label="*Inspetor"
                        options={optionsResponsavel.map((item, index) => (<option key={`option-responsavel-${index}`} value={item.codigo}>{item.display}</option>))}
                        onChange={(e) => {
                            setResponsavel(e.props.value);
                        }}
                        disabled={operacao == 3}
                        defaultSelected={operacao != 1 ? (<option value={itemSelecionado.inq_id_responsavel}>{optionsResponsavel.inq_nome_responsavel}</option>) : null}

                    />}
                    {optionsOperador && <SimpleSelectInput
                        id="operador"
                        name="operador"
                        label="*Operador"
                        options={optionsOperador.map((item, index) => (<option key={`option-operador-${index}`} value={item.codigo}>{item.display}</option>))}
                        onChange={(e) => {
                            setOperador(e.props.value);
                        }}
                        disabled={operacao == 3}
                        defaultSelected={operacao != 1 ? (<option value={itemSelecionado.inq_id_operador}>{optionsOperador.inq_nome_operador}</option>) : null}

                    />}
                </div>
                {operacao == 2 &&
                    <TextareaWrapper
                        id="motivoAlteracao"
                        name="motivoAlteracao"
                        label="*Motivo da alteração"
                        onChange={(e) => {
                            setMotivoAleracao(e.target.value);
                        }}
                    />
                }
            </form>
            <div className='w-100 text-right'>
                <Button
                    label="Cancelar"
                    theme="danger"
                    onClick={() => {
                        setSelectedMenu('cuba');
                    }}
                />
                <Button
                    label={operacao == 3 ? 'Excluir' : 'Salvar'}
                    theme={operacao == 3 ? 'danger' : undefined}
                    className={'ml-1'}
                    disabled={!validForm}
                    loading={loadingSubmit ? loadingSubmit : undefined}
                    onClick={() => {
                        onSubmit();
                    }}
                />
            </div>
        </>
    );
}

export default CadastroCubaPage;