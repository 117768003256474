import React from 'react';
import styled from 'styled-components';

import Menu from 'components/Menu';

const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
`;

const Main = styled.div`
  padding: 0 150px;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
  box-shadow: -2px 0px 5px rgb(26 26 26 / 8%);
  z-index: 1;
  position: relative;

  @media(max-width: 1280px) {
    padding: 50px;
  }

  @media(max-width: 1024px) /* 940px */  {
    padding: 24px 24px 148px;
  }
`;

function InternalLayout({ children, modals = null }) {
  return (
    <Content>
      <Menu />
      <Main>
        { children }
      </Main>
      { modals }
    </Content>
  );
}

export default InternalLayout;
