import React from 'react';
import styled from 'styled-components';

export const h1r = `
  font-family: "Exo 2", sans-serif;
  font-size: 48px;
  line-height: 56px;
  font-weight: 500;
  letter-spacing: -1px;
`;

export const h2r = `
  font-family: "Exo 2", sans-serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing: -0.6px;
`;

export const h3r = `
  font-family: "Exo 2", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const h2m = `
  font-family: 'Poppins',sans-serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  letter-spacing: -0.6px;
`;

export const h3m = `
  font-family: 'Poppins',sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.5px;
`;

export const h4m = `
  font-family: 'Poppins',sans-serif;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.4px;
`;

export const h5m = `
  font-family: 'Poppins',sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 600;
`;

export const body1 = `
  font-family: 'Poppins',sans-serif;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  font-weight: 500;
`;

export const body2 = `
  font-family: 'Poppins',sans-serif;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.4px;
  font-weight: 500;
`;

export const body3 = `
  font-family: 'Poppins',sans-serif;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.3px;
  font-weight: 500;
`;

export const body4 = `
  font-family: 'Poppins',sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  font-weight: 500;
`;

export const body5 = `
  font-family: 'Poppins',sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  font-weight: 500;
`;

const Styled = {
  h1r: styled.h1`
    ${h1r}
    text-align: center;
    margin: 0;
  `,
  h2r: styled.h2`
    ${h2r}
    text-align: center;
    margin: 0;
  `,
  h3r: styled.h3`
    ${h3r}
    text-align: center;
    margin: 0;
  `,
  h2m: styled.h2`
    ${h2m}
    text-align: center;
    margin: 0;
  `,
  h3m: styled.h2`
    ${h3m}
    text-align: center;
    margin: 0;
  `,
  h4m: styled.h4`
    ${h4m}
    text-align: center;
    margin: 0;
  `,
  h5m: styled.h5`
    ${h5m}
    text-align: center;
    margin: 0;
  `,
  body1: styled.p`
    ${body1}
    margin: 0;
  `,
  body2: styled.p`
    ${body2}
    margin: 0;
  `,
  body3: styled.p`
    ${body3}
    margin: 0;
  `,
  body4: styled.p`
    ${body4}
    margin: 0;
  `,
  body5: styled.p`
    ${body5}
    margin: 0;
  `,
};

const Type = ({ variant, children, ...props }) => {
  const Component = Styled[variant];

  return (
    <Component
      {...props}
    >
      { children }
    </Component>
  );
};

export default Type;
