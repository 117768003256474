import React, { useState, useEffect } from 'react';

function DocumentosPage() {
    return (
        <>
            Documentos
        </>
    );
}

export default DocumentosPage;