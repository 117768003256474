import React from 'react';
import styled from 'styled-components'

const Divider = styled.hr`
  border: 0;
  height: 1px;
  margin: 20px 0;
`;

const DividerLight = styled(Divider)`
  opacity: 0.2;
  color: #FFFDFB;
  background-color: #FFFDFB;
`;

const DividerDark = styled(Divider)`
  opacity: 0.15;
  color: #1A1A1A;
  background-color: #1A1A1A;
`;

function DividerInput({ variant }) {
  return (
    variant === 'light' ? <DividerLight /> : <DividerDark />
  )
};

DividerInput.defaultProps = {
  variant: 'light'
};

export default DividerInput;
