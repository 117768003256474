import React, { useState, useEffect } from 'react';
import { bizConfig } from '../../utils/bizConfig';
import InputWrapper from '../../components/Input/Default';
import SimpleSelectInput from '../../components/Input/SimpleSelect';
import MaskedInputWrapper from '../../components/Input/Masked';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import styled from 'styled-components';
import { formatarValorDecimal, toDecimal } from '../../utils/utils';
import TextareaWrapper from '../../components/Input/Textarea';
import { getData } from 'utils/token';

const Section = styled.section`
  hr {
    margin: 0;
  B}
`;

const Header = styled.header`
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-weight: 400;
    width: 100%;
    text-align: left;
  }
`;


function PesagemPage(props) {
    const { idProducao, setVisualizarPesagem, AtualizarPesagem } = props;
    const [formularios, setFormularios] = useState();
    const [pesagem, setPesagem] = useState();
    const [showLoader, setShowLoader] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [operacao, setOperacao] = useState(1);
    const [acessosUsuario, setAcessosUsuario] = useState(null);
    const [inq_numero_serie_balanca, set_inq_numero_serie_balanca] = useState("");
    const [inq_numero_serie_balanca_lancado, set_inq_numero_serie_balanca_lancado] = useState(false);
    const [inq_hora_1, set_inq_hora_1] = useState("");
    const [inq_tara_1, set_inq_tara_1] = useState("");
    const [inq_hora_2, set_inq_hora_2] = useState("");
    const [inq_tara_2, set_inq_tara_2] = useState("");
    const [inq_hora_3, set_inq_hora_3] = useState("");
    const [inq_tara_3, set_inq_tara_3] = useState("");
    const [inq_hora_4, set_inq_hora_4] = useState("");
    const [inq_tara_4, set_inq_tara_4] = useState("");

    const [inq_hora_5, set_inq_hora_5] = useState("");
    const [inq_tara_5, set_inq_tara_5] = useState("");

    const [inq_hora_6, set_inq_hora_6] = useState("");
    const [inq_tara_6, set_inq_tara_6] = useState("");

    const [inq_hora_7, set_inq_hora_7] = useState("");
    const [inq_tara_7, set_inq_tara_7] = useState("");

    const [inq_hora_8, set_inq_hora_8] = useState("");
    const [inq_tara_8, set_inq_tara_8] = useState("");

    const [inq_hora_9, set_inq_hora_9] = useState("");
    const [inq_tara_9, set_inq_tara_9] = useState("");

    const [inq_hora_10, set_inq_hora_10] = useState("");
    const [inq_tara_10, set_inq_tara_10] = useState("");

    const [inq_hora_11, set_inq_hora_11] = useState("");
    const [inq_tara_11, set_inq_tara_11] = useState("");

    const [inq_hora_12, set_inq_hora_12] = useState("");
    const [inq_tara_12, set_inq_tara_12] = useState("");


    const [validForm, setValidForm] = useState(false);
    const [mostraMensagemSucesso, setMostrarMensagemSucesso] = useState(false);
    const userData = getData();

    useEffect(() => {
        if (!pesagem) return;

        setOperacao(2);

        set_inq_numero_serie_balanca(pesagem[0]?.inq_numero_serie_balanca);
        set_inq_hora_1(pesagem[0]?.inq_hora_1);
        set_inq_tara_1(toDecimal(pesagem[0]?.inq_tara_1, 2));
        set_inq_hora_2(pesagem[0]?.inq_hora_2);
        set_inq_tara_2(toDecimal(pesagem[0]?.inq_tara_2, 2));
        set_inq_hora_3(pesagem[0]?.inq_hora_3);
        set_inq_tara_3(toDecimal(pesagem[0]?.inq_tara_3, 2));
        set_inq_hora_4(pesagem[0]?.inq_hora_4);
        set_inq_tara_4(toDecimal(pesagem[0]?.inq_tara_4, 2));
        set_inq_hora_5(pesagem[0]?.inq_hora_5);
        set_inq_tara_5(toDecimal(pesagem[0]?.inq_tara_5, 2));
        set_inq_hora_6(pesagem[0]?.inq_hora_6);
        set_inq_tara_6(toDecimal(pesagem[0]?.inq_tara_6, 2));
        set_inq_hora_7(pesagem[0]?.inq_hora_7);
        set_inq_tara_7(toDecimal(pesagem[0]?.inq_tara_7, 2));
        set_inq_hora_8(pesagem[0]?.inq_hora_8);
        set_inq_tara_8(toDecimal(pesagem[0]?.inq_tara_8, 2));
        set_inq_hora_9(pesagem[0]?.inq_hora_9);
        set_inq_tara_9(toDecimal(pesagem[0]?.inq_tara_9, 2));
        set_inq_hora_10(pesagem[0]?.inq_hora_10);
        set_inq_tara_10(toDecimal(pesagem[0]?.inq_tara_10, 2));
        set_inq_hora_11(pesagem[0]?.inq_hora_11);
        set_inq_tara_11(toDecimal(pesagem[0]?.inq_tara_11, 2));
        set_inq_hora_12(pesagem[0]?.inq_hora_12);
        set_inq_tara_12(toDecimal(pesagem[0]?.inq_tara_12, 2));
    }, [pesagem]);


    useEffect(() => {
        if (!idProducao) return;

        setShowLoader(true);
        ConsultarFormularios();
    }, [idProducao]);


    useEffect(() => {
        if (!formularios) return;

        ConsultarPesagem();
    }, [formularios]);

    useEffect(() => {
        if (!inq_numero_serie_balanca || inq_numero_serie_balanca?.length < 1) {
            setValidForm(false);
            return;
        }

        setValidForm(true);
    }, [inq_numero_serie_balanca]);

    const ConsultarFormularios = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqProducaoFormularios/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}/${userData.usuario}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setFormularios(response.filter(a => a.inq_possui_pesagem == 1)[0]);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const ConsultarPesagem = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqProducaoPesagem/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}/${formularios.inq_id_formulario}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setPesagem(response);
                setShowLoader(false);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const onSubmit = async (e) => {
        setLoadingSubmit(true);
        if (e) e.preventDefault();
        let requestInfo = {
            method: 'POST',
            headers: bizConfig.requestHeaders,
            body: JSON.stringify(
                {
                    "cad_empresa": bizConfig.cad_empresa,
                    "cad_filial": bizConfig.cad_filial,
                    "inq_id_producao": idProducao,
                    "inq_id_formulario": formularios.inq_id_formulario,
                    "inq_numero_serie_balanca": inq_numero_serie_balanca,
                    "inq_hora_1": inq_hora_1 || "",
                    "inq_tara_1": inq_tara_1 ? inq_tara_1?.replace(",", ".") : "",
                    "inq_hora_2": inq_hora_2 || "",
                    "inq_tara_2": inq_tara_2 ? inq_tara_2?.replace(",", ".") : "",
                    "inq_hora_3": inq_hora_3 || "",
                    "inq_tara_3": inq_tara_3 ? inq_tara_3?.replace(",", ".") : "",
                    "inq_hora_4": inq_hora_4 || "",
                    "inq_tara_4": inq_tara_4 ? inq_tara_4?.replace(",", ".") : "",

                    "inq_hora_5": inq_hora_5 || "",
                    "inq_tara_5": inq_tara_5 ? inq_tara_5?.replace(",", ".") : "",

                    "inq_hora_6": inq_hora_6 || "",
                    "inq_tara_6": inq_tara_6 ? inq_tara_6?.replace(",", ".") : "",

                    "inq_hora_7": inq_hora_7 || "",
                    "inq_tara_7": inq_tara_7 ? inq_tara_7?.replace(",", ".") : "",

                    "inq_hora_8": inq_hora_8 || "",
                    "inq_tara_8": inq_tara_8 ? inq_tara_8?.replace(",", ".") : "",

                    "inq_hora_9": inq_hora_9 || "",
                    "inq_tara_9": inq_tara_9 ? inq_tara_9?.replace(",", ".") : "",

                    "inq_hora_10": inq_hora_10 || "",
                    "inq_tara_10": inq_tara_10 ? inq_tara_10?.replace(",", ".") : "",

                    "inq_hora_11": inq_hora_11 || "",
                    "inq_tara_11": inq_tara_11 ? inq_tara_11?.replace(",", ".") : "",

                    "inq_hora_12": inq_hora_12 || "",
                    "inq_tara_12": inq_tara_12 ? inq_tara_12?.replace(",", ".") : "",

                    "inq_usuario": userData.usuario,
                    "inq_motivo_alteracao": ""
                }
            )
        };
        fetch(`${bizConfig.rootApi}/InqProducaoPesagem`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response;
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setLoadingSubmit(false);
                setMostrarMensagemSucesso(true);

                setVisualizarPesagem(false);
                AtualizarPesagem();
            })
            .catch((error) => {
                setLoadingSubmit(false);

                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })

    }

    return (
        <>
            {mostraMensagemSucesso &&
                <div className='my-1'>
                    As informações foram atualizadas com sucesso.
                </div>
            }
            <form noValidate onSubmit={onSubmit} autoComplete="off">
                <div style={{ margin: "margin: 0 auto", gridGap: "1rem", display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                    <MaskedInputWrapper
                        id="inq_hora_1"
                        name="inq_hora_1"
                        label="Hora 1"
                        mask="99:99"
                        onChange={(e) => {
                            set_inq_hora_1(e.target.value);
                        }}
                        value={inq_hora_1}
                    />
                    <InputWrapper
                        id="inq_tara_1"
                        name="inq_tara_1"
                        label="Tara 1"
                        onChange={(e) => {
                            set_inq_tara_1(formatarValorDecimal(e.target.value));
                        }}
                        value={inq_tara_1}
                    />
                    <MaskedInputWrapper
                        id="inq_hora_2"
                        name="inq_hora_2"
                        label="Hora 2"
                        mask="99:99"
                        onChange={(e) => {
                            set_inq_hora_2(e.target.value);
                        }}
                        value={inq_hora_2}
                    />
                    <InputWrapper
                        id="inq_tara_2"
                        name="inq_tara_2"
                        label="Tara 2"
                        onChange={(e) => {
                            set_inq_tara_2(formatarValorDecimal(e.target.value));
                        }}
                        value={inq_tara_2}
                    />
                </div>

                <div style={{ margin: "margin: 0 auto", gridGap: "1rem", display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                    <MaskedInputWrapper
                        id="inq_hora_3"
                        name="inq_hora_3"
                        label="Hora 3"
                        mask="99:99"
                        onChange={(e) => {
                            set_inq_hora_3(e.target.value);
                        }}
                        value={inq_hora_3}
                    />
                    <InputWrapper
                        id="inq_tara_3"
                        name="inq_tara_3"
                        label="Tara 3"
                        onChange={(e) => {
                            set_inq_tara_3(formatarValorDecimal(e.target.value));
                        }}
                        value={inq_tara_3}
                    />
                    <MaskedInputWrapper
                        id="inq_hora_4"
                        name="inq_hora_4"
                        label="Hora 4"
                        mask="99:99"
                        onChange={(e) => {
                            set_inq_hora_4(e.target.value);
                        }}
                        value={inq_hora_4}
                    />
                    <InputWrapper
                        id="inq_tara_4"
                        name="inq_tara_4"
                        label="Tara 4"
                        onChange={(e) => {
                            set_inq_tara_4(formatarValorDecimal(e.target.value));
                        }}
                        value={inq_tara_4}
                    />
                </div>

                <div style={{ margin: "margin: 0 auto", gridGap: "1rem", display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                    <MaskedInputWrapper
                        id="inq_hora_5"
                        name="inq_hora_5"
                        label="Hora 5"
                        mask="99:99"
                        onChange={(e) => {
                            set_inq_hora_5(e.target.value);
                        }}
                        value={inq_hora_5}
                    />
                    <InputWrapper
                        id="inq_tara_5"
                        name="inq_tara_5"
                        label="Tara 5"
                        onChange={(e) => {
                            set_inq_tara_5(formatarValorDecimal(e.target.value));
                        }}
                        value={inq_tara_5}
                    />
                    <MaskedInputWrapper
                        id="inq_hora_6"
                        name="inq_hora_6"
                        label="Hora 6"
                        mask="99:99"
                        onChange={(e) => {
                            set_inq_hora_6(e.target.value);
                        }}
                        value={inq_hora_6}
                    />
                    <InputWrapper
                        id="inq_tara_6"
                        name="inq_tara_6"
                        label="Tara 6"
                        onChange={(e) => {
                            set_inq_tara_6(formatarValorDecimal(e.target.value));
                        }}
                        value={inq_tara_6}
                    />
                </div>


                <div style={{ margin: "margin: 0 auto", gridGap: "1rem", display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                    <MaskedInputWrapper
                        id="inq_hora_7"
                        name="inq_hora_7"
                        label="Hora 7"
                        mask="99:99"
                        onChange={(e) => {
                            set_inq_hora_7(e.target.value);
                        }}
                        value={inq_hora_7}
                    />
                    <InputWrapper
                        id="inq_tara_7"
                        name="inq_tara_7"
                        label="Tara 7"
                        onChange={(e) => {
                            set_inq_tara_7(formatarValorDecimal(e.target.value));
                        }}
                        value={inq_tara_7}
                    />
                    <MaskedInputWrapper
                        id="inq_hora_8"
                        name="inq_hora_8"
                        label="Hora 8"
                        mask="99:99"
                        onChange={(e) => {
                            set_inq_hora_8(e.target.value);
                        }}
                        value={inq_hora_8}
                    />
                    <InputWrapper
                        id="inq_tara_8"
                        name="inq_tara_8"
                        label="Tara 8"
                        onChange={(e) => {
                            set_inq_tara_8(formatarValorDecimal(e.target.value));
                        }}
                        value={inq_tara_8}
                    />
                </div>

                <div style={{ margin: "margin: 0 auto", gridGap: "1rem", display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                    <MaskedInputWrapper
                        id="inq_hora_9"
                        name="inq_hora_9"
                        label="Hora 9"
                        mask="99:99"
                        onChange={(e) => {
                            set_inq_hora_9(e.target.value);
                        }}
                        value={inq_hora_9}
                    />
                    <InputWrapper
                        id="inq_tara_9"
                        name="inq_tara_9"
                        label="Tara 9"
                        onChange={(e) => {
                            set_inq_tara_9(formatarValorDecimal(e.target.value));
                        }}
                        value={inq_tara_9}
                    />
                    <MaskedInputWrapper
                        id="inq_hora_10"
                        name="inq_hora_10"
                        label="Hora 10"
                        mask="99:99"
                        onChange={(e) => {
                            set_inq_hora_10(e.target.value);
                        }}
                        value={inq_hora_10}
                    />
                    <InputWrapper
                        id="inq_tara_10"
                        name="inq_tara_10"
                        label="Tara 10"
                        onChange={(e) => {
                            set_inq_tara_10(formatarValorDecimal(e.target.value));
                        }}
                        value={inq_tara_10}
                    />
                </div>

                <div style={{ margin: "margin: 0 auto", gridGap: "1rem", display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                    <MaskedInputWrapper
                        id="inq_hora_11"
                        name="inq_hora_11"
                        label="Hora 11"
                        mask="99:99"
                        onChange={(e) => {
                            set_inq_hora_11(e.target.value);
                        }}
                        value={inq_hora_11}
                    />
                    <InputWrapper
                        id="inq_tara_11"
                        name="inq_tara_11"
                        label="Tara 11"
                        onChange={(e) => {
                            set_inq_tara_11(formatarValorDecimal(e.target.value));
                        }}
                        value={inq_tara_11}
                    />
                    <MaskedInputWrapper
                        id="inq_hora_12"
                        name="inq_hora_12"
                        label="Hora 12"
                        mask="99:99"
                        onChange={(e) => {
                            set_inq_hora_12(e.target.value);
                        }}
                        value={inq_hora_12}
                    />
                    <InputWrapper
                        id="inq_tara_12"
                        name="inq_tara_12"
                        label="Tara 12"
                        onChange={(e) => {
                            set_inq_tara_12(formatarValorDecimal(e.target.value));
                        }}
                        value={inq_tara_12}
                    />
                </div>

                <div style={{ margin: "0 auto", gridGap: "1rem", display: "grid", gridTemplateColumns: "1fr" }}>
                    <InputWrapper
                        id="inq_numero_serie_balanca"
                        name="inq_numero_serie_balanca"
                        label="*Número de série da balança"
                        onChange={(e) => {
                            set_inq_numero_serie_balanca(e.target.value);
                            set_inq_numero_serie_balanca_lancado(true);
                        }}
                        errorMessage={!inq_numero_serie_balanca && inq_numero_serie_balanca_lancado ? "Campo obrigatório" : ""}
                        value={inq_numero_serie_balanca}
                    />
                </div>
            </form>

            <div className='w-100 text-right'>
                <Button
                    label="Cancelar"
                    theme="danger"
                    onClick={() => {
                        setVisualizarPesagem(false);
                    }}
                />
                <Button
                    label={'Salvar'}
                    className={'ml-1'}
                    loading={loadingSubmit ? loadingSubmit : undefined}
                    disabled={!validForm}
                    onClick={() => {
                        onSubmit();
                    }}
                />
            </div>
        </>
    );
}

export default PesagemPage;
