import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { body3, body4 } from 'components/Type';
import InputMask from 'react-input-mask';
import mastercardIcon from 'assets/cards/mastercard.svg';
import eloIcon from 'assets/cards/elo.svg';
import visaIcon from 'assets/cards/visa.svg';
import amexIcon from 'assets/cards/amex.svg';

const MaskedInputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;

  .card-suffix {
    position: absolute;
    right: 16px;
    width: 34px;
    height: 56px;
    top: ${props => props.error ? 'calc(50% + 0px)' : 'calc(50% + 13px)'};
    transform: translateY(-50%);
  }

  .field-error {
    ${body4}
    white-space: normal;
    word-wrap: break-word;
    width: 100%;
    max-width: 635px;
    position: relative;
    color: #CE2C30;
    margin-top: 2px;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    display: block;
    overflow: hidden;
    opacity: ${props => props.error ? '1' : '0'};
    max-height: ${props => props.error ? '200px' : '0'};
  }
`

const InputStyled = styled(InputMask)`
  ${body3}
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  padding: 10px 20px;
  border: 1px solid #D0D0D0;
  border-color: ${props => props.error ? '#CE2C30' : '#D0D0D0'};
  border-radius: 5px;
  transition: border-color .3s ease-in-out, color .3s ease-in-out;
  outline: none;
  color: #4E4D4C;

  &:focus {
    outline: none;
    border-color: ${props => props.error ? '#CE2C30' : '#1A1A1A'};
    box-shadow: 0px 0px 1px #999;
    color: #1A1A1A;
  }

  &:disabled {
    color: #B2B1B0
  }

  ::placeholder {
    color: #B3B1B0;
  }
`
const Label = styled.label`
  ${body4}
  display: flex;
  margin-bottom: 5px;

  ${props => !props.error ? `color: #807E7D;` : null}

  ${props => props.disabled ? `color: #B2B1B0;` : null}

  ${props => !!props.error ? `color: #CE2C30;` : null}
`

function MaskedInput({
  id, name, label, mask, errorMessage,
  onChange, value, type, placeholder, disabled,
  suffixIcon, onPaste, autocomplete
}) {
  const [currentIcon, setCurrentIcon] = useState();


  useEffect(() => {
    switch (suffixIcon) {
      case 'mastercard': {
        setCurrentIcon(mastercardIcon);
        return;
      }
      case 'elo': {
        setCurrentIcon(eloIcon);
        return;
      }
      case 'amex': {
        setCurrentIcon(amexIcon);
        return;
      }
      case 'visa': {
        setCurrentIcon(visaIcon);
        return;
      }
      default: {
        setCurrentIcon();
        return;
      }
    }
  }, [suffixIcon]);

  return (
    <MaskedInputWrapper error={!!errorMessage}>
      {label && (<Label error={!!errorMessage} disabled={disabled}>{label}</Label>)}
      <InputStyled
        id={id}
        name={name}
        type={type}
        onChange={onChange}
        onPaste={(e) => onPaste(e)}
        //autocomplete={autocomplete}
        value={value || ""}
        mask={mask}
        maskChar={""}
        disabled={disabled ? disabled : undefined}
        placeholder={placeholder}
        error={!!errorMessage}
      />
      {currentIcon && <img className="card-suffix" src={currentIcon} alt={suffixIcon} />}
      <div className="field-error">{errorMessage}</div>
    </MaskedInputWrapper>
  )
};

MaskedInput.defaultProps = {
  type: 'text',
  onPaste: () => { },
}

export default MaskedInput;
