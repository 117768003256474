let requestHeaders = new Headers();
requestHeaders.append('Content-type', 'application/json');
//const local = "https://localhost:20443/api"; //testes
const local = "http://192.168.2.50:2080/api";
const dev = "http://dev.api.universalchemical.biz/api";
const prod = "https://api.universalchemical.biz/api";
const online = process.env.REACT_APP_ONLINE === "1";
const ambient = process.env.REACT_APP_ENV;
let rootApi = local;

if (ambient === 'development') {
    rootApi = dev;
} else if (ambient === 'production') {
    rootApi = prod;
}

export const bizConfig = {
    cad_empresa: "001",
    cad_filial: "0001",
    rootApi,
    requestHeaders,
    usuario: "zib",
    online,
    ambient: ambient ? ambient : 'local',
};

console.log('bizConfig', bizConfig);
