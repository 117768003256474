import React, { useEffect, useRef, useState } from 'react';
import {Html5QrcodeScanner, Html5QrcodeSupportedFormats} from 'html5-qrcode';
import styled from 'styled-components';
import closeIcon from 'assets/icon-close.svg';
import barcodeIcon from 'assets/barcode.svg';
import { body3, body4 } from 'components/Type';

const BarcodeWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;

  .barcodeIcon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    height: 56px;
    width: 15px;
    top: ${props => props.error ? 'calc(50% + 0px)' : 'calc(50% + 13px)'};
    transform: translateY(-50%);
  }

  .scannerContainer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 9998;
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9999;
    width: 40px;
    height: 40px;
    background-color: white;
    box-shadow: none;
    border: none;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }

  .field-error {
    ${body4}
    white-space: normal;
    word-wrap: break-word;
    width: 100%;
    max-width: 635px;
    position: relative;
    color: #CE2C30;
    margin-top: 2px;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    display: block;
    overflow: hidden;
    opacity: ${props => props.error ? '1' : '0'};
    max-height: ${props => props.error ? '200px' : '0'};
  }
`

const BarcodeInputStyled = styled.input`
  ${body3}
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  padding: 10px 20px;
  border: 1px solid #D0D0D0;
  border-color: ${props => props.error ? '#CE2C30' : '#D0D0D0'};
  border-radius: 5px;
  transition: border-color .3s ease-in-out, color .3s ease-in-out;
  outline: none;
  color: #4E4D4C;
  -moz-appearance:textfield;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  &:focus {
    outline: none;
    border-color: ${props => props.error ? '#CE2C30' : '#1A1A1A'};
    box-shadow: 0px 0px 1px #999;
    color: #1A1A1A;
  }

  &:disabled {
    color: #B2B1B0
  }

  ::placeholder {
    color: #B3B1B0;
  }
`
const Label = styled.label`
  ${body4}
  display: flex;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${props => !props.error ? `color: #807E7D;` : null}

  ${props => props.disabled ? `color: #B2B1B0;` : null}

  ${props => !!props.error ? `color: #CE2C30;` : null}
`

function BarcodeInput({
  id, name, label, errorMessage,
  onChange, value, type, placeholder,
  className, inputEl, disabled, maxLength, ...props
}) {
  const ref = useRef(null);
  const [scanBarcode, setScanBarcode] = useState(false);

  useEffect(() => {
    const height = document.body.offsetHeight;
    const width = document.body.offsetWidth;

    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      {
        experimentalFeatures: {
          useBarCodeDetectorIfSupported: true,
        },
        rememberLastUsedCamera: true,
        fps: 10,
        qrbox: {
          width: width * 0.667,
          height: width * 0.667
        },
        aspectRatio: Math.ceil(height / width),
        focusMode:"continuous",
        formatsToSupport: [
          Html5QrcodeSupportedFormats.CODE_39,
          Html5QrcodeSupportedFormats.CODE_93,
          Html5QrcodeSupportedFormats.CODE_128,
          Html5QrcodeSupportedFormats.EAN_13,
          Html5QrcodeSupportedFormats.EAN_8,
        ]
      }
    );

    html5QrcodeScanner.render((decodedText, decodedResult) => {
      console.log('Success');

      onChange({
        target: {
          value: decodedText
        }
      });

      setScanBarcode(false);

      html5QrcodeScanner.clear();
    });
  }, [])

  return (
    <BarcodeWrapper className={className} error={!!errorMessage}>
      {label && (<Label error={!!errorMessage} disabled={disabled}>{label}</Label>)}
      <BarcodeInputStyled
        id={id}
        ref={inputEl}
        name={name}
        type={type}
        error={!!errorMessage}
        onChange={onChange}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onWheel={(e) => e.target.blur()}
        {...props}
        maxLength={maxLength}
      />
      {scanBarcode ? null : <img className="barcodeIcon" onClick={() => setScanBarcode(!scanBarcode)} src={barcodeIcon} alt="Escanear código de barras" />}
      {
        <div className="scannerContainer" style={{'display': scanBarcode ? 'block' : 'none'}}>
          <button className="close" onClick={() => setScanBarcode(!scanBarcode)}>
            <img src={closeIcon} alt="Fechar" />
          </button>
          <div id="reader" className="scanner" />
        </div>
      }
      <div className="field-error">{errorMessage}</div>
    </BarcodeWrapper>
  )
};

BarcodeInput.defaultProps = {
  type: 'text'
}

export default BarcodeInput;
