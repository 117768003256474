import React from 'react';
import styled from 'styled-components';
import { body3, body4 } from 'components/Type';
import upload from 'assets/upload.svg';
import iconPublish from 'assets/icon-publish.svg';
import iconUnpublished from 'assets/icon-unpublish.svg';
import uploadLoading from 'assets/upload.svg';
// import uploadLoading from 'assets/upload-loading.svg';

const Wrapper = styled.div`
  margin-bottom: ${props => props.type === 'profile' ? '64px' : '20px'};
`

const DefaultInputStyled = styled.input`
  ${body3}
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  margin-bottom: 20px;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  color: #4E4D4C;

  &:focus {
    outline: none;
    border: 1px solid #999;
    box-shadow: 0px 0px 1px #999;
    color: #1A1A1A;
  }
`
const Label = styled.label`
  ${body4}
  display: flex;
  margin-bottom: 5px;
  color: #807E7D;
`

const Profile = styled.label`
  width: 100%;
  height: 162px;
  display: inline-block;
  background: #F6F6F4;
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  & > div {
    padding: 28px 15px;
    background-color: #FFF;
    border: 1px solid #D0D0D0;
    box-sizing: border-box;
    border-radius: 4px;
    position: absolute;
    width: 160px;
    height: 160px;
    left: 32px;
    top: 46px;
    text-align: center;
    cursor: pointer;

    ${props => props.image ? `
      background-size: cover;
      background-image: url(${props.image});
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;

      &:hover, &.loading {
        &:before {
          opacity: 1;
        }

        p, img {
          opacity: 1;
          z-index: 2;
          position: inherit;
        }
      }

      &.loading {
        img {
          animation: loading 3s linear infinite;
        }
      }

      &:before {
        content: '';
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95));
        opacity: 0;
        transition: opacity .2s ease-in;
        border-radius: 8px;
      }

      p, img {
        opacity: 0;
        transition: opacity .2s ease-in;
      }
    ` : `` }
  }

  p {
    ${body4}
    text-align: center;
    margin: 0;
    color: #807E7D;
    text-align: center;
  }

  & + input {
    display: none;
  }
`;

const Cover = styled.label`
  width: 100%;
  height: 160px;
  border: 1px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  div {
    width: 100%;
    height: 100%;
    display: inline-block;
    padding: 28px;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;

    ${props => props.image ? `
      background-size: cover;
      background-image: url(${props.image});
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;

      &:hover, &.loading {
        &:before {
          opacity: 1;
        }

        p, img {
          opacity: 1;
          z-index: 2;
          position: inherit;
        }
      }

      &.loading {
        img {
          animation: loading 3s linear infinite;
        }
      }

      &:before {
        content: '';
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95));
        opacity: 0;
        transition: opacity .2s ease-in;
      }

      p, img {
        opacity: 0;
        transition: opacity .2s ease-in;
      }
    ` : `` }
  }

  p {
    text-align: center;
    ${body4}
    margin: 0;
    color: #807E7D;
    text-align: center;
  }

  & + input {
    display: none;
  }
`;

function DefaultInput({
  id, name, type, label,
  onChange, image, value, placeholder, loading = false,
}) {
  return (
    <Wrapper type={type}>
      {label  && (<Label htmlFor={name}>{label}</Label>)}
      {
        (
          type === 'profile' && (
            <Profile image={image} htmlFor={name}>
              <div className={ loading ? 'loading' : '' }>
                <img src={ loading ? uploadLoading : upload  } alt="Tamanho mínimo 180 x 180" />
                {
                  (
                    loading ?
                    (<p>Carregando...</p>) :
                    (<p>Tamanho mínimo<br />180 x 180</p>)
                  )
                }
              </div>
            </Profile>
          )
        )
      }
      {
        (
          type === 'cover' && (
            <Cover image={image} htmlFor={name}>
              <div className={ loading ? 'loading' : '' }>
                <img src={ loading ? uploadLoading : upload } alt="Tamanho mínimo 1.584 x 396" />
                {
                  (
                    loading ?
                    (<p>Carregando...</p>) :
                    (<p>Selecione uma imagem</p>)
                  )
                }
              </div>
            </Cover>
          )
        )
      }
      {
        (
          type === 'file' && (
            <Cover image={null} htmlFor={name}>
              <div className={ loading ? 'loading' : '' }>
                <img src={ image ? iconPublish : iconUnpublished } alt={image ? 'Subir arquivo' : 'Alterar arquivo'} />
                {
                  (
                    loading ?
                    (<p>Carregando...</p>) :
                    (<p>{image ? 'Subir arquivo' : 'Alterar arquivo'}</p>)
                  )
                }
              </div>
            </Cover>
          )
        )
      }
      <DefaultInputStyled
        id={id}
        name={name}
        type="file"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />

    </Wrapper>
  )
};

DefaultInput.defaultProps = {

}

export default DefaultInput;
