import React, { createContext, useState } from 'react';
// import {
//   login, signup, recovery,
//   verify, resetPassword, whitelist,
// } from 'services/auth';
import { setData } from 'utils/token';
import 'cross-fetch/polyfill';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const isProduction = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const poolData = isProduction ? {
  UserPoolId: 'us-west-2_3L39i2SVw',
  ClientId: '2u9o85bunhg41ia9vtcvrq3fa3'
} : {
  UserPoolId: 'us-west-2_i2CXggFia',
  ClientId: 'n1dosqka04452gke4n8k1n5o5'
};

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [loginContent, setLogin] = useState({});

  const submitLogin = async (payload) => {
    setLogin({ loading: true });

    const authenticationData = {
      Username: payload.email,
      Password: payload.password,
    };

    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: authenticationData.Username,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        newPasswordRequired: (result) => {
          resolve({
            success: true,
            action: 'changePassword',
          });

          setLogin({ loading: false });
        },
        onSuccess: (session) => {
          cookies.set('userMail', session.idToken.payload.email, { path: '/' });

          setData({
            accessToken: session.getAccessToken(),
            refreshToken: session.getRefreshToken(),
            idToken: session.getIdToken(),
          });

          resolve({
            success: true,
            action: false,
          });

          setLogin({ loading: false });
        },
        onFailure: (err) => {
          resolve({
            success: false,
            action: false,
            message: (() => {
              if (err.code === 'NotAuthorizedException') {
                return 'Usuário ou senhas inválidos!';
              }

              return 'Erro inesperado do servidor!';
            })()
          });

          setLogin({ loading: false });
        },
      });
    });
  };

  const changePassword = async (payload) => {
    setLogin({ loading: true });

    const authenticationData = {
      Username: payload.email,
      Password: payload.oldPassword,
    };

    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
        Username: payload.email,
        Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        newPasswordRequired: function (result) {
          delete result.email_verified;
          delete result.phone_number_verified;
          result.name = userData.Username;

          cognitoUser.completeNewPasswordChallenge(payload.newPassword, result, {
            onSuccess: (session) => {
              setData({
                accessToken: session.getAccessToken(),
                refreshToken: session.getRefreshToken(),
                idToken: session.getIdToken(),
              });

              resolve({
                success: true,
                action: false,
              });

              setLogin({ loading: false });
            },
            onFailure: function (err) {
              resolve({
                success: false,
                action: false,
                message: (() => {
                  if (err.code === 'NotAuthorizedException') {
                    return 'Usuário ou senhas inválidos!';
                  }

                  return 'Erro inesperado do servidor!';
                })()
              });

              setLogin({ loading: false });
            },
          });
        },
        onFailure: function (err) {
          resolve({
            success: false,
            action: false,
            message: (() => {
              if (err.code === 'NotAuthorizedException') {
                return 'Usuário ou senhas inválidos!';
              }

              return 'Erro inesperado do servidor!';
            })()
          });

          setLogin({ loading: false });
        },
      })
    });
  };

  const submitResetPassword = async (payload) => {
    setLogin({ loading: true });

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: payload.email,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (result) => {
          resolve({
            success: true,
            action: false,
          });

          setLogin({ loading: false });
        },
        onFailure: (err) => {
          resolve({
            success: false,
            action: false,
            message: (() => {
              if (err.code === 'NotAuthorizedException') {
                return 'Usuário não encontrado!';
              }

              return 'Erro inesperado do servidor!';
            })()
          });

          setLogin({ loading: false });
        },
      });
    });
  };

  const submitVerify = async (payload) => {
    setLogin({ loading: true });

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: payload.email,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(payload.verificationCode, payload.newPassword, {
        onSuccess: (result) => {
          resolve({
            success: true,
            action: false,
          });

          setLogin({ loading: false });
        },
        onFailure: (err) => {
          resolve({
            success: false,
            action: false,
            message: (() => {
              if (err.code === 'CodeMismatchException' || err.code === 'NotAuthorizedException') {
                return 'Código inválido!';
              } else if (err.code === 'ExpiredCodeException') {
                return 'Código expirado!';
              }

              return 'Erro inesperado do servidor!';
            })()
          });

          setLogin({ loading: false });
        },
      });
    });
  };

  const clearAuthContext = () => {
    setLogin({});
  };

  return (
    <AuthContext.Provider value={{
      submitLogin, loginContent,
      submitResetPassword,
      submitVerify,
      changePassword,
      clearAuthContext,
    }}>
      {children}
    </AuthContext.Provider>
  )
}
