export const toDateSQL = function (v_Valor) {
    if (!v_Valor) {
        return "";
    }
    else {
        if (typeof v_Valor === "string") {
            if (v_Valor.substring(2, 3) == "/" && v_Valor.substring(5, 6) == "/") {
                if (v_Valor.length == 8) {
                    return "20" + v_Valor.substring(6, 9) + "-" + v_Valor.substring(3, 5) + "-" + v_Valor.substring(0, 2);
                }
                else {
                    return v_Valor.substring(6, 11) + "-" + v_Valor.substring(3, 5) + "-" + v_Valor.substring(0, 2);
                }
            }
            else if (v_Valor.substring(4, 5) == "-" && v_Valor.substring(7, 8) == "-")
                return v_Valor;
        }
        else {
            return v_Valor.getFullYear() + "-" + zeroEsquerda(v_Valor.getMonth() + 1, 2) + "-" + zeroEsquerda(v_Valor.getDate(), 2);
        }
    }
};

export const dataValida = function (v_Data) {
    let v_Retorno = true, v_i, v_Dia, v_Mes, v_Ano;

    if (v_Data == undefined)
        v_Data = "";

    if ((v_Data.length != 8) && (v_Data.length != 10))
        v_Retorno = false

    if (v_Retorno) {
        v_i = 0
        while (v_i < v_Data.length && v_Retorno) {
            if (v_i == 2 || v_i == 5) {
                if (v_Data.charAt(v_i) != "/")
                    v_Retorno = false;
            }
            else {
                if (isNaN(v_Data.charAt(v_i), 0))
                    v_Retorno = false;
            }
            v_i++;
        }

    }

    if (v_Retorno) {
        v_Dia = parseInt(v_Data.substring(0, 2), 10);
        v_Mes = parseInt(v_Data.substring(3, 5), 10);
        if (v_Data.length == 8) {
            v_Ano = parseInt("20" + v_Data.substring(6, 8), 10);
        }
        else {
            v_Ano = parseInt(v_Data.substring(6, 10), 10);
        }

        if (v_Mes < 1 || v_Mes > 12)
            v_Retorno = false;
        if (v_Ano < 1)
            v_Retorno = false;
    }

    if (v_Retorno) {
        if (v_Mes == 1 || v_Mes == 3 || v_Mes == 5 || v_Mes == 7 || v_Mes == 8 || v_Mes == 10 || v_Mes == 12) {
            if (v_Dia < 1 || v_Dia > 31)
                v_Retorno = false;
        }
        if (v_Mes == 2) {
            if (v_Ano % 4 == 0 && (v_Ano % 100 != 0 || v_Ano % 400 == 0)) {
                if (v_Dia < 1 || v_Dia > 29)
                    v_Retorno = false;
            }
            else {
                if (v_Dia < 1 || v_Dia > 28)
                    v_Retorno = false;
            }
        }
        if (v_Mes == 4 || v_Mes == 6 || v_Mes == 9 || v_Mes == 11) {
            if (v_Dia < 1 || v_Dia > 30)
                v_Retorno = false;
        }
    }

    return v_Retorno;
};

export const zeroEsquerda = function (v_Valor, v_Tamanho) {
    v_Valor = v_Valor.toString();
    while (v_Valor.length < v_Tamanho)
        v_Valor = 0 + v_Valor;
    return v_Valor;
};

export const toInt = function (v_Valor) {
    if (typeof v_Valor == "boolean") {
        v_Valor = Se(v_Valor, 1, 0);
    }

    if (typeof v_Valor == "string") {
        while (v_Valor.indexOf(".") != -1)
            v_Valor = v_Valor.replace(".", "");
    }

    return Se((v_Valor == "" || v_Valor == null), 0, parseInt(v_Valor));
};

export const toDateJavaScript = function (v_Valor) {
    try {
        var date = new Date();
        if (typeof v_Valor == "string") {
            if (v_Valor.substring(2, 3) == "/" && v_Valor.substring(5, 6) == "/") {
                date.setDate(v_Valor.substring(0, 2));
                date.setMonth(toInt(v_Valor.substring(3, 5)) - 1);
                date.setFullYear(v_Valor.substring(6, 11));
                return date;
            }
            else if (v_Valor.substring(4, 5) == "-" && v_Valor.substring(7, 8) == "-") {
                date.setDate(v_Valor.substring(8, 10));
                date.setMonth(toInt(v_Valor.substring(5, 7)) - 1);
                date.setFullYear(v_Valor.substring(0, 4));
                return date;
            }
        }
    }
    catch (v_E) {
        console.log('Erro ao converter essa porr: ' + v_Valor);
    }
};


export const Se = function (v_Condicao, v_Verdadeiro, v_Falso, v_executarFuncao) {
    if (v_Condicao) {
        if (isFunction(v_Verdadeiro) && (v_executarFuncao != false))
            return v_Verdadeiro();
        else
            return v_Verdadeiro;
    }
    else {
        if (isFunction(v_Falso) && (v_executarFuncao != false))
            return v_Falso();
        else
            return v_Falso;
    }
};

export const isFunction = function (f_Function) {
    return f_Function && {}.toString.call(f_Function) === '[object Function]';
};

export const toDate8 = function (v_Valor) {
    try {
        if (!v_Valor)
            return null;

        if (typeof v_Valor == "object") {
            return zeroEsquerda(v_Valor.getDate(), 2) + "/" + zeroEsquerda(v_Valor.getMonth() + 1, 2) + "/" + v_Valor.getFullYear().toString().substring(2,4);;
        }
        else {
            if (v_Valor.substring(4, 5) == "-" && v_Valor.substring(7, 8) == "-")
                return v_Valor.substring(8, 10) + "/" + v_Valor.substring(5, 7) + "/" + v_Valor.substring(2, 4);
            else if (v_Valor.substring(2, 3) == "/" && v_Valor.substring(5, 6) == "/")
                return v_Valor;
        }
    }
    catch (v_E) {
        console.log('Erro ao converter: ' + v_Valor);
    }
};

export const toDate = function (v_Valor) {
    try {
        if (!v_Valor)
            return null;

        if (typeof v_Valor == "object") {
            return zeroEsquerda(v_Valor.getDate(), 2) + "/" + zeroEsquerda(v_Valor.getMonth() + 1, 2) + "/" + v_Valor.getFullYear();
        }
        else {
            if (v_Valor.substring(4, 5) == "-" && v_Valor.substring(7, 8) == "-")
                return v_Valor.substring(8, 10) + "/" + v_Valor.substring(5, 7) + "/" + v_Valor.substring(0, 4);
            else if (v_Valor.substring(2, 3) == "/" && v_Valor.substring(5, 6) == "/")
                return v_Valor;
        }
    }
    catch (v_E) {
        console.log('Erro ao converter: ' + v_Valor);
    }
};
export const toFloat = function (v_Valor) {
    if (v_Valor === null || v_Valor === undefined || v_Valor == "")
        v_Valor = 0;

    if (typeof v_Valor == "string" && v_Valor.indexOf(",") >= 0) {
        while (v_Valor.indexOf(".") > 0)
            v_Valor = v_Valor.replace(".", "");
        v_Valor = v_Valor.replace(",", ".");
    }
    else if (typeof v_Valor == "boolean") {
        v_Valor = Se(v_Valor, 1, 0);
    }

    if (typeof v_Valor == "string" || typeof v_Valor == "number")
        return parseFloat(v_Valor);
    else
        alert("Você só pode converter uma string ou ou número em float, figura!");
};

export const toMoney = function (v_Valor, v_qtdCasasDecimais) {
    var v_Cents, v_Retorno = "0,00";
    var v_Negativo = 0, v_Centavos;

    if (typeof v_Valor != "number") {
        v_Valor = toFloat(v_Valor);
    }

    if (v_Valor < 0) {
        v_Valor = Math.abs(v_Valor);
        v_Negativo = 1;
    }

    v_Valor = eval(v_Valor).toFixed(v_qtdCasasDecimais);

    var v_aux = (v_qtdCasasDecimais == 2) ? 100 : (v_qtdCasasDecimais == 3) ? 1000 : (v_qtdCasasDecimais == 4) ? 10000 : (v_qtdCasasDecimais == 5) ? 100000 : 1000000;

    v_Centavos = Math.floor((v_Valor * v_aux + 0.5) % v_aux);

    v_Valor = Math.floor((v_Valor * 10000 + 0.5) / 10000).toString();

    v_Cents = v_Centavos.toString();

    if (v_qtdCasasDecimais == 0) {
        v_Centavos = "";
    }
    else if (v_qtdCasasDecimais == 1) {
        v_Centavos = (v_Cents.length == 0) ? v_Centavos = "0" : v_Cents.substring(0, 1);
    }
    else if (v_qtdCasasDecimais == 2) {
        if (v_Cents.length == 1)
            v_Centavos = "0" + v_Centavos;
    }
    else if (v_qtdCasasDecimais == 3) {
        if (v_Cents.length == 1)
            v_Centavos = "00" + v_Centavos;
        if (v_Cents.length == 2)
            v_Centavos = "0" + v_Centavos;
    }
    else if (v_qtdCasasDecimais == 4) {
        if (v_Cents.length == 1)
            v_Centavos = "000" + v_Centavos;
        if (v_Cents.length == 2)
            v_Centavos = "00" + v_Centavos;
        if (v_Cents.length == 3)
            v_Centavos = "0" + v_Centavos;
    }
    else if (v_qtdCasasDecimais == 5) {
        if (v_Cents.length == 1)
            v_Centavos = "0000" + v_Centavos;
        if (v_Cents.length == 2)
            v_Centavos = "000" + v_Centavos;
        if (v_Cents.length == 3)
            v_Centavos = "00" + v_Centavos;
        if (v_Cents.length == 4)
            v_Centavos = "0" + v_Centavos;
    }
    else if (v_qtdCasasDecimais == 6) {
        if (v_Cents.length == 1)
            v_Centavos = "00000" + v_Centavos;
        if (v_Cents.length == 2)
            v_Centavos = "0000" + v_Centavos;
        if (v_Cents.length == 3)
            v_Centavos = "000" + v_Centavos;
        if (v_Cents.length == 4)
            v_Centavos = "00" + v_Centavos;
        if (v_Cents.length == 5)
            v_Centavos = "0" + v_Centavos;
    }

    if (v_Cents.length > v_qtdCasasDecimais) {
        v_Centavos = v_Cents.substring(0, v_qtdCasasDecimais);
    }

    for (var v_i = 0; v_i < Math.floor((v_Valor.length - (1 + v_i)) / 3); v_i++)
        v_Valor = v_Valor.substring(0, v_Valor.length - (4 * v_i + 3)) + '.' + v_Valor.substring(v_Valor.length - (4 * v_i + 3));

    if (v_Centavos != "")
        v_Retorno = v_Valor + ',' + v_Centavos;
    else
        v_Retorno = v_Valor;

    if (v_Negativo == 1)
        v_Retorno = '-' + v_Retorno;

    return v_Retorno;
};

export const toDecimal = function (v_Valor, v_CasasDecimais) {
    return toMoney(v_Valor, v_CasasDecimais);
};


export const groupBy = key => array =>
    array.reduce(
        (objectsByKeyValue, obj) => ({
            ...objectsByKeyValue,
            [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
        }),
        {}
    );

export const formatarValorDecimal = (valor) => {
    let virgulas = valor.match(/,/g);
    if (virgulas) {
        for (let i = 0; i < virgulas.length - 1; i++) {
            valor = valor.replace(",", "")
        }
    }
    return valor.replace(/[^\d\,]/g, "");
}

export const somarMinutos = (valor, somaMinutos) => {
    if (!valor) return "";

    let data = new Date();
    valor = valor.split(':');
    let hora = valor[0];
    let minutos = valor[1];
    data.setHours(parseInt(hora));
    data.setMinutes(parseInt(minutos) + somaMinutos)

    return `${zeroEsquerda(data.getHours(), 2)}:${zeroEsquerda(data.getMinutes(), 2)}`;
}
