import React, { useState, useEffect } from 'react';
import { bizConfig } from '../../../utils/bizConfig';
import Table2Input from '../../../components/Table2';
import { toDate, groupBy, toDecimal } from '../../../utils/utils';
import InternalLayout from '../../../components/InternalLayout';
import Divider from '../../../components/Divider';
import styled from 'styled-components';
const Section = styled.section`
  hr {
    margin: 0;
  }
`;
function ListaApontamentosPage(props) {
    const { history, match } = props;

    const idProducao = match?.params?.idProducao;
    const idFormulario = match?.params?.idFormulario;
    const status = match?.params?.status;
    const possuiPesagemProducao = match?.params?.possuiPesagemProducao;

    const [apontamentos, setApontamentos] = useState();
    const [grupo, setGrupo] = useState();
    const [sequencia, setSequencia] = useState();
    const [producao, setProducao] = useState(null);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        if (!apontamentos) return;

        const groupByBrand = groupBy('inq_id_atributo');
        setGrupo(groupByBrand(apontamentos));

        const groupByBrand2 = groupBy('inq_sequencia_listagem');
        setSequencia(groupByBrand2(apontamentos));

    }, [apontamentos]);

    const formatarValor = (item) => {
        switch (item.inq_id_tipo_atributo) {

            case "02":
                return toDecimal(item.inq_valor_atributo, item.inq_valor_atributo.replaceAll(",", ".").indexOf(".") > -1 ? item.inq_valor_atributo.length - item.inq_valor_atributo.replaceAll(",", ".").indexOf(".") - 1 : 2);
            case "10":
                switch (item.inq_id_lista_selecao) {
                    case "014":
                        return item.inq_valor_atributo ? "OK" : "Não OK";
                    case "015":
                        return item.inq_valor_atributo == 'true' ? "Aprovado" : "Reprovado";
                    case "003":
                        return item.inq_valor_atributo == 'true' ? "Sim" : "Não";
                }
            case "08":
                return (<img className='maxW400' src={item.inq_valor_atributo} />)
            default:
                return item.inq_valor_atributo;
        }
    }

    useEffect(() => {
        if (!idProducao) return;
        ConsultarApontamentos();
        ConsultarProducao();
    }, [idProducao]);

    const ConsultarApontamentos = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqProducaoApontamentos/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}/${idFormulario}/1/0`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setApontamentos(response);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const ConsultarProducao = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqProducao/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setProducao(response);
                setShowLoader(false);
            })
            .catch((error) => {
                // history.push({ pathname: '/lista-producao' });
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
                setShowLoader(false);
            })
    }
    return (
        <InternalLayout>
            <>
                {producao && <Section>
                    <>
                        <div className={'flex-container'}>
                            <div className={'flex-item-grow-2'}>
                                <h2>{producao.prd_codigo} - {producao.prd_descricao}</h2>
                            </div>
                            <div className={'flex-item-grow-1'} style={{ textAlign: 'right' }}>
                                <h2>Data: {toDate(producao.inq_data_acondicionamento)}</h2>
                            </div>
                        </div>
                        <div className={'flex-container'}>
                            <p className={'flex-item-grow-2 mt-0'}>
                                <strong>Linha: </strong>{producao.inq_nome_linha}
                            </p>
                            <p className={'flex-item-grow-2 mt-0'}>
                                <strong>EAN: </strong>{producao.ean}
                            </p>
                            <p className={'flex-item-grow-2 mt-0'}>
                                <strong>DUN: </strong>{producao.dun + (producao.dun && (producao.inq_dun_1 || producao.inq_dun_2 || producao.inq_dun_3) ? " - " : "") +
                                    producao.inq_dun_1 + (producao.inq_dun_1 && producao.inq_dun_2 ? " / " : "") + producao.inq_dun_2 +
                                    (producao.inq_dun_1 || producao.inq_dun_2 ? " / " : "") + producao.inq_dun_3}
                            </p>
                            <p className={'flex-item-grow-2 mt-0'} style={{ textAlign: 'right' }}>
                                <strong>Lote: </strong> {producao.inq_lote} - {producao.inq_data_validade_lote}
                            </p>
                        </div>
                        {(producao.inq_qrcode_1 || producao.inq_qrcode_2 || producao.inq_qrcode_3) &&
                            <div className={'flex-container'}>
                                <p className={'flex-item-grow-2 mt-0'}>
                                    <strong>QR Code: </strong>{producao.inq_qrcode_1 + (producao.inq_qrcode_1 && producao.inq_qrcode_2 ? " / " : "") + producao.inq_qrcode_2 +
                                        (producao.inq_qrcode_1 || producao.inq_qrcode_2 ? " / " : "") + producao.inq_qrcode_3}
                                </p>
                            </div>}
                        <Divider variant="dark" />
                    </>
                </Section>
                }
                {apontamentos && grupo &&
                    <div className={"w-100"} style={{ minWidth: "100%", height: "80vh", marginTop: "20px", overflowX: "auto" }}>
                        <Table2Input
                            style={{ width: "max-content" }}
                            children={
                                [
                                    <thead key={'thead-cuba'}>
                                        <tr>
                                            {
                                                Object.keys(grupo).map((item, key) =>
                                                    <td key={`th-${key}`}>{

                                                        apontamentos.find(a => a.inq_id_atributo == item).inq_nome_atributo

                                                    }</td>
                                                )
                                            }
                                            {status == 'IN' &&
                                                <td className='text-center'>#</td>
                                            }
                                        </tr>
                                    </thead>,
                                    <tbody key={'tbody-cuba'}>
                                        {sequencia &&
                                            Object.keys(sequencia).map((apontamento, index) => {
                                                let linkHRef;

                                                if (apontamento != "999999|999999") {
                                                    linkHRef =
                                                        '/#/editar-producao/' +
                                                        apontamento.split("|")[1] + '/' +
                                                        possuiPesagemProducao.toString() + '/' +
                                                        status + '/' +
                                                        idFormulario + '/' +
                                                        apontamento.split("|")[0];
                                                }

                                                return (
                                                    <tr className='desktop' key={`tr-cuba-${index}`}>
                                                        {

                                                            Object.keys(grupo).map((item, key) =>
                                                                <td key={`td-${key}`}>{
                                                                    formatarValor(apontamentos.find(a => a.inq_id_atributo == item && a.inq_sequencia_listagem == apontamento))
                                                                }</td>
                                                            )
                                                        }
                                                        {status == 'IN' && (apontamento != "999999|999999") &&
                                                            <td className='text-center'>
                                                                <a href={`${linkHRef}`}
                                                                    style={{ marginTop: ".4em" }}
                                                                >
                                                                    Editar
                                                                </a>
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            }
                                            )
                                        }
                                        {sequencia &&
                                            Object.keys(sequencia).map((apontamento, index) => {
                                                let linkHRef;
                                                if (apontamento != "999999|999999") {
                                                    linkHRef =
                                                        '/#/editar-producao/' +
                                                        apontamento.split("|")[1] + '/' +
                                                        possuiPesagemProducao.toString() + '/' +
                                                        status + '/' +
                                                        idFormulario + '/' +
                                                        apontamento.split("|")[0];
                                                }

                                                return (
                                                    <tr className='mobile' key={`tr2-cuba-${index}`}>
                                                        {
                                                            Object.keys(grupo).map((item, key) =>
                                                                <td key={`td2-${key}`}>
                                                                    <strong>
                                                                        {
                                                                            apontamentos.find(a => a.inq_id_atributo == item).inq_nome_atributo + ": "
                                                                        }
                                                                    </strong>
                                                                    {
                                                                        formatarValor(apontamentos.find(a => a.inq_id_atributo == item && a.inq_sequencia_listagem == apontamento))
                                                                    }
                                                                </td>
                                                            )
                                                        }
                                                        {status == 'IN' && (apontamento != "999999|999999") &&
                                                            <td className='text-center'>
                                                                <a href={`${linkHRef}`}
                                                                    style={{ marginTop: ".4em" }}
                                                                >
                                                                    Editar
                                                                </a>
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            }
                                            )
                                        }
                                    </tbody>
                                ]
                            }
                        />
                    </div>
                }
            </>
        </InternalLayout >
    );
}

export default ListaApontamentosPage;