import React, { useState, useEffect } from 'react';
import { bizConfig } from '../../../utils/bizConfig';
import Loading from '../../../components/Loading';
import CardInput from '../../../components/Card';
import CardInputNok from '../../../components/CardNok';
import Typography from '../../../components/Typography';
import { getData } from 'utils/token';
import styled from 'styled-components';

const Section = styled.section`
  hr {
    margin: 0;
  }
`;

const Header = styled.header`
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-weight: 400;
    width: 100%;
    text-align: left;
  }
`;

function ListaFormulariosPage(props) {
    const { idProducao, setSelectedMenu, setIdFormulario, setNomeFormulario, setPeriodicidade, setPossuiPesagem, status, possuiPesagem, idFormularioEdicao, idSequenciaEdicao, setLancamentoPOSFinalizar } = props;
    const [formularios, setFormularios] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [acessosUsuario, setAcessosUsuario] = useState(null);
    const userData = getData();

    if (idFormularioEdicao) {
        setSelectedMenu('apontamentosFormulario');
        setIdFormulario(idFormularioEdicao);
    }

    useEffect(() => {
        if (!idProducao) return;
        setShowLoader(true);
        ConsultarFormularios();
    }, [idProducao]);

    const ConsultarFormularios = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqProducaoFormularios/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}/${userData.usuario}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setFormularios(response);
                ConsultarAcessosUsuario();
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const ConsultarAcessosUsuario = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqAcessosProducao/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}/${userData.usuario}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setAcessosUsuario(response);
                setShowLoader(false);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const GerarDossie = () => {
        setShowLoader(true);
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/Dossie/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}/${status}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setShowLoader(false);
                var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
                window.location.assign(response.arquivo + "?" + timeStampInMs);
                console.log(response);
            })
            .catch((error) => {
                setShowLoader(false);
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const alterarStatusLancamento = (operacao) => {
        setShowLoader(true);
        let requestInfo = {
            method: 'POST',
            headers: bizConfig.requestHeaders,
            body: JSON.stringify(
                {
                    "cad_empresa": bizConfig.cad_empresa,
                    "cad_filial": bizConfig.cad_filial,
                    "inq_id_producao": idProducao,
                    "operacao": operacao,
                    "prd_referencia": "",
                    "prd_codigo": "",
                    "inq_id_linha": "",
                    "inq_lote": "",
                    "inq_data_acondicionamento": "",
                    "inq_data_validade_lote": "",
                    "inq_motivo_cancelamento": "",
                    "inq_usuario": userData.usuario,
                    "inq_motivo_alteracao": "Finalizando produção",
                    "inq_id_status_producao": "",
                    "inq_id_turno": "",
                    "inq_unificador_lote": "",
                    "inq_dun_1": "",
                    "inq_dun_2": "",
                    "inq_dun_3": "",
                    "inq_qrcode_1": "",
                    "inq_qrcode_2": "",
                    "inq_qrcode_3": ""
                }
            )
        };
        fetch(`${bizConfig.rootApi}/InqProducao`, requestInfo)
            .then(response => {

                if (response.status === 200 || response.status === 400) {
                    return response;
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setShowLoader(false);
                window.location.href = '/#/lista-producao/' + status;
            })
            .catch((error) => {
                setShowLoader(false);
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    return (
        <>
            <Loading show={showLoader} size="big" />
            <Header style={{ justifyContent: 'space-between' }}>
                <Typography variant="h3" >Formulários</Typography>
                <div className='cardsProducaoX'>
                    { !bizConfig.online && status == 'IN' && acessosUsuario && acessosUsuario.length > 0 && (acessosUsuario[0].novaProducao == 1) &&
                        <a
                            className='b-1 p-1 ml-1'
                            style={{ cursor: 'pointer' }}
                            href={"/#/producao-editar/" +
                                idProducao + '/' +
                                possuiPesagem + '/' +
                                status
                            }
                        >
                            Alterar produção
                        </a>
                    }
                    { bizConfig.online && acessosUsuario && acessosUsuario.length > 0 && (acessosUsuario[0].gerarDossie == 1) &&
                        <a
                            className='b-1 p-1 ml-1'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                GerarDossie();
                            }}
                        >
                            Gerar Dossiê
                        </a>
                    }
                    { !bizConfig.online && status == 'IN' && acessosUsuario && acessosUsuario.length > 0 && (acessosUsuario[0].finalizarLancamentos == 1) &&
                        <a
                            className='b-1 p-1 ml-1'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                alterarStatusLancamento(4);
                            }}
                        >
                            Finalizar lançamentos
                        </a>
                    }
                    { !bizConfig.online && status != 'FI' && acessosUsuario && acessosUsuario.length > 0 && (acessosUsuario[0].cancelarProducao == 1) &&
                        <a
                            className='b-1 p-1 ml-1'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                alterarStatusLancamento(3);
                            }}
                        >
                            Cancelar produção
                        </a>
                    }
                    { !bizConfig.online && status == 'FI' && acessosUsuario && acessosUsuario.length > 0 && (acessosUsuario[0].revisarLancamentos == 1) &&
                        <a
                            className='b-1 p-1 ml-1'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                alterarStatusLancamento(5);
                            }}
                        >
                            Revisar lançamentos
                        </a>
                    }
                    <a
                        className='b-1 p-1 ml-1'
                        style={{ cursor: 'pointer' }}
                        href={"/#/lista-producao/" + status}
                    >
                        Voltar
                    </a>
                </div>

            </Header>
            <div className={'cardsProducao'}>
                {
                    (acessosUsuario && acessosUsuario.length > 0 && acessosUsuario[0].cadastrarCubas == 1 &&
                     acessosUsuario && acessosUsuario.length > 0 && acessosUsuario[0].possuiCubas == 0 &&
                        <CardInputNok
                            key={`card-formulario-000`}
                            children={
                                (
                                    <>
                                        IT#053 anexo I - TROCA DE CUBA / BAG / BEEN / CONTAINER / ISOTANK / CHEP / FI / TANQUE
                                        {!bizConfig.online && <a
                                            style={{ marginTop: ".4em" }}
                                            onClick={() => {
                                                setSelectedMenu('cuba');
                                                setIdFormulario('000');
                                                setNomeFormulario('Cubas');
                                                setPeriodicidade('');
                                                setPossuiPesagem('');
                                                setLancamentoPOSFinalizar(0);
                                            }}
                                        >
                                            Entrar
                                        </a>}
                                    </>
                                )
                            }
                        />)
                    ||
                    (acessosUsuario && acessosUsuario.length > 0 && acessosUsuario[0].cadastrarCubas == 1 &&
                     acessosUsuario && acessosUsuario.length > 0 && acessosUsuario[0].possuiCubas == 1 &&
                        <CardInput
                            key={`card-formulario-000`}
                            children={
                                (
                                    <>
                                        IT#053 anexo I - TROCA DE CUBA / BAG / BEEN / CONTAINER / ISOTANK / CHEP / FI / TANQUE
                                        {!bizConfig.online && <a
                                            style={{ marginTop: ".4em" }}
                                            onClick={() => {
                                                setSelectedMenu('cuba');
                                                setIdFormulario('000');
                                                setNomeFormulario('Cubas');
                                                setPeriodicidade('');
                                                setPossuiPesagem('');
                                                setLancamentoPOSFinalizar(0);
                                            }}
                                        >
                                            Entrar
                                        </a>}
                                    </>
                                )
                            }
                        />)
                }
                {
                    formularios.map((element, index) => (
                        (element.inq_pendente == 0 &&
                            <CardInput
                                key={`card-formulario-${index}`}
                                children={
                                    (
                                        <>
                                            {element.inq_nome_formulario}
                                            {!bizConfig.online && <a
                                                style={{ marginTop: ".4em" }}
                                                onClick={() => {
                                                    setSelectedMenu('apontamentosFormulario');
                                                    setIdFormulario(element.inq_id_formulario);
                                                    setNomeFormulario(element.inq_nome_formulario);
                                                    setPeriodicidade(element.inq_periodicidade);
                                                    setPossuiPesagem(element.inq_possui_pesagem);
                                                    setLancamentoPOSFinalizar(element.inq_lancamento_pos_finalizar);
                                                }}
                                            >
                                                Entrar
                                            </a>}
                                        </>
                                    )
                                }
                            />)
                        ||
                        (element.inq_pendente == 1 &&
                            <CardInputNok
                                key={`card-formulario-${index}`}
                                children={
                                    (
                                        <>
                                            {element.inq_nome_formulario}
                                            {!bizConfig.online && <a
                                                style={{ marginTop: ".4em" }}
                                                onClick={() => {
                                                    setSelectedMenu('apontamentosFormulario');
                                                    setIdFormulario(element.inq_id_formulario);
                                                    setNomeFormulario(element.inq_nome_formulario);
                                                    setPeriodicidade(element.inq_periodicidade);
                                                    setPossuiPesagem(element.inq_possui_pesagem);
                                                    setLancamentoPOSFinalizar(element.inq_lancamento_pos_finalizar);
                                                }}
                                            >
                                                Entrar
                                            </a>}
                                        </>
                                    )
                                }
                            />)

                    )
                    )
                }

            </div>
        </>
    );
}

export default ListaFormulariosPage;