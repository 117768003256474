import React, { useState, useEffect } from 'react';
import { bizConfig } from '../../../utils/bizConfig';
import Loading from '../../../components/Loading';
import AtributoPage from '../Atributo/Index';
import styled from 'styled-components';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import { groupBy, toDateSQL, dataValida, toDate8, somarMinutos, toFloat, toDecimal } from '../../../utils/utils';
import TextareaInput from '../../../components/Input/Textarea';
import { getData } from 'utils/token';
import Pesagem from '../../Pesagem';

const Section = styled.section`
  hr {
    margin: 0;
  }
`;

const Header = styled.header`
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-weight: 400;
    width: 100%;
    text-align: left;
  }
`;

function LancamentosFormulariosPage(props) {
    const { idProducao, idFormulario, nomeFormulario, setSelectedMenu, periodicidade, possuiPesagem, status, idFormularioEdicao, idSequenciaEdicao, lancamentoPOSFinalizar } = props;
    const [showLoaderAtributosUnicos, setShowLoaderAtributosUnicos] = useState(true);
    const [showLoaderAtributosRepeticao, setShowLoaderAtributosRepeticao] = useState(true);
    const [atributosUnicos, setAtributosUnicos] = useState();
    const [operacao, setOperacao] = useState(1);
    const [valoresAtributosUnicos, setValoresAtributosUnicos] = useState([]);
    const [validFormAtributosUnicos, setValidFormAtributosUnicos] = useState(true);
    const [loadingSubmitAtributosUnicos, setLoadingSubmitAtributosUnicos] = useState();
    const [valoresAtributosRepeticao, setValoresAtributosRepeticao] = useState([]);
    const [validFormAtributosRepeticao, setValidFormAtributosRepeticao] = useState(true);
    const [atributosRepeticao, setAtributosRepeticao] = useState();
    const [loadingSubmitAtributosRepeticao, setLoadingSubmitAtributosRepeticao] = useState();
    const [ultimoLancamentoPesagem, setUltimoLancamentoPesagem] = useState();
    const [producao, setProducao] = useState();
    const [pesagem, setPesagem] = useState();
    const [media, setMedia] = useState(0);
    const [visualizarPesagem, setVisualizarPesagem] = useState(false);
    const [desvioPadrao, setDesvioPadrao] = useState(0);
    const [classeMedia, setClasseMedia] = useState("");
    const [ean, setEan] = useState();
    const [dun, setDun] = useState();
    const [qrCode, setQrCode] = useState();
    const [motivoAlteracao, setMotivoAlteracao] = useState("_");
    const [exibirObsarvacoes, setExibirObservacoes] = useState(false);
    const [producaoPlanejada, setProducaoPlanejada] = useState();
    const [producaoRealizada, setProducaoRealizada] = useState();
    const [minimoBoticario, setMinimoBoticario] = useState(0);
    const [nominalBoticario, setNominalBoticario] = useState(0);
    const [maximoBoticario, setMaximoBoticario] = useState(0);

    const [minimoBoticarioML, setMinimoBoticarioML] = useState(0);
    const [nominalBoticarioML, setNominalBoticarioML] = useState(0);
    const [maximoBoticarioML, setMaximoBoticarioML] = useState(0);

    const userData = getData();

    useEffect(() => {
        if (producao) {
            const mediaCalc = toFloat(media);
            setClasseMedia("")

            if (mediaCalc == 0) return;

            if (idFormulario != "0024") {
                if ((idFormulario == "0050") || (idFormulario == "0052")) {
                    if (mediaCalc < minimoBoticario)
                        setClasseMedia("pesoVermelho")

                    if (mediaCalc > maximoBoticario)
                        setClasseMedia("pesoAmarelo")

                    if (media < minimoBoticario) {
                        setValidFormAtributosRepeticao(0);
                    }
                }
                else {
                    if (mediaCalc < producao?.inq_peso_minimo)
                        setClasseMedia("pesoVermelho")

                    if (idFormulario == "0049") {
                        if (mediaCalc > producao.inq_peso_nominal)
                            setClasseMedia("pesoAmarelo")
                    }
                    else {
                        if (mediaCalc > producao.inq_peso_maximo)
                            setClasseMedia("pesoAmarelo")
                    }

                    if (media < producao?.inq_peso_minimo) {
                        setValidFormAtributosRepeticao(0);
                    }
                }
            }
        }
    }, [media])

    useEffect(() => {
        if (!producaoPlanejada || !producaoRealizada)
            SetValorAtributoRepeticao("0020-0368", "0%");
        else
            SetValorAtributoRepeticao("0020-0368", toDecimal(100 * (producaoRealizada / producaoPlanejada), 0) + "%");

        if (document.getElementById("0020-0368"))
            document.getElementById("0020-0368").disabled = true;

    }, [producaoRealizada, producaoPlanejada])


    useEffect(() => {
        if (producao) {
            if (!ean) return;

            document.getElementById(`0013-0302`).classList.remove("pesoVermelho");

            if (ean != producao.ean) {
                document.getElementById(`0013-0302`).classList.add("pesoVermelho");
                SetValorAtributoRepeticao("0013-0303", false);
                document.getElementById("0013-0303").checked = false;
            } else {
                SetValorAtributoRepeticao("0013-0303", true);
                document.getElementById("0013-0303").checked = true;
            }
        }
    }, [ean])

    useEffect(() => {
        if (producao) {
            if (!dun) return;

            document.getElementById(idFormulario + "-0308").classList.remove("pesoVermelho");

            if ((producao.dun && dun == producao.dun) ||
                (producao.inq_dun_1 && dun == producao.inq_dun_1) ||
                (producao.inq_dun_2 && dun == producao.inq_dun_2) ||
                (producao.inq_dun_3 && dun == producao.inq_dun_3)) {
                SetValorAtributoRepeticao(idFormulario + "-0309", true);
                document.getElementById(idFormulario + "-0309").checked = true;
            } else {
                document.getElementById(idFormulario + "-0308").classList.add("pesoVermelho");
                SetValorAtributoRepeticao(idFormulario + "-0309", false);
                document.getElementById(idFormulario + "-0309").checked = false;
            }

        }
    }, [dun])

    useEffect(() => {
        if (producao) {
            if (!qrCode) return;

            document.getElementById(idFormulario + "-1503").classList.remove("pesoVermelho");

            if ((producao.inq_qrcode_1 && qrCode == producao.inq_qrcode_1) ||
                (producao.inq_qrcode_2 && qrCode == producao.inq_qrcode_2) ||
                (producao.inq_qrcode_3 && qrCode == producao.inq_qrcode_3)) {
                SetValorAtributoRepeticao(idFormulario + "-0309", true);
                document.getElementById(idFormulario + "-0309").checked = true;
            } else {
                document.getElementById(idFormulario + "-1503").classList.add("pesoVermelho");
                SetValorAtributoRepeticao(idFormulario + "-0309", false);
                document.getElementById(idFormulario + "-0309").checked = false;
            }

        }
    }, [qrCode])

    useEffect(() => {
        if (atributosUnicos && !valoresAtributosUnicos.length) {
            Object.keys(atributosUnicos).map(grupo => {
                if ((atributosUnicos[grupo].filter(a => a.inq_valor_atributo.length > 0).length) && (!atributosRepeticao)) {
                    setOperacao(2);
                }

                atributosUnicos[grupo].map(elemento => valoresAtributosUnicos.push({
                    idFormulario: elemento.inq_id_formulario,
                    idAtributo: elemento.inq_id_atributo,
                    valor: formatarValorFormulario(elemento.inq_id_tipo_atributo, elemento.inq_valor_atributo, elemento.inq_id_atributo, elemento.inq_periodicidade),
                    obrigatorio: elemento.inq_obrigatorio,
                    idAtributoDependencia: elemento.inq_id_atributo_dependencia,
                    valorAtributoDependencia: elemento.inq_valor_atributo_dependencia,
                    tipoAtributo: elemento.inq_id_tipo_atributo
                }));
            });
        }
    }, [atributosUnicos]);

    useEffect(() => {
        if (atributosRepeticao && !valoresAtributosRepeticao.length) {
            Object.keys(atributosRepeticao).map(grupo => {
                if (idFormularioEdicao) {
                    setOperacao(2);
                }

                atributosRepeticao[grupo].map(elemento => valoresAtributosRepeticao.push({
                    idFormulario: elemento.inq_id_formulario,
                    idAtributo: elemento.inq_id_atributo,
                    valor: formatarValorFormulario(elemento.inq_id_tipo_atributo, elemento.inq_valor_atributo, elemento.inq_id_atributo, elemento.inq_periodicidade),
                    obrigatorio: elemento.inq_obrigatorio,
                    idAtributoDependencia: elemento.inq_id_atributo_dependencia,
                    valorAtributoDependencia: elemento.inq_valor_atributo_dependencia,
                    tipoAtributo: elemento.inq_id_tipo_atributo
                }));
            });
        }
    }, [atributosRepeticao, ultimoLancamentoPesagem]);

    useEffect(() => {
        recarregarComponente();
    }, []);

    const recarregarComponente = () => {
        ConsultarAtributosRepeticao();
        ConsultarProducao();

        if (possuiPesagem || periodicidade) {
            ConsultarUltimoLancamentoPesagem();
        }

        if (possuiPesagem) {
            ConsultarPesagem();
        }
    }

    function validaAtributosUnicos() {
        if (valoresAtributosUnicos.length) {
            VerificarDependencias();

            let formValido = 1;

            valoresAtributosUnicos.map(element => {
                if ((element.obrigatorio == 1) &&
                    (document.getElementById(element.idFormulario + "-" + element.idAtributo)) &&
                    (!document.getElementById(element.idFormulario + "-" + element.idAtributo).hasAttribute("disabled")) &&
                    ((!element.valor || element.valor.length < 1) ||
                        (element.tipoAtributo == '05' && !dataValida(element.valor)))) {
                    formValido = 0;
                }
            });

            setValidFormAtributosUnicos(formValido);

            if (idFormulario == "0004") {
                corSanitizante("0004-0580", "0004-0102");
                corSanitizante("0004-0104", "0004-0105");
            }

        }
    }

    useEffect(() => {
        validaAtributosUnicos();
    }, [valoresAtributosUnicos]);

    function validaAtributosRepeticao() {
        if (valoresAtributosRepeticao.length) {
            VerificarDependenciasRepeticao();

            let formValido = 1;

            valoresAtributosRepeticao.map(element => {
                if ((element.obrigatorio == 1) &&
                    (document.getElementById(element.idFormulario + "-" + element.idAtributo)) &&
                    (!document.getElementById(element.idFormulario + "-" + element.idAtributo).hasAttribute("disabled")) &&
                    ((!element.valor || element.valor.length < 1) ||
                        (element.tipoAtributo == '05' && !dataValida(element.valor)))) {
                    formValido = 0;
                }
            });

            setValidFormAtributosRepeticao(formValido);

            if (pesagem) {
                alimentarMediaPesagem();
                verificarPesagem();

                if ((idFormulario == "0050") || (idFormulario == "0052")) {
                    if (media < minimoBoticario) {
                        setValidFormAtributosRepeticao(0);
                    }
                }
                else {
                    if (media < producao?.inq_peso_minimo) {
                        setValidFormAtributosRepeticao(0);
                    }
                }
            }

            if (idFormulario == "0013") {
                alimentarEan();
            }

            if ((idFormulario == "0014") || (idFormulario == "0054") || (idFormulario == "0055")) {
                alimentarDun();
            }

            if ((idFormulario == "0056") || (idFormulario == "0057") || (idFormulario == "0058")) {
                alimentarQRCode();
            }

            if (idFormulario == "0020") {
                alimentarEficiencia();
            }

            if ((idFormulario == "0036") || (idFormulario == "0051") || (idFormulario == "0052")) {
                alimentarResultados();
                verificarPesagem0036();
            }

            if (idFormulario == "0008" || idFormulario == "0027" || idFormulario == "0029" || idFormulario == "0030" || idFormulario == "0031" || idFormulario == "0044" ||idFormulario == "0061") {
                alimentarNQA();
            }

            if ((idFormulario == "0009") || (idFormulario == "0024")) {
                alimentarTorque();
            }
        }
    }

    useEffect(() => {
        validaAtributosRepeticao();
    }, [valoresAtributosRepeticao, ultimoLancamentoPesagem]);

    function addClass(id, classe) {
        var elemento = document.getElementById(id);
        var classes = elemento.className.split(' ');
        var getIndex = classes.indexOf(classe);

        if (getIndex === -1) {
            classes.push(classe);
            elemento.className = classes.join(' ');
        }
    }

    function delClass(id, classe) {
        var elemento = document.getElementById(id);
        var classes = elemento.className.split(' ');
        var getIndex = classes.indexOf(classe);

        if (getIndex > -1) {
            classes.splice(getIndex, 1);
        }
        elemento.className = classes.join(' ');
    }

    const corSanitizante = (elemento1, elemento2) => {
        let valorInicial = document.getElementById(elemento1).value;
        let valorFinal = document.getElementById(elemento2).value;

        if ((valorInicial) && (valorFinal)) {
            valorInicial = toFloat(valorInicial);
            valorFinal = toFloat(valorFinal);

            if (valorInicial > 0) {
                valorFinal = valorFinal / valorInicial;

                if ((valorFinal > 1.10) || (valorFinal < 0.9)) {
                    addClass(elemento2, "pesoAmarelo");
                }
                else {
                    delClass(elemento2, "pesoAmarelo");
                }
            }
            else {
                delClass(elemento2, "pesoAmarelo");
            }
        }
        else {
            delClass(elemento2, "pesoAmarelo");
        }
    }

    const ConsultarAtributosUnicos = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqRetornoFormularios/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}/${idFormulario}/2/0`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                const groupByBrand = groupBy('inq_agrupador');
                setAtributosUnicos(groupByBrand(response));
                setShowLoaderAtributosUnicos(false);

                if (idFormulario == "0004") {
                    corSanitizante("0004-0580", "0004-0102");
                    corSanitizante("0004-0104", "0004-0105");
                }

                validaAtributosUnicos();
            })
            .catch((error) => {
                setShowLoaderAtributosUnicos(false);
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const ConsultarAtributosRepeticao = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqRetornoFormularios/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}/${idFormulario}/1/${idFormularioEdicao ? idSequenciaEdicao : '0'}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                if (((idFormulario == "0050") || (idFormulario == "0052")) && (idFormularioEdicao)) {
                    response.forEach(element => {
                        if ((element.inq_id_atributo == "0412") && (element.inq_valor_atributo != "")) {
                            let pesoMinimo = element.inq_peso_minimo * element.inq_densidade_cuba;
                            let pesoNominal = pesoMinimo + element.inq_gramas_adicionais_peso_nominal;
                            let pesoMaximo = pesoNominal + element.inq_gramas_adicionais_peso_maximo;

                            setMinimoBoticario(pesoMinimo);
                            setNominalBoticario(pesoNominal);
                            setMaximoBoticario(pesoMaximo);

                            setMinimoBoticarioML(pesoMinimo / element.inq_densidade_cuba);
                            setNominalBoticarioML(pesoNominal / element.inq_densidade_cuba);
                            setMaximoBoticarioML(pesoMaximo / element.inq_densidade_cuba);
                        }
                    });

                }

                const groupByBrand = groupBy('inq_agrupador');
                setAtributosRepeticao(groupByBrand(response));
                setShowLoaderAtributosRepeticao(false);

                if (((idFormulario == "0050") || (idFormulario == "0052")) && (idFormularioEdicao)) {
                    alimentarMediaPesagem();
                }

                if (document.getElementById("0020-0368")) {
                    document.getElementById("0020-0368").disabled = true;
                }

                if (idFormulario == "0022") {
                    if (document.getElementById("0022-0417")) {
                        document.getElementById("0022-0417").disabled = true;
                    }
                }

                if ((idFormulario == "0036") || (idFormulario == "0051") || (idFormulario == "0052")) {
                    if (document.getElementById(idFormulario + "-1095")) {
                        document.getElementById(idFormulario + "-1095").disabled = true;
                        document.getElementById(idFormulario + "-1096").disabled = true;
                        document.getElementById(idFormulario + "-1097").disabled = true;
                        document.getElementById(idFormulario + "-1098").disabled = true;
                        document.getElementById(idFormulario + "-1099").disabled = true;
                        document.getElementById(idFormulario + "-1100").disabled = true;
                        document.getElementById(idFormulario + "-1101").disabled = true;
                        document.getElementById(idFormulario + "-1102").disabled = true;
                        document.getElementById(idFormulario + "-1103").disabled = true;
                        document.getElementById(idFormulario + "-1104").disabled = true;
                    }
                }

                if (idFormulario == "0008" || idFormulario == "0027" || idFormulario == "0029" || idFormulario == "0030" || idFormulario == "0031" || idFormulario == "0044" ||idFormulario == "0061") {
                    alimentarNQA();
                }

                if ((idFormulario == "0009") || (idFormulario == "0024")) {
                    alimentarTorque();
                }

                ConsultarAtributosUnicos();
            })
            .catch((error) => {
                setShowLoaderAtributosRepeticao(false);
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const ConsultarUltimoLancamentoPesagem = () => {

        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqProducaoInformacoesPeriodicos/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}/${idFormulario}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setUltimoLancamentoPesagem(response);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const ConsultarProducao = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };

        fetch(`${bizConfig.rootApi}/InqProducao/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setProducao(response);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const ConsultarPesagem = () => {
        let requestInfo = {
            method: 'GET',
            headers: bizConfig.requestHeaders
        };
        fetch(`${bizConfig.rootApi}/InqProducaoPesagem/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}/${idFormulario}`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setPesagem(response);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const SetValorAtributoUnico = (id, valor, dados_auxiliares, peso_pallet, densidade_cuba) => {
        let aux_valoresAtributosUnicos = [...valoresAtributosUnicos];
        let atributo = aux_valoresAtributosUnicos.filter(element => `${element.idFormulario}-${element.idAtributo}` == id);
        if (atributo.length) {
            atributo[0].valor = valor;
            atributo[0].dados_auxiliares = dados_auxiliares;
            atributo[0].peso_pallet = peso_pallet;
            atributo[0].densidade_cuba = densidade_cuba;
            setValoresAtributosUnicos([...aux_valoresAtributosUnicos]);

            if ((peso_pallet) && (idFormulario == "0022")) {
                if (document.getElementById("0022-0417")) {
                    document.getElementById("0022-0417").value = peso_pallet;
                    SetValorAtributoUnico("0022-0417", peso_pallet);
                }
            }

            if (dados_auxiliares) {
                if (document.querySelector(`.custom-select-${id}`)) {
                    if (!document.querySelector(`.custom-select-${id}`).parentNode.parentNode.parentNode.querySelector(".dados_auxiliares")) {
                        let v_span = document.createElement("div");
                        v_span.setAttribute("class", "dados_auxiliares");
                        document.querySelector(`.custom-select-${id}`).parentNode.parentNode.parentNode.appendChild(v_span);
                    }

                    document.querySelector(`.custom-select-${id}`).parentNode.parentNode.parentNode.querySelector(".dados_auxiliares").innerHTML = dados_auxiliares;
                }
            }
            else {
                if (document.querySelector(`.custom-select-${id}`)) {
                    if (document.querySelector(`.custom-select-${id}`).parentNode.parentNode.parentNode.querySelector(".dados_auxiliares")) {
                        document.querySelector(`.custom-select-${id}`).parentNode.parentNode.parentNode.querySelector(".dados_auxiliares").innerHTML = "";
                    }
                }
            }

            if ((densidade_cuba) && ((idFormulario == "0050") || (idFormulario == "0052"))) {
                if (producao?.inq_peso_minimo_decimal) {
                    let pesoMinimo = producao?.inq_peso_minimo_decimal * densidade_cuba;
                    let pesoNominal = pesoMinimo + producao?.inq_gramas_adicionais_peso_nominal;
                    let pesoMaximo = pesoNominal + producao?.inq_gramas_adicionais_peso_maximo;

                    setMinimoBoticario(pesoMinimo);
                    setNominalBoticario(pesoNominal);
                    setMaximoBoticario(pesoMaximo);

                    setMinimoBoticarioML(pesoMinimo / densidade_cuba);
                    setNominalBoticarioML(pesoNominal / densidade_cuba);
                    setMaximoBoticarioML(pesoMaximo / densidade_cuba);
                }
                else {
                    setMinimoBoticario(0);
                    setNominalBoticario(0);
                    setMaximoBoticario(0);

                    setMinimoBoticarioML(0);
                    setNominalBoticarioML(0);
                    setMaximoBoticarioML(0);
                }
            }
        }
    }

    const SetValorAtributoRepeticao = (id, valor, dados_auxiliares, peso_pallet, densidade_cuba) => {
        let aux_valoresAtributosRepeticao = [...valoresAtributosRepeticao];
        let atributo = aux_valoresAtributosRepeticao.filter(element => `${element.idFormulario}-${element.idAtributo}` == id);
        if (atributo.length) {
            atributo[0].valor = valor;
            atributo[0].dados_auxiliares = dados_auxiliares;
            atributo[0].peso_pallet = peso_pallet;
            atributo[0].densidade_cuba = densidade_cuba;
            setValoresAtributosRepeticao([...aux_valoresAtributosRepeticao]);

            if ((peso_pallet) && (idFormulario == "0022")) {
                if (document.getElementById("0022-0417")) {
                    document.getElementById("0022-0417").value = peso_pallet;
                    SetValorAtributoRepeticao("0022-0417", peso_pallet);
                }
            }

            if (dados_auxiliares) {
                if (document.querySelector(`.custom-select-${id}`)) {
                    if (!document.querySelector(`.custom-select-${id}`).parentNode.parentNode.parentNode.querySelector(".dados_auxiliares")) {
                        let v_span = document.createElement("div");
                        v_span.setAttribute("class", "dados_auxiliares");
                        document.querySelector(`.custom-select-${id}`).parentNode.parentNode.parentNode.appendChild(v_span);
                    }

                    document.querySelector(`.custom-select-${id}`).parentNode.parentNode.parentNode.querySelector(".dados_auxiliares").innerHTML = dados_auxiliares;
                }
            }
            else {
                if (document.querySelector(`.custom-select-${id}`)) {
                    if (document.querySelector(`.custom-select-${id}`).parentNode.parentNode.parentNode.querySelector(".dados_auxiliares")) {
                        document.querySelector(`.custom-select-${id}`).parentNode.parentNode.parentNode.querySelector(".dados_auxiliares").innerHTML = "";
                    }
                }
            }

            if ((densidade_cuba) && ((idFormulario == "0050") || (idFormulario == "0052"))) {
                if (producao?.inq_peso_minimo_decimal) {
                    let pesoMinimo = producao?.inq_peso_minimo_decimal * densidade_cuba;
                    let pesoNominal = pesoMinimo + producao?.inq_gramas_adicionais_peso_nominal;
                    let pesoMaximo = pesoNominal + producao?.inq_gramas_adicionais_peso_maximo;

                    setMinimoBoticario(pesoMinimo);
                    setNominalBoticario(pesoNominal);
                    setMaximoBoticario(pesoMaximo);

                    setMinimoBoticarioML(pesoMinimo / densidade_cuba);
                    setNominalBoticarioML(pesoNominal / densidade_cuba);
                    setMaximoBoticarioML(pesoMaximo / densidade_cuba);
                }
                else {
                    setMinimoBoticario(0);
                    setNominalBoticario(0);
                    setMaximoBoticario(0);

                    setMinimoBoticarioML(0);
                    setNominalBoticarioML(0);
                    setMaximoBoticarioML(0);
                }
            }
        }
    }

    const retornarValorAtributoUnico = (idFormulario, idAtributo) => {
        let retorno = valoresAtributosUnicos.filter(element => element.idFormulario == idFormulario && element.idAtributo == idAtributo);
        return retorno.length ? retorno[0].valor : ''
    }

    const retornarValorAtributoRepeticao = (idFormulario, idAtributo) => {
        let retorno = valoresAtributosRepeticao.filter(element => element.idFormulario == idFormulario && element.idAtributo == idAtributo);
        return retorno.length ? retorno[0].valor : ''
    }

    const VerificarDependencias = () => {
        if (valoresAtributosUnicos.length) {
            valoresAtributosUnicos.map(element => {
                if (element.idAtributoDependencia != "") {
                    if (document.getElementById(`${element.idFormulario}-${element.idAtributo}`)) {
                        let estaDesabilitado = document.getElementById(`${element.idFormulario}-${element.idAtributo}`).hasAttribute("disabled");
                        let valorDepencia = retornarValorAtributoUnico(element.idFormulario, element.idAtributoDependencia);

                        if ((element.valorAtributoDependencia == "NAOVAZIO") && (valorDepencia != "")) {
                            valorDepencia = "NAOVAZIO";
                        }

                        if (valorDepencia == element.valorAtributoDependencia) {
                            if (estaDesabilitado) {
                                document.getElementById(`${element.idFormulario}-${element.idAtributo}`).removeAttribute('disabled');
                            }
                        }
                        else {
                            if (!estaDesabilitado) {
                                document.getElementById(`${element.idFormulario}-${element.idAtributo}`).setAttribute('disabled', true);
                            }
                        }
                    }
                }
            });
        }
    }
    const VerificarDependenciasRepeticao = () => {
        if (valoresAtributosRepeticao.length) {
            valoresAtributosRepeticao.map(element => {
                if (element.idAtributoDependencia != "" || element.valorAtributoDependencia != "") {
                    if (element.tipoAtributo == '12') {
                        let dependencias = element.valorAtributoDependencia.split(',')
                        let valorFinal = 0;
                        dependencias.map((idAtributo, index) => {
                            let valorAtual = toFloat(valoresAtributosRepeticao?.find(a => a.idAtributo == idAtributo)?.valor);
                            if (index == 0)
                                valorFinal = valorAtual;
                            else
                                valorFinal = valorFinal - valorAtual;
                        });
                        if (!isNaN(valorFinal) && valoresAtributosRepeticao.find(a => a.idAtributo == element.idAtributo).valor != toDecimal(valorFinal, 2)) {

                            SetValorAtributoRepeticao(`${element.idFormulario}-${element.idAtributo}`, toDecimal(valorFinal, 2));

                        }
                    }

                    if (element.idAtributoDependencia != "") {
                        if (document.getElementById(`${element.idFormulario}-${element.idAtributo}`)) {
                            let estaDesabilitado = document.getElementById(`${element.idFormulario}-${element.idAtributo}`).hasAttribute("disabled");
                            let valorDepencia = retornarValorAtributoRepeticao(element.idFormulario, element.idAtributoDependencia);

                            if (element.valorAtributoDependencia.indexOf(",") > -1) {
                                if ((valorDepencia) && (element.valorAtributoDependencia.indexOf(valorDepencia) > -1)) {
                                    if (estaDesabilitado) {
                                        document.getElementById(`${element.idFormulario}-${element.idAtributo}`).removeAttribute('disabled');
                                    }
                                }
                                else {
                                    if (!estaDesabilitado) {
                                        document.getElementById(`${element.idFormulario}-${element.idAtributo}`).setAttribute('disabled', true);
                                    }
                                }
                            }
                            else {
                                if ((element.valorAtributoDependencia == "NAOVAZIO") && (valorDepencia != "")) {
                                    valorDepencia = "NAOVAZIO";
                                }

                                if (valorDepencia == element.valorAtributoDependencia) {
                                    if (estaDesabilitado) {
                                        document.getElementById(`${element.idFormulario}-${element.idAtributo}`).removeAttribute('disabled');
                                    }
                                }
                                else {
                                    if (!estaDesabilitado) {
                                        document.getElementById(`${element.idFormulario}-${element.idAtributo}`).setAttribute('disabled', true);
                                    }
                                }
                            }
                        }
                    }
                }
            });
        }
    }

    const alimentarMediaPesagem = () => {
        let valores = [];
        if (valoresAtributosRepeticao.length) {
            valoresAtributosRepeticao.map(element => {
                if (element.tipoAtributo == "02" && element.valor != "")
                    valores.push(toFloat(element.valor));
            });
        } else {
            setMedia(0);
        }
        let aux_media = valores.reduce((total, valor) => total + valor / valores.length, 0);
        let variancia = valores.reduce((total, valor) => total + Math.pow(aux_media - valor, 2) / valores.length, 0);
        let aux_desvioPadrao = Math.sqrt(variancia);

        setMedia(aux_media);
        setDesvioPadrao(aux_desvioPadrao);
    }

    const alimentarEan = () => {
        if (valoresAtributosRepeticao.length) {
            valoresAtributosRepeticao.map(element => {
                if (element.idAtributo == "0302" && element.valor != "")
                    setEan(element.valor);
            });
        } else {
            setEan("");
        }
    }

    const alimentarDun = () => {
        if (valoresAtributosRepeticao.length) {
            valoresAtributosRepeticao.map(element => {
                if (element.idAtributo == "0308" && element.valor != "")
                    setDun(element.valor);
            });
        } else {
            setDun("");
        }
    }

    const alimentarQRCode = () => {
        if (valoresAtributosRepeticao.length) {
            valoresAtributosRepeticao.map(element => {
                if (element.idAtributo == "1503" && element.valor != "")
                    setQrCode(element.valor);
            });
        } else {
            setQrCode("");
        }
    }

    const alimentarResultadosTela = (elemento, valor) => {
        let inq_peso_embalagem = 0;
        let idElementoEmbVazia = "";

        if (elemento.idAtributo == "1095") {
            idElementoEmbVazia = "1214";
        }
        else if (elemento.idAtributo == "1096") {
            idElementoEmbVazia = "1215";
        }
        else if (elemento.idAtributo == "1097") {
            idElementoEmbVazia = "1216";
        }
        else if (elemento.idAtributo == "1098") {
            idElementoEmbVazia = "1217";
        }
        else if (elemento.idAtributo == "1099") {
            idElementoEmbVazia = "1218";
        }
        else if (elemento.idAtributo == "1100") {
            idElementoEmbVazia = "1219";
        }
        else if (elemento.idAtributo == "1101") {
            idElementoEmbVazia = "1220";
        }
        else if (elemento.idAtributo == "1102") {
            idElementoEmbVazia = "1221";
        }
        else if (elemento.idAtributo == "1103") {
            idElementoEmbVazia = "1222";
        }
        else if (elemento.idAtributo == "1104") {
            idElementoEmbVazia = "1223";
        }

        if (document.getElementById(elemento.idFormulario + "-" + idElementoEmbVazia).value) {
            inq_peso_embalagem = toFloat(toDecimal(document.getElementById(elemento.idFormulario + "-" + idElementoEmbVazia).value, 6));
        }

        if ((!valor) || (!inq_peso_embalagem)) {
            document.getElementById(elemento.idFormulario + "-" + elemento.idAtributo).value = "";
            elemento.valor = "";
        }
        else {
            valor = toFloat(valor);

            if (valor > inq_peso_embalagem) {
                valor = valor - inq_peso_embalagem;
                document.getElementById(elemento.idFormulario + "-" + elemento.idAtributo).value = toDecimal(valor, 6);
                elemento.valor = toDecimal(valor, 6);
            }
            else {
                document.getElementById(elemento.idFormulario + "-" + elemento.idAtributo).value = "0,000000";
                elemento.valor = "0,000000";
            }
        }
    }

    const alimentarNQA = () => {
        let leve = [
            "0008-0256", "0008-0638", "0008-0639", "0008-0640", "0008-0641", "0008-0642", "0008-0643", "0008-0644", "0008-0645", 
            "0027-1007", "0027-1019", "0027-1037", "0027-1040", "0027-1043", "0027-1046", "0027-1049", "0027-1052", "0027-1059", 
            "0029-0809", "0029-0810", "0029-0811", "0029-0812", "0029-0813", "0029-0814", "0029-0815", 
            "0030-0256", "0030-0465", "0030-0466", "0030-0467", "0030-0468", "0030-0469", "0030-0470", "0030-0471", "0030-0472", 
            "0031-0813", "0031-0814", "0031-0815", "0031-0986", "0031-0990", "0031-0994", "0031-0998", "0031-1001", "0031-1004", "0031-1007", "0031-1012", "0031-1016", "0031-1019", "0031-1025", "0031-1030", 
            "0044-1315", "0044-1318", "0044-1321", "0044-1324", "0044-1327", "0044-1330", "0044-1333", "0044-1336", "0044-1339", "0044-1342", 
            "0061-1572", "0061-1575", "0061-1578", "0061-1581", "0061-1584", "0061-1587", "0061-1590"];

        let naoLeve = [
            "0008-0258", "0008-0259", "0008-0260", "0008-0261", "0008-0262", "0008-0263", "0008-0264", "0008-0266", "0008-0267", "0008-0268", "0008-0269", 
            "0008-0270", "0008-0271", "0008-0272", "0008-0275", "0008-0276", "0008-0277", "0008-0279", "0008-0447", "0008-0473", "0008-0621", "0008-0636", 
            "0008-0647", "0008-0648", "0008-0649", "0008-0650", "0008-0651", "0008-0652", "0008-0791", "0008-0792", "0008-0795", "0008-0796", "0008-0797", 
            "0008-0798", "0008-0799", "0008-0800", "0008-0801", "0008-0802", "0008-0803", "0008-0804", "0008-0805", "0008-0806", "0008-0807", "0008-0808", 

            "0027-0258", "0027-0259", "0027-0260", "0027-0261", "0027-0263", "0027-0264", "0027-0266", "0027-0267", "0027-0268", "0027-0269", "0027-0270", 
            "0027-0271", "0027-0272", "0027-1006", "0027-1008", "0027-1018", "0027-1020", "0027-1021", "0027-1035", "0027-1036", "0027-1038", "0027-1039", 
            "0027-1041", "0027-1042", "0027-1044", "0027-1045", "0027-1047", "0027-1048", "0027-1050", "0027-1051", "0027-1053", "0027-1054", "0027-1055", 
            "0027-1056", "0027-1057", "0027-1058", "0027-1060", 

            "0029-0258", "0029-0259", "0029-0260", "0029-0261", "0029-0262", "0029-0263", "0029-0264", "0029-0266", "0029-0267", "0029-0268", "0029-0269", 
            "0029-0270", "0029-0271", "0029-0272", "0029-0275", "0029-0276", "0029-0447", "0029-0462", "0029-0463", "0029-0621", "0029-0818", "0029-0819", 
            "0029-0820", "0029-0822", "0029-0962", "0029-0963", "0029-0966", "0029-0967", "0029-0968", "0029-0969", "0029-0970", "0029-0971", "0029-0972", 
            "0029-0973", "0029-0974", "0029-0975", "0029-0976", "0029-0977", "0029-0980", "0029-0981", 

            "0030-0258", "0030-0259", "0030-0260", "0030-0261", "0030-0262", "0030-0263", "0030-0264", "0030-0266", "0030-0267", "0030-0268", "0030-0269", 
            "0030-0270", "0030-0271", "0030-0272", "0030-0275", "0030-0276", "0030-0279", "0030-0447", "0030-0462", "0030-0463", "0030-0473", "0030-0474", 
            "0030-0475", "0030-0476", "0030-0477", "0030-0478", "0030-0479", "0030-0480", "0030-0481", "0030-0618", "0030-0619", "0030-0621", "0030-0622", 
            "0030-0623", "0030-0624", "0030-0625", "0030-0626", "0030-0627", "0030-0628", "0030-0629", "0030-0630", "0030-0631", "0030-0632", "0030-0633", 
            "0030-0634", "0030-0635", "0030-0636", 

            "0031-0258", "0031-0259", "0031-0260", "0031-0261", "0031-0263", "0031-0264", "0031-0266", "0031-0267", "0031-0268", "0031-0269", "0031-0270", 
            "0031-0271", "0031-0272", "0031-0457", "0031-0477", "0031-0972", "0031-0973", "0031-0974", "0031-0975", "0031-0976", "0031-0977", "0031-0982", 
            "0031-0983", "0031-0984", "0031-0985", "0031-0987", "0031-0988", "0031-0989", "0031-0991", "0031-0992", "0031-0993", "0031-0995", "0031-0996", 
            "0031-0997", "0031-0999", "0031-1000", "0031-1002", "0031-1003", "0031-1005", "0031-1006", "0031-1008", "0031-1009", "0031-1010", "0031-1011", 
            "0031-1013", "0031-1014", "0031-1015", "0031-1017", "0031-1018", "0031-1020", "0031-1021", "0031-1022", "0031-1023", "0031-1024", "0031-1026", 
            "0031-1027", "0031-1028", "0031-1029", "0031-1031", "0031-1032", "0031-1033", "0031-1034", 

            "0044-0263", "0044-0264", "0044-0266", "0044-0267", "0044-0268", "0044-0269", "0044-0270", "0044-0271", "0044-0272", "0044-0457", "0044-1021", 
            "0044-1316", "0044-1317", "0044-1319", "0044-1320", "0044-1322", "0044-1323", "0044-1325", "0044-1326", "0044-1328", "0044-1329", "0044-1331", 
            "0044-1332", "0044-1334", "0044-1335", "0044-1337", "0044-1338", "0044-1340", "0044-1341", "0044-1343", "0044-1344", "0044-1345", "0044-1346", 
            "0044-1347", "0044-1348", "0044-1349", "0044-1350", "0044-1351", 

            "0061-0258", "0061-0259", "0061-0260", "0061-0261", "0061-0262", "0061-0263", "0061-0264", "0061-0266", "0061-0267", "0061-0268", "0061-0269", 
            "0061-0270", "0061-0271", "0061-0272", "0061-0447", "0061-1573", "0061-1574", "0061-1576", "0061-1577", "0061-1579", "0061-1580", "0061-1582", 
            "0061-1583", "0061-1585", "0061-1586", "0061-1588", "0061-1589", "0061-1591", "0061-1592", "0061-1593", "0061-1594"];

        leve.map(element => {
            if (document.getElementById(element)) {
                document.getElementById(element).classList.remove("pesoVermelho");

                if (document.getElementById(element).value > 2) {
                    document.getElementById(element).classList.add("pesoVermelho");
                }
            }
        });

        naoLeve.map(element => {
            if (document.getElementById(element)) {
                document.getElementById(element).classList.remove("pesoVermelho");

                if (document.getElementById(element).value > 0) {
                    document.getElementById(element).classList.add("pesoVermelho");
                }
            }
        });
    }

    const alimentarTorque = () => {
        let torque = [idFormulario + "-0285", idFormulario + "-0543", idFormulario + "-0544", idFormulario + "-0545", idFormulario + "-0546"];

        torque.map(element => {
            if (document.getElementById(element)) {
                document.getElementById(element).classList.remove("pesoVermelho");
                document.getElementById(element).classList.remove("pesoAmarelo");

                if (document.getElementById(element).value) {
                    if (producao) {
                        if (toFloat(document.getElementById(element).value) < producao.inq_torque_minimo) {
                            document.getElementById(element).classList.add("pesoVermelho");
                        }
                        else if (toFloat(document.getElementById(element).value) > producao.inq_torque_maximo) {
                            document.getElementById(element).classList.add("pesoAmarelo");
                        }
                    }
                }
            }
        });
    }

    const alimentarResultados = () => {
        if (valoresAtributosRepeticao.length) {
            valoresAtributosRepeticao.map(element => {
                if (element.idAtributo == "1075") {
                    valoresAtributosRepeticao.map(elementoFilho => {
                        if (elementoFilho.idAtributo == "1095") {
                            alimentarResultadosTela(elementoFilho, element.valor);
                        }
                    });
                }
                else if (element.idAtributo == "1076") {
                    valoresAtributosRepeticao.map(elementoFilho => {
                        if (elementoFilho.idAtributo == "1096") {
                            alimentarResultadosTela(elementoFilho, element.valor);
                        }
                    });
                }
                else if (element.idAtributo == "1077") {
                    valoresAtributosRepeticao.map(elementoFilho => {
                        if (elementoFilho.idAtributo == "1097") {
                            alimentarResultadosTela(elementoFilho, element.valor);
                        }
                    });
                }
                else if (element.idAtributo == "1078") {
                    valoresAtributosRepeticao.map(elementoFilho => {
                        if (elementoFilho.idAtributo == "1098") {
                            alimentarResultadosTela(elementoFilho, element.valor);
                        }
                    });
                }
                else if (element.idAtributo == "1079") {
                    valoresAtributosRepeticao.map(elementoFilho => {
                        if (elementoFilho.idAtributo == "1099") {
                            alimentarResultadosTela(elementoFilho, element.valor);
                        }
                    });
                }
                else if (element.idAtributo == "1080") {
                    valoresAtributosRepeticao.map(elementoFilho => {
                        if (elementoFilho.idAtributo == "1100") {
                            alimentarResultadosTela(elementoFilho, element.valor);
                        }
                    });
                }
                else if (element.idAtributo == "1081") {
                    valoresAtributosRepeticao.map(elementoFilho => {
                        if (elementoFilho.idAtributo == "1101") {
                            alimentarResultadosTela(elementoFilho, element.valor);
                        }
                    });
                }
                else if (element.idAtributo == "1082") {
                    valoresAtributosRepeticao.map(elementoFilho => {
                        if (elementoFilho.idAtributo == "1102") {
                            alimentarResultadosTela(elementoFilho, element.valor);
                        }
                    });
                }
                else if (element.idAtributo == "1083") {
                    valoresAtributosRepeticao.map(elementoFilho => {
                        if (elementoFilho.idAtributo == "1103") {
                            alimentarResultadosTela(elementoFilho, element.valor);
                        }
                    });
                }
                else if (element.idAtributo == "1084") {
                    valoresAtributosRepeticao.map(elementoFilho => {
                        if (elementoFilho.idAtributo == "1104") {
                            alimentarResultadosTela(elementoFilho, element.valor);
                        }
                    });
                }
            });
        }
    }

    const alimentarEficiencia = () => {
        if (valoresAtributosRepeticao.length) {
            valoresAtributosRepeticao.map(element => {
                if (element.idAtributo == "0366" && element.valor != "")
                    setProducaoPlanejada(element.valor);

                if (element.idAtributo == "0367" && element.valor != "")
                    setProducaoRealizada(element.valor);

            });
        } else {
            setProducaoRealizada("");
        }
    }

    const verificarPesagem = () => {

        if (valoresAtributosRepeticao.length) {
            valoresAtributosRepeticao.map(element => {
                if (element.tipoAtributo == "02" && element.valor != "") {
                    const valorCalc = toFloat(element.valor);

                    document.getElementById(`${element.idFormulario}-${element.idAtributo}`).classList.remove("pesoVermelho", "pesoAmarelo");

                    if ((element.idFormulario == "0050") || (element.idFormulario == "0052")) {
                        if (valorCalc < minimoBoticario)
                            document.getElementById(`${element.idFormulario}-${element.idAtributo}`).classList.add("pesoVermelho");

                        if (valorCalc > maximoBoticario)
                            document.getElementById(`${element.idFormulario}-${element.idAtributo}`).classList.add("pesoAmarelo");
                    }
                    else {
                        if (valorCalc < producao.inq_peso_minimo)
                            document.getElementById(`${element.idFormulario}-${element.idAtributo}`).classList.add("pesoVermelho");

                        if (element.idFormulario == "0049") {
                            if (valorCalc > producao.inq_peso_nominal)
                                document.getElementById(`${element.idFormulario}-${element.idAtributo}`).classList.add("pesoAmarelo");
                        }
                        else {
                            if (valorCalc > producao.inq_peso_maximo)
                                document.getElementById(`${element.idFormulario}-${element.idAtributo}`).classList.add("pesoAmarelo");
                        }
                    }
                }
            });
        }
    }

    const verificarPesagem0036 = () => {

        if (valoresAtributosRepeticao.length) {
            valoresAtributosRepeticao.map(element => {

                if ((element.idAtributo == "1095") ||
                    (element.idAtributo == "1096") ||
                    (element.idAtributo == "1097") ||
                    (element.idAtributo == "1098") ||
                    (element.idAtributo == "1099") ||
                    (element.idAtributo == "1100") ||
                    (element.idAtributo == "1101") ||
                    (element.idAtributo == "1102") ||
                    (element.idAtributo == "1103") ||
                    (element.idAtributo == "1104")) {
                    if (element.tipoAtributo == "02" && element.valor != "") {
                        const valorCalc = toFloat(element.valor);

                        document.getElementById(`${element.idFormulario}-${element.idAtributo}`).classList.remove("pesoVermelho", "pesoAmarelo");

                        if ((element.idFormulario == "0050") || (element.idFormulario == "0052")) {
                            if (valorCalc < minimoBoticario)
                                document.getElementById(`${element.idFormulario}-${element.idAtributo}`).classList.add("pesoVermelho");

                            if (valorCalc > maximoBoticario)
                                document.getElementById(`${element.idFormulario}-${element.idAtributo}`).classList.add("pesoAmarelo");
                        }
                        else {
                            if (valorCalc < producao.inq_peso_minimo)
                                document.getElementById(`${element.idFormulario}-${element.idAtributo}`).classList.add("pesoVermelho");

                            if ((element.idFormulario == "0049") || (element.idFormulario == "0051")) {
                                if (valorCalc > producao.inq_peso_nominal)
                                    document.getElementById(`${element.idFormulario}-${element.idAtributo}`).classList.add("pesoAmarelo");
                            }
                            else {
                                if (valorCalc > producao.inq_peso_maximo)
                                    document.getElementById(`${element.idFormulario}-${element.idAtributo}`).classList.add("pesoAmarelo");
                            }
                        }
                    }
                }
            });
        }
    }

    const formatarValorSubmit = (tipo, valor) => {
        switch (tipo) {
            case '02':
                return valor.replace(",", ".");
            case '05':
                return toDateSQL(valor);
        }
        return valor;
    }

    const formatarValorFormulario = (tipo, valor, idAtributo, periodicidade) => {
        switch (tipo) {
            case '02' || '12':
                return valor.replace(".", ",");
            case '05':
                return toDate8(valor);
            case '11':
                return idFormularioEdicao ? valor : somarMinutos(ultimoLancamentoPesagem?.find(a => a?.inq_id_atributo == idAtributo)?.inq_valor_atributo, periodicidade) || "";

        }

        return valor;
    }

    const onSubmit = (e) => {
        if (e) e.preventDefault();

        setLoadingSubmitAtributosUnicos(true);

        let dadosAtributos = "";
        document.body.querySelector("style:not([type])");

        valoresAtributosUnicos.map(element => {
            let valor = "";
            if (element.idAtributoDependencia) {
                if (document.getElementById(`${element.idFormulario}-${element.idAtributo}`)) {
                    let estaDesabilitado = document.getElementById(`${element.idFormulario}-${element.idAtributo}`).hasAttribute("disabled");

                    if (!estaDesabilitado) {
                        valor = element.valor;
                    }
                }
                else {
                    valor = element.valor;
                }
            }
            else {
                valor = element.valor;
            }

            dadosAtributos += `${element.idAtributo}|${formatarValorSubmit(element.tipoAtributo, valor)}|;`
        });

        let requestInfo = {
            method: 'POST',
            headers: bizConfig.requestHeaders,
            body: JSON.stringify(
                {
                    "operacao": operacao,
                    "cad_empresa": bizConfig.cad_empresa,
                    "cad_filial": bizConfig.cad_filial,
                    "inq_id_producao": idProducao,
                    "inq_id_formulario": idFormulario,
                    "inq_id_repeticao": '2',
                    "inq_sequencia": 0,
                    "inq_dados_atributos": dadosAtributos,
                    "inq_usuario": userData.usuario,
                    "inq_motivo_alteracao": operacao == 2 || idFormularioEdicao ? motivoAlteracao : ""
                }
            )
        };
        fetch(`${bizConfig.rootApi}/InqProducaoApontamentos`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response;
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                if (!idFormularioEdicao) {
                    setLoadingSubmitAtributosUnicos(false);
                    setSelectedMenu('');
                    setSelectedMenu('apontamentosFormulario');
                }
            })
            .catch((error) => {
                setLoadingSubmitAtributosUnicos(false);
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })


    }

    const onSubmitRepeticao = (e) => {
        if (e) e.preventDefault();
        setLoadingSubmitAtributosRepeticao(true);

        let dadosAtributos = "";
        document.body.querySelector("style:not([type])");

        valoresAtributosRepeticao.map(element => {
            let valor = "";
            if (element.idAtributoDependencia) {
                if (document.getElementById(`${element.idFormulario}-${element.idAtributo}`)) {
                    let estaDesabilitado = document.getElementById(`${element.idFormulario}-${element.idAtributo}`).hasAttribute("disabled");

                    if (!estaDesabilitado) {
                        valor = element.valor;
                    }
                }
                else {
                    valor = element.valor;
                }
            }
            else {
                valor = element.valor;
            }

            dadosAtributos += `${element.idAtributo}|${formatarValorSubmit(element.tipoAtributo, valor)}|;`
        });

        let requestInfo = {
            method: 'POST',
            headers: bizConfig.requestHeaders,
            body: JSON.stringify(
                {
                    "operacao": idFormularioEdicao ? 2 : 1,
                    "cad_empresa": bizConfig.cad_empresa,
                    "cad_filial": bizConfig.cad_filial,
                    "inq_id_producao": idProducao,
                    "inq_id_formulario": idFormulario,
                    "inq_id_repeticao": '1',
                    "inq_sequencia": idFormularioEdicao ? idSequenciaEdicao : 0,
                    "inq_dados_atributos": dadosAtributos,
                    "inq_usuario": userData.usuario,
                    "inq_motivo_alteracao": operacao == 2 || idFormularioEdicao ? motivoAlteracao : ""
                }
            )
        };
        fetch(`${bizConfig.rootApi}/InqProducaoApontamentos`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response;
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                if (idFormularioEdicao) {
                    window.close();
                }
                else {
                    setLoadingSubmitAtributosRepeticao(false);
                    setSelectedMenu('');
                    setSelectedMenu('apontamentosFormulario');
                }
            })
            .catch((error) => {
                setLoadingSubmitAtributosRepeticao(false);
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }
    return (
        <>
            <Loading show={showLoaderAtributosUnicos || showLoaderAtributosRepeticao} size="big" />
            <Section>
                <h3>
                    <Typography variant="h3">{nomeFormulario}</Typography>
                </h3>
                <div className='w-100 text-right' style={{ display: "flex-container", textAlign: "right" }}>
                    {(valoresAtributosRepeticao.filter(a => a.tipoAtributo == "04").length || valoresAtributosUnicos.filter(a => a.tipoAtributo == "04").length) > 0 && <a style={{ cursor: "pointer", marginRight: "20px" }} onClick={() => { setExibirObservacoes(!exibirObsarvacoes) }}>Observações</a>}
                    {producao && pesagem && !visualizarPesagem && <a style={{ "cursor": "pointer" }} onClick={() => { setVisualizarPesagem(true) }}>Parâmetros</a>}
                    {!idFormularioEdicao && valoresAtributosRepeticao?.length > 0 && !visualizarPesagem && <a style={{ marginLeft: "20px" }} href={`/#/lista-apontamentos/${idProducao}/${idFormulario}/${lancamentoPOSFinalizar == 1 ? "IN" : status}/${possuiPesagem}`} target="_blank" >Ver lançamentos</a>}
                    {!idFormularioEdicao && <a
                        style={{ marginLeft: "20px" }}
                        onClick={() => {
                            setSelectedMenu('apontamentos');
                        }}
                    >Voltar</a>}
                </div>
                {
                    !visualizarPesagem &&
                    producao &&
                    pesagem &&
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={"p-1"}>

                        <div>
                            <strong>Tara:</strong> {pesagem[0]?.inq_tara_1 > 0 ? toDecimal(pesagem[0]?.inq_tara_1, 2) : ''}<br />
                            <strong>Tara:</strong> {pesagem[0]?.inq_tara_2 > 0 ? toDecimal(pesagem[0]?.inq_tara_2, 2) : ''}<br />
                            <strong>Tara:</strong> {pesagem[0]?.inq_tara_3 > 0 ? toDecimal(pesagem[0]?.inq_tara_3, 2) : ''}<br />
                            <strong>Tara:</strong> {pesagem[0]?.inq_tara_4 > 0 ? toDecimal(pesagem[0]?.inq_tara_4, 2) : ''}<br />

                            <strong>Tara:</strong> {pesagem[0]?.inq_tara_5 > 0 ? toDecimal(pesagem[0]?.inq_tara_5, 2) : ''}<br />
                            <strong>Tara:</strong> {pesagem[0]?.inq_tara_6 > 0 ? toDecimal(pesagem[0]?.inq_tara_6, 2) : ''}<br />
                            <strong>Tara:</strong> {pesagem[0]?.inq_tara_7 > 0 ? toDecimal(pesagem[0]?.inq_tara_7, 2) : ''}<br />
                            <strong>Tara:</strong> {pesagem[0]?.inq_tara_8 > 0 ? toDecimal(pesagem[0]?.inq_tara_8, 2) : ''}<br />

                            <strong>Tara:</strong> {pesagem[0]?.inq_tara_9 > 0 ? toDecimal(pesagem[0]?.inq_tara_9, 2) : ''}<br />
                            <strong>Tara:</strong> {pesagem[0]?.inq_tara_10 > 0 ? toDecimal(pesagem[0]?.inq_tara_10, 2) : ''}<br />
                            <strong>Tara:</strong> {pesagem[0]?.inq_tara_11 > 0 ? toDecimal(pesagem[0]?.inq_tara_11, 2) : ''}<br />
                            <strong>Tara:</strong> {pesagem[0]?.inq_tara_12 > 0 ? toDecimal(pesagem[0]?.inq_tara_12, 2) : ''}<br />
                        </div>
                        <div>
                            <strong>Hora:</strong> {pesagem[0]?.inq_hora_1 ? pesagem[0]?.inq_hora_1 : ''}<br />
                            <strong>Hora:</strong> {pesagem[0]?.inq_hora_2 ? pesagem[0]?.inq_hora_2 : ''}<br />
                            <strong>Hora:</strong> {pesagem[0]?.inq_hora_3 ? pesagem[0]?.inq_hora_3 : ''}<br />
                            <strong>Hora:</strong> {pesagem[0]?.inq_hora_4 ? pesagem[0]?.inq_hora_4 : ''}<br />

                            <strong>Hora:</strong> {pesagem[0]?.inq_hora_5 ? pesagem[0]?.inq_hora_5 : ''}<br />
                            <strong>Hora:</strong> {pesagem[0]?.inq_hora_6 ? pesagem[0]?.inq_hora_6 : ''}<br />
                            <strong>Hora:</strong> {pesagem[0]?.inq_hora_7 ? pesagem[0]?.inq_hora_7 : ''}<br />
                            <strong>Hora:</strong> {pesagem[0]?.inq_hora_8 ? pesagem[0]?.inq_hora_8 : ''}<br />

                            <strong>Hora:</strong> {pesagem[0]?.inq_hora_9 ? pesagem[0]?.inq_hora_9 : ''}<br />
                            <strong>Hora:</strong> {pesagem[0]?.inq_hora_10 ? pesagem[0]?.inq_hora_10 : ''}<br />
                            <strong>Hora:</strong> {pesagem[0]?.inq_hora_11 ? pesagem[0]?.inq_hora_11 : ''}<br />
                            <strong>Hora:</strong> {pesagem[0]?.inq_hora_12 ? pesagem[0]?.inq_hora_12 : ''}<br />
                        </div>
                        {
                            (idFormulario == "0049" || idFormulario == "0050") &&
                            <div>
                                <strong>Média: </strong><span className={classeMedia}>{toDecimal(media, 2)}</span><br />
                            </div>
                        }
                        {
                            idFormulario != "0049" &&
                            idFormulario != "0050" &&
                            <div>
                                <strong>Densidade:</strong> {toDecimal(producao?.inq_densidade, 3)}<br />
                                <strong>Média: </strong><span className={classeMedia}>{toDecimal(media, 2)}</span><br />
                            </div>
                        }
                        {
                            idFormulario == "0049" &&
                            <div>
                                <strong>Mínimo:</strong> {toDecimal(producao?.inq_peso_minimo, 2)}<br />
                                <strong>Referência:</strong> {toDecimal(producao?.inq_peso_nominal, 2)}<br />
                            </div>
                        }
                        {
                            (idFormulario == "0050" || idFormulario == "0052") &&
                            <div>
                                <table className='tableNQA'>
                                    <tr>
                                        <th>
                                            Grama
                                        </th>
                                        <th>
                                            ML
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Mínimo:</strong> {toDecimal(minimoBoticario, 2)}
                                        </td>
                                        <td>
                                            <strong>Mínimo:</strong> {toDecimal(minimoBoticarioML, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Nominal:</strong> {toDecimal(nominalBoticario, 2)}
                                        </td>
                                        <td>
                                            <strong>Nominal:</strong> {toDecimal(nominalBoticarioML, 0)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Máximo:</strong> {toDecimal(maximoBoticario, 2)}
                                        </td>
                                        <td>
                                            <strong>Máximo:</strong> {toDecimal(maximoBoticarioML, 0)}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        }
                        {
                            idFormulario != "0049" &&
                            idFormulario != "0050" &&
                            idFormulario != "0052" &&
                            <div>
                                <strong>Mínimo:</strong> {toDecimal(producao?.inq_peso_minimo, 2)}<br />
                                <strong>Nominal:</strong> {toDecimal(producao?.inq_peso_nominal, 2)}<br />
                                <strong>Máximo:</strong> {toDecimal(producao?.inq_peso_maximo, 2)}<br />
                            </div>
                        }
                    </div>
                }
                {
                    !visualizarPesagem &&
                    producao &&
                    idFormulario == "0036" &&
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={"p-1"}>
                        <div>
                            <strong>Densidade:</strong> {toDecimal(producao?.inq_densidade, 3)}<br />
                            <strong>Mínimo:</strong> {toDecimal(producao?.inq_peso_minimo, 2)}<br />
                            <strong>Nominal:</strong> {toDecimal(producao?.inq_peso_nominal, 2)}<br />
                            <strong>Máximo:</strong> {toDecimal(producao?.inq_peso_maximo, 2)}<br />
                        </div>
                    </div>
                }
                {
                    !visualizarPesagem &&
                    producao &&
                    idFormulario == "0051" &&
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={"p-1"}>
                        <div>
                            <strong>Mínimo:</strong> {toDecimal(producao?.inq_peso_minimo, 2)}<br />
                            <strong>Referência:</strong> {toDecimal(producao?.inq_peso_nominal, 2)}<br />
                        </div>
                    </div>
                }
                {
                    !visualizarPesagem &&
                    producao &&
                    idFormulario == "0052" &&
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={"p-1"}>
                        <div>
                            <strong>Mínimo:</strong> {toDecimal(minimoBoticario, 2)}<br />
                            <strong>Nominal:</strong> {toDecimal(nominalBoticario, 2)}<br />
                            <strong>Máximo:</strong> {toDecimal(maximoBoticario, 2)}<br />
                        </div>
                    </div>
                }

                {
                    producao &&
                    (idFormulario == "0008" || idFormulario == "0027" || idFormulario == "0029" || idFormulario == "0030" || idFormulario == "0031" || idFormulario == "0044" ||idFormulario == "0061") &&
                    <div style={{ display: "flex", justifyContent: "right" }} className={"p-1"}>
                        <table className='tableNQA'>
                            <tr>
                                <th></th>
                                <th>Aceita</th>
                                <th>Rejeita</th>
                                <th>NQA</th>
                            </tr>
                            <tr>
                                <td>Demérito Leve</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4,00%</td>
                            </tr>
                            <tr>
                                <td>Demérito Moderado</td>
                                <td>0</td>
                                <td>1</td>
                                <td>1,00%</td>
                            </tr>
                            <tr>
                                <td>Demérito Grave</td>
                                <td>0</td>
                                <td>1</td>
                                <td>0,15%</td>
                            </tr>
                        </table>
                    </div>
                }

                {
                    producao &&
                    (producao?.cad_boticario == 1) &&
                    (idFormulario == "0048") &&
                    <div style={{ display: "flex", justifyContent: "right" }} className={"p-1"}>
                        <table className='tableNQA'>
                            <tr>
                                <th>Tamanho do lote (unidades produzidas)</th>
                                <th>Quantidade em unidades de amostra a ser retirada no lote</th>
                                <th>Amostragem enviada para boticário FQ, micro e retenção em unidades</th>
                                <th>Níveis gerais de inspeção</th>
                            </tr>
                            <tr>
                                <td>281 a 500</td>
                                <td>50</td>
                                <td rowSpan={2}>AROMATIZADORES 60 ML<br /><strong>5 UNIDADES</strong></td>
                                <td>H</td>
                            </tr>
                            <tr>
                                <td>501 a 1.200</td>
                                <td>80</td>
                                <td>J</td>
                            </tr>
                            <tr>
                                <td>1.201 a 3.200</td>
                                <td>125</td>
                                <td rowSpan={2}>PRODUTOS EM GERAL <strong><u>MAIS + DE 50 ML:</u><br />5 UNIDADES</strong></td>
                                <td>K</td>
                            </tr>
                            <tr>
                                <td>3.201 a 10.000</td>
                                <td>200</td>
                                <td>L</td>
                            </tr>
                            <tr>
                                <td>10.001 a 35.000</td>
                                <td>315</td>
                                <td rowSpan={2}>PRODUTOS EM GERAL <strong><u>MENOS - DE 50 ML:</u><br />8 UNIDADES</strong></td>
                                <td>M</td>
                            </tr>
                            <tr>
                                <td>35.001 a 150.000</td>
                                <td>500</td>
                                <td>N</td>
                            </tr>
                        </table>
                    </div>
                }
                {
                    producao && ((idFormulario == "0009") || (idFormulario == "0024")) &&
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={"p-1"}>
                        <div>
                            <strong>Torque mínimo:</strong> {producao ? toDecimal(producao.inq_torque_minimo, 2) : ""}<br />
                            <strong>Torque máximo:</strong> {producao ? toDecimal(producao.inq_torque_maximo, 2) : ""}<br />
                        </div>
                    </div>
                }
            </Section>
            {!visualizarPesagem &&
                atributosUnicos && valoresAtributosUnicos?.length > 0 &&
                <>
                    <form noValidate onSubmit={onSubmit} autoComplete="off">
                        {
                            Object.keys(atributosUnicos).map(grupo =>
                                <div key={`grupo-atributos-${grupo}`} style={{ marginTop: "15px", margin: "margin: 0 auto", gridGap: "1rem", display: "grid", gridTemplateColumns: atributosUnicos[grupo].map(element => element.inq_largura).join(" ") }}>
                                    {
                                        atributosUnicos[grupo].map((elemento, index) => {
                                            if (!exibirObsarvacoes && elemento.inq_id_tipo_atributo == "04") {
                                                return "";
                                            }
                                            else {
                                                return (<AtributoPage
                                                    key={`atr-${index}`}
                                                    id={`${elemento.inq_id_formulario}-${elemento.inq_id_atributo}`}
                                                    name={`${elemento.inq_id_formulario}-${elemento.inq_id_atributo}`}
                                                    label={elemento.inq_nome_atributo}
                                                    tipoAtributo={elemento.inq_id_tipo_atributo}
                                                    required={elemento.inq_obrigatorio}
                                                    value={valoresAtributosUnicos.filter(a => a.idFormulario == elemento.inq_id_formulario && a.idAtributo == elemento.inq_id_atributo)[0]?.valor}
                                                    setValorAtributo={SetValorAtributoUnico}
                                                    index={index}
                                                    maxLength={elemento.inq_tamanho}
                                                    listaSelecao={elemento.inq_objeto_dropdown}
                                                    idAtributoDependencia={elemento.inq_id_atributo_dependencia}
                                                    valorAtributoDependencia={elemento.inq_valor_atributo_dependencia}
                                                    idProducao={idProducao}
                                                    verificarDependencias={VerificarDependencias}
                                                    status={lancamentoPOSFinalizar == 1 ? "IN" : status}
                                                />)
                                            }
                                        }
                                        )
                                    }

                                </div>
                            )
                        }
                        {
                            operacao == 2
                            && !valoresAtributosRepeticao
                            && !valoresAtributosRepeticao.length
                            &&
                            <TextareaInput
                                id={'motivo'}
                                name={'motivo'}
                                label={'*Motivo'}
                                value={motivoAlteracao}
                                onChange={(e) => {
                                    setMotivoAlteracao(e.target.value);
                                }}
                                errorMessage={!motivoAlteracao ? "Campo obrigatório" : ""}
                                disabled={lancamentoPOSFinalizar == 1 ? false : (status == 'FI')}
                            />
                        }
                    </form>
                    {
                        !valoresAtributosRepeticao?.length > 0 &&
                        <div className='w-100 text-right'>
                            <Button
                                label="Cancelar"
                                theme="danger"
                                onClick={() => {
                                    setSelectedMenu('apontamentos');
                                }}
                            />
                            {
                                ((lancamentoPOSFinalizar == 1) || (status == 'IN')) &&
                                <Button
                                    label='Salvar'
                                    className={'ml-1'}
                                    disabled={!validFormAtributosUnicos || (operacao == 2 && !motivoAlteracao?.trim()?.length)}
                                    loading={loadingSubmitAtributosUnicos ? loadingSubmitAtributosUnicos : undefined}
                                    onClick={() => {
                                        onSubmit();
                                    }}
                                />}
                        </div>}
                </>
            }
            {
                !visualizarPesagem &&
                valoresAtributosRepeticao?.length > 0 &&
                <>
                    <form noValidate onSubmit={onSubmitRepeticao} autoComplete="off">
                        {
                            Object.keys(atributosRepeticao).map(grupo =>
                                <div key={`grupo-atributos-${grupo}`} style={{ marginTop: "15px", margin: "margin: 0 auto", gridGap: "1rem", display: "grid", gridTemplateColumns: atributosRepeticao[grupo].map(element => element.inq_largura).join(" ") }}>
                                    {

                                        atributosRepeticao[grupo].map((elemento, index) => {
                                            if (!exibirObsarvacoes && elemento.inq_id_tipo_atributo == "04") {
                                                return "";
                                            }
                                            else {
                                                return (
                                                    <AtributoPage
                                                        key={`atr-${index}`}
                                                        id={`${elemento.inq_id_formulario}-${elemento.inq_id_atributo}`}
                                                        name={`${elemento.inq_id_formulario}-${elemento.inq_id_atributo}`}
                                                        label={elemento.inq_nome_atributo}
                                                        tipoAtributo={elemento.inq_id_tipo_atributo}
                                                        required={elemento.inq_obrigatorio}
                                                        value={valoresAtributosRepeticao.filter(a => a.idFormulario == elemento.inq_id_formulario && a.idAtributo == elemento.inq_id_atributo)[0]?.valor}
                                                        setValorAtributo={SetValorAtributoRepeticao}
                                                        index={index}
                                                        maxLength={elemento.inq_tamanho}
                                                        listaSelecao={elemento.inq_objeto_dropdown}
                                                        idAtributoDependencia={elemento.inq_id_atributo_dependencia}
                                                        valorAtributoDependencia={elemento.inq_valor_atributo_dependencia}
                                                        verificarDependencias={VerificarDependenciasRepeticao}
                                                        idProducao={idProducao}
                                                        status={lancamentoPOSFinalizar == 1 ? "IN" : status}
                                                    />
                                                )
                                            }
                                        })
                                    }

                                </div>
                            )
                        }
                        {
                            operacao == 2
                            && valoresAtributosRepeticao
                            && valoresAtributosRepeticao.length > 0
                            &&
                            <TextareaInput
                                id={'motivo'}
                                name={'motivo'}
                                label={'*Motivo'}
                                value={motivoAlteracao}
                                onChange={(e) => {
                                    setMotivoAlteracao(e.target.value);
                                }}
                                errorMessage={!motivoAlteracao ? "Campo obrigatório" : ""}
                                disabled={lancamentoPOSFinalizar == 1 ? false : (status == 'FI')}
                            />
                        }
                    </form>
                    <div className='w-100 text-right'>
                        <Button
                            label="Cancelar"
                            theme="danger"
                            onClick={() => {
                                if (idFormularioEdicao) {
                                    window.close();
                                }
                                else {
                                    setSelectedMenu('apontamentos');
                                }
                            }}
                        />
                        {((lancamentoPOSFinalizar == 1) || (status == 'IN')) &&
                            <Button
                                id='salvarRepeticao'
                                label='Salvar'
                                className={'ml-1'}
                                disabled={!validFormAtributosRepeticao || !validFormAtributosUnicos}
                                loading={loadingSubmitAtributosRepeticao ? loadingSubmitAtributosRepeticao : undefined}
                                onClick={() => {
                                    onSubmitRepeticao();
                                    if (atributosUnicos && valoresAtributosUnicos?.length) onSubmit();
                                }}
                            />}
                    </div>
                </>
            }
            {visualizarPesagem && <Pesagem idProducao={idProducao} setVisualizarPesagem={setVisualizarPesagem} AtualizarPesagem={ConsultarPesagem} />}
        </>
    );
}

export default LancamentosFormulariosPage;