import React from 'react';
import styled from 'styled-components';
import { body4, body5 } from 'components/Type';

const TableContainer = styled.div`
  display: block;
  position: relative;
`;

const Table = styled.table`
  border: 0;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: max-content;
  min-width:100%;
  table-layout: fixed;

  tbody tr {
    background-color: #fff;
    border-bottom: 1px solid rgb(26, 26, 26, 0.15);
    padding: 16px 8px;
    color: #4E4D4C;

    &.isInactive {
      background-color: #eee;
      opacity: .7;
      cursor: not-allowed;
    }

    &:hover {
      background-color: #F6F6F4;
    }
  }

  thead tr {
    background-color: #F6F6F4;
    border-bottom: 0 !important;
    padding: 8px;
    color: #4E4D4C;
  }

  th,
  td {
    padding: 16px 8px;
    text-align: left;

    div {
      white-space: nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
      max-width: max-content;
      box-sizing: border-box;
    }
  }

  th {
    ${body5}
    color: #4E4D4C;
  }

  td, td > div {
    ${body4}
  }

  .desktop {
    opacity: 0;
    height: 0;
    display: none;
  }

  @media only screen and (min-width: 1025px) {
    .desktop {
        opacity: 1;
        height: auto;
        display: revert;
      }
  }

  .td2Colunas {
    float: left;
    width: 50%;
  }

  .mobile {
    opacity: 0;
    height: 0;
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    .mobile {
        opacity: 1;
        height: auto;
        display: revert;
    }
  }  

  @media screen and (max-width: 1024px) {
    border: 0;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      border-bottom: 3px solid rgb(26, 26, 26, 0.15);
      display: block;
      margin-bottom: 0px;
    }

    td {
      border-bottom: 1px solid rgb(26, 26, 26, 0.15);
      display: block;
    }

    td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      color: #4E4D4C;
      ${body5}
    }

    td:last-child {
      border-bottom: 3px solid rgb(26, 26, 26, 0.15);
      display: block;
    }
  }
`;

function Table2Input({ children }) {
  return (
    <TableContainer>
      <Table>{children}</Table>
    </TableContainer>
  )
};

Table2Input.defaultProps = {

};

export default Table2Input;
