import React from 'react';
import styled from 'styled-components';

const Styled = {
  h1: styled.h1`
    font-family: "Exo 2", sans-serif;
    font-size: 48px;
    text-align: center;
    margin: 0;
  `,
  h2: styled.h2`
    font-family: "Exo 2", sans-serif;
    font-size: 48px;
    text-align: center;
    font-weight: 500;
    margin: 0;

    @media(max-width: 768px) {
      font-size: 32px;
    }
  `,
  h3: styled.h3`
    font-size: 20px;
    text-align: center;
    margin: 0;
  `,
  h4: styled.h4`
    font-size: 16px;
    text-align: center;
    margin: 0;
  `,
  h5: styled.h5`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0;
  `,
  p: styled.p`
    font-size: 16px;
    line-height: 28px;
    margin: 0;
  `,
};

const Typography = ({ variant, children, ...props }) => {
  const Component = Styled[variant];

  return (
    <Component
      {...props}
    >
      { children }
    </Component>
  );
};

export default Typography;
