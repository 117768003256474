import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import InternalLayout from '../../../components/InternalLayout';
import Divider from '../../../components/Divider';
import Loading from '../../../components/Loading';
import Typography from '../../../components/Typography';
import { bizConfig } from 'utils/bizConfig';
import { toDate } from '../../../utils/utils';
import LancamentoProducao from '../LancamentosProducao'
const Section = styled.section`
  hr {
    margin: 0;
  }
`;

const Header = styled.header`
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-weight: 400;
    width: 100%;
    text-align: left;
  }
`;

function ProducaoPage(props) {
  const { history, match } = props;
  const [showLoader, setShowLoader] = useState(true);
  const [producao, setProducao] = useState(null);
  const [arquivo, setArquivo] = useState(null);
  const idProducao = match?.params?.idProducao;
  const possuiPesagemProducao = match?.params?.possuiPesagemProducao;
  const status = match?.params?.status;
  if (!match.params.idProducao || !match.params.possuiPesagemProducao) history.push({ pathname: '/lista-producao' });

  let divTopo = document.getElementsByClassName("sc-gGCDDS")[0];
  if (divTopo) {
    divTopo.scrollTop = 0;
  }

  const idFormularioEdicao = match?.params?.idFormularioEdicao;
  const idSequenciaEdicao = match?.params?.idSequenciaEdicao;

  useEffect(() => {
    ConsultarProducao();
  }, [])

  const ConsultarProducao = () => {
    let requestInfo = {
      method: 'GET',
      headers: bizConfig.requestHeaders
    };
    fetch(`${bizConfig.rootApi}/InqProducao/${bizConfig.cad_empresa}/${bizConfig.cad_filial}/${idProducao}/${status}`, requestInfo)
      .then(response => {
        if (response.status === 200 || response.status === 400) {
          return response.json();
        } else {
          throw new Error(response.text());
        }
      })
      .then(response => {
        setProducao(response);
        setShowLoader(false);
        ConsultarArquivosProduto(response.prd_referencia);
      })
      .catch((error) => {
        history.push({ pathname: '/lista-producao' });
        console.log('Ocorreu um problema na execução da fetch: ' + error.message);
        setShowLoader(false);
      })
  }

  const ConsultarArquivosProduto = (prd_referencia) => {
    let requestInfo = {
      method: 'GET',
      headers: bizConfig.requestHeaders
    };
    fetch(`${bizConfig.rootApi}/ArquivoProduto/${bizConfig.cad_empresa}/${prd_referencia}`, requestInfo)
      .then(response => {
        if (response.status === 200 || response.status === 400) {
          return response.json();
        } else {
          throw new Error(response.text());
        }
      })
      .then(response => {
        setArquivo(linksArquivos(response));
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const linksArquivos = (arquivos) => {
    let links = "";

    arquivos.map(element => {
      if (element.cad_arquivo) {
        if (!links) {
          links = 'Baixar: ';
        }

        links += '<a href="https://universalchemical.biz/Arquivos/ArquivosProdutos/Original/' + element.cad_arquivo + '" target="_blank" style={{ fontSize: "13px", fontWeight: "initial" }} >' + element.cad_nome_arquivo + '</a>, ';
      }
    });

    if (links) {
      links = links.substring(0, links.lastIndexOf(","));
    }

    return <div className='baixarLinks' dangerouslySetInnerHTML={{ __html: links }}></div>;
  }

  return (
    <InternalLayout>
      <Loading show={showLoader} size="big" />
      <Section>
        <Header>
          <Typography variant="h1">Inspeção de qualidade</Typography>
        </Header>
        <Divider variant="dark" />
      </Section>
      {producao && <Section>
        <>
          <div className={'flex-container'}>
            <div className={'flex-item-grow-2'}>
              <h2>{producao.prd_codigo} - {producao.prd_descricao}</h2>{arquivo}
            </div>
            <div className={'flex-item-grow-1'} style={{ textAlign: 'right' }}>
              <h2>Data: {toDate(producao.inq_data_acondicionamento)}</h2>
            </div>
          </div>
          <div className={'flex-container'}>
            <p className={'flex-item-grow-2 mt-0'}>
              <strong>Linha: </strong>{producao.inq_nome_linha}
            </p>
            <p className={'flex-item-grow-2 mt-0'}>
              <strong>EAN: </strong>{producao.ean}
            </p>
            <p className={'flex-item-grow-2 mt-0'}>
              <strong>DUN: </strong>{producao.dun + (producao.dun && (producao.inq_dun_1 || producao.inq_dun_2 || producao.inq_dun_3) ? " - " : "") +
                producao.inq_dun_1 + (producao.inq_dun_1 && producao.inq_dun_2 ? " / " : "") + producao.inq_dun_2 +
                (producao.inq_dun_1 || producao.inq_dun_2 ? " / " : "") + producao.inq_dun_3}
            </p>
            <p className={'flex-item-grow-2 mt-0'} style={{ textAlign: 'right' }}>
              <strong>Lote: </strong> {producao.inq_lote} - {producao.inq_data_validade_lote}
            </p>
          </div>
          {(producao.inq_qrcode_1 || producao.inq_qrcode_2 || producao.inq_qrcode_3) &&
            <div className={'flex-container'}>
              <p className={'flex-item-grow-2 mt-0'}>
                <strong>QR Code: </strong>{producao.inq_qrcode_1 + (producao.inq_qrcode_1 && producao.inq_qrcode_2 ? " / " : "") + producao.inq_qrcode_2 +
                  (producao.inq_qrcode_1 || producao.inq_qrcode_2 ? " / " : "") + producao.inq_qrcode_3}
              </p>
            </div>}
          <Divider variant="dark" />
        </>
      </Section>
      }
      <Section>
        <LancamentoProducao idProducao={idProducao} possuiPesagemProducao={possuiPesagemProducao} status={status} idFormularioEdicao={idFormularioEdicao} idSequenciaEdicao={idSequenciaEdicao} />
      </Section>
    </InternalLayout >
  );
}

export default ProducaoPage;