import React, { useState, useEffect } from 'react';
import InputWrapper from '../../../components/Input/Default';
import SimpleSelectInput from '../../../components/Input/SimpleSelect';
import TextareaInput from '../../../components/Input/Textarea';
import RadioInput from '../../../components/Input/Radio';
import MaskedInputWrapper from '../../../components/Input/Masked';
import SwitchInput from '../../../components/Input/Switch';
import { bizConfig } from '../../../utils/bizConfig';
import { dataValida, toDecimal } from '../../../utils/utils';
import UploadInput from '../../../components/Input/Upload';
import Barcode from '../../../components/Input/Barcode';
import { getData } from 'utils/token';
import AWS from 'aws-sdk';

// aws info
var bucketName = "biz-universal-chemical-imagens";
var bucketRegion = "us-west-2";
var IdentityPoolId = "us-west-2:e4e4cfe9-180c-4ff6-b66d-dccecf9560fc";

AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
    })
});

var s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: bucketName }
});

function AtributoPage(props) {
    const { index, id, name, label, tipoAtributo, required, maxLength, value, setValorAtributo, listaSelecao, verificarDependencias, getUltimoLancamento, idProducao, status } = props;
    const [alteracaoComponente, setAlteracaoComponente] = useState(false);
    const [erroComponente, setErroComponente] = useState("");
    const [loaderProfile, setLoaderProfile] = useState();
    const [coverImg, uploadCoverImg] = useState('');
    const [loaderCover, setLoaderCover] = useState(false);
    const [options, setOptions] = useState(null);
    const [optionDefault, setOptionDefault] = useState(null);
    const userData = getData();

    useEffect(() => {
        if (tipoAtributo == '09' || tipoAtributo == '13' || tipoAtributo == '14')
            ConsultarOptions(tipoAtributo);
    }, [])


    useEffect(() => {
        if (!value && options && options.length) {
            setValorAtributo(id, options[0].codigo);
        }
        verificarDependencias();
    }, [options])


    useEffect(() => {

        if (alteracaoComponente && required == 1 && (!value || value.toString()?.trim().length < 1)) {
            setErroComponente("Campo obrigatório");
            return;
        }

        if (tipoAtributo == '05' && (value || value?.trim().length > 0) && !dataValida(value)) {
            setErroComponente("Data inválida");
            return;
        }

        setErroComponente("");
    }, [value]);

    const create_UUID = () => {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    const ConsultarOptions = (idTipoAtributo) => {
        let requestInfo = {
            method: 'POST',
            headers: bizConfig.requestHeaders,
            body: JSON.stringify([
                {
                    "nome": "cad_empresa",
                    "valor": bizConfig.cad_empresa
                },
                {
                    "nome": "cad_filial",
                    "valor": bizConfig.cad_filial
                },
                {
                    "nome": "tipo",
                    "valor": listaSelecao
                },
                {
                    "nome": "tipoAtributo",
                    "valor": idTipoAtributo
                },
                {
                    "nome": "idProducao",
                    "valor": idProducao
                },
                {
                    "nome": "userMail",
                    "valor": userData.email
                },
                {
                    "nome": "user",
                    "valor": userData.usuario
                }
            ])
        };
        fetch(`${bizConfig.rootApi}/DropDown`, requestInfo)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json();
                } else {
                    throw new Error(response.text());
                }
            })
            .then(response => {
                setOptions(response);
            })
            .catch((error) => {
                console.log('Ocorreu um problema na execução da fetch: ' + error.message);
            })
    }

    const putImage = async (data) => {
        return new Promise((resolve, reject) => {
            if (data) {
                var file = data;
                var fileName = file.name || ".jpg";
                var filePath = create_UUID() + "-" + fileName;
                s3.upload({
                    Key: filePath,
                    Body: file,
                    ACL: 'private'
                },
                    function (err, data) {
                        if (err) {
                            console.log(err)
                        }
                        resolve(data.Location)
                    })
            } else {
                reject("necessário um arquivo");
            }
        });
    };

    const handleChangeHero = async (event) => {
        const [file] = event.target.files;

        if (!file) {
            return;
        }

        setLoaderCover(true);

        uploadCoverImg(URL.createObjectURL(file));

        let responseAsset = await putImage(file);
        setValorAtributo(id, responseAsset);

        setLoaderCover(false);
    }

    return (
        <div style={{ pointerEvents: `${status == 'FI' ? 'none' : 'inherit'}` }}>
            {(tipoAtributo == '03' || tipoAtributo == '01' || tipoAtributo == '02' || tipoAtributo == '12') &&
                <InputWrapper
                    id={id}
                    name={name}
                    label={required == 1 ? '*' + label : label}
                    value={value}
                    disabled={status == 'FI' || (tipoAtributo == '12' ? true : undefined)}
                    onChange={(e) => {
                        let valor = e.target.value;

                        if (tipoAtributo == '01')
                            valor = valor.replace(/[^0-9]/g, '');


                        if (tipoAtributo == '02') {
                            let virgulas = valor.match(/,/g);
                            if (virgulas) {
                                for (let i = 0; i < virgulas.length - 1; i++) {
                                    valor = valor.replace(",", "")
                                }
                            }
                            valor = valor.replace(/[^\d\,]/g, "");
                        }

                        if (tipoAtributo != '12') {
                            setAlteracaoComponente(true);
                            setValorAtributo(id, valor);
                        }

                    }}
                    errorMessage={erroComponente}
                    maxLength={tipoAtributo != '01' && tipoAtributo != '02' ? maxLength : undefined}
                />
            }
            {(tipoAtributo == '05') &&
                <MaskedInputWrapper
                    id={id}
                    name={name}
                    label={required == 1 ? '*' + label : label}
                    value={value}
                    disabled={status == 'FI'}
                    onChange={(e) => {
                        setAlteracaoComponente(true);
                        setValorAtributo(id, e.target.value);
                    }}
                    mask={"99/99/99"}
                    errorMessage={erroComponente}
                />
            }
            {(tipoAtributo == '06' || tipoAtributo == '11') &&
                <MaskedInputWrapper
                    id={id}
                    name={name}
                    label={required == 1 ? '*' + label : label}
                    value={value}
                    onChange={(e) => {
                        setAlteracaoComponente(true);
                        setValorAtributo(id, e.target.value);
                    }}
                    mask={"99:99"}
                    disabled={status == 'FI'}
                    errorMessage={erroComponente}
                />
            }
            {(tipoAtributo == '16') &&
                <MaskedInputWrapper
                    id={id}
                    name={name}
                    label={required == 1 ? '*' + label : label}
                    value={value}
                    onChange={(e) => {
                        setAlteracaoComponente(true);
                        setValorAtributo(id, e.target.value);
                    }}
                    mask={"99/99"}
                    disabled={status == 'FI'}
                    errorMessage={erroComponente}
                />
            }
            {tipoAtributo == '04' &&
                <TextareaInput
                    id={id}
                    name={name}
                    label={required == 1 ? '*' + label : label}
                    value={value}
                    onChange={(e) => {
                        setAlteracaoComponente(true);
                        setValorAtributo(id, e.target.value);
                    }}
                    errorMessage={erroComponente}
                    maxLength={maxLength}
                    disabled={status == 'FI'}
                />
            }
            {
                tipoAtributo == '08' &&

                <UploadInput
                    id={id}
                    name={name}
                    label={required == 1 ? '*' + label : label}
                    type="cover"
                    image={value}
                    loading={loaderCover}
                    onChange={handleChangeHero}
                />
            }
            {
                tipoAtributo == '17' &&

                <UploadInput
                    id={id}
                    name={name}
                    label={required == 1 ? '*' + label : label}
                    type="file"
                    image={value}
                    loading={loaderCover}
                    onChange={handleChangeHero}
                />
            }
            {(tipoAtributo == '09' || tipoAtributo == '14' ) && options &&
                <SimpleSelectInput
                    id={id}
                    name={name}
                    label={required == 1 ? '*' + label : label}
                    options={options.map((item, index) => (<option key={`option-${id}-${index}`} densidade_cuba={item.densidade_Cuba}  dados_auxiliares={item.dados_Auxiliares} peso_pallet={item.peso_Pallet} value={item.codigo}>{item.display}</option>))}
                    onChange={(e) => {
                        setAlteracaoComponente(true);
                        setValorAtributo(id, e.props.value, e.props.dados_auxiliares, e.props.peso_pallet, e.props.densidade_cuba);
                    }}
                    defaultSelected={(<option value={!value ? options[0]?.codigo : value}></option>)}
                    disabled={status == 'FI'}
                />
            }
            {tipoAtributo == '10' &&
                <SwitchInput
                    id={id}
                    name={name}
                    label={required == 1 ? '*' + label : label}
                    disabled={status == 'FI' || (id == '0013-0303' || id == '0014-0309' ? true : false)}
                    onChange={(e) => {
                        setAlteracaoComponente(true);
                        setValorAtributo(id, e.target.checked);
                    }}
                />
            }
            {tipoAtributo == '13' && options &&
                <RadioInput
                    id={id}
                    name={name}
                    label={required == 1 ? '*' + label : label}
                    itens={options}
                    value={value || options[0].codigo}
                    disabled={status == 'FI'}
                    onChange={(e) => {
                        setAlteracaoComponente(true);
                        setValorAtributo(id, document.querySelector(`input[name="${name}"]:checked`).value);
                    }}
                />
            }
			{(tipoAtributo == '15') &&
                <Barcode
                    id={id}
                    name={name}
                    label={required == 1 ? '*' + label : label}
                    value={value}
                    errorMessage={erroComponente}
                    onChange={(e) => {
                        setAlteracaoComponente(true);
                        setValorAtributo(id, e.target.value);
                    }}
                />
            }
        </div>
    )
}

export default AtributoPage;
