import React  from 'react';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Divider from 'components/Divider';
import Loading from 'components/Loading';
import Typography from 'components/Typography';

const Section = styled.section`
  hr {
    margin: 0;
  }
`;

const Header = styled.header`
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-weight: 400;
    width: 100%;
    text-align: left;
  }
`;

function DashboardPage() {
  return (
    <InternalLayout>
      <Loading show={false} size="big" />
      <Section>
        <Header>
          <Typography variant="h1">Dashboard</Typography>
        </Header>
        <Divider variant="dark" />
      </Section>
    </InternalLayout>
  );
}

export default DashboardPage;
