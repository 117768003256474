import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { body4 } from 'components/Type';

const RadioInputStyled = styled.div`

label {
  display: block;
  color: #7d7d7d;
}

.floatBlock {
  margin: 0 1.81em 0 0;
}

.labelish {
	color:#7d7d7d;
	margin: 0;
}

.flexOptions {
	border: none;
	display: flex;
	flex-direction: row;
	justify-content: start;
  padding-top:10px;
}

#purchaseOrder {
	margin: 0 0 2em 0;
}

  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100px;

  .switch__container {
    display: block;
    position: relative;
    width: 40px;
    box-sizing: border-box;
    margin-right: 14px;
  }

  .switch__checkbox {
    position: absolute;
    z-index: 0;
    height: 1px;
    width: 1px;
    left: 10px;
    top: 5px;
    background-color: transparent;
    border: transparent;
    appearance: none;

    &:focus + .switch {
      &:before {
        border: 2px solid #999;
      }
    }

    &:checked + .switch {
      background: #009051;

      &:before {
        left: 50px;
      }
    }

    &:disabled + .switch {

    }
  }

  .switch {
    z-index: 1;
    display: block;
    font-size: 30px;
    width: 100px;
    height: 60px;
    background: #807E7D;
    border-radius: 18px;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;

    &:before {
      content: "";
      border: 1px solid transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: absolute;
      content: "";
      width: 50px;
      height: 57px;
      border-radius: 40%;
      background: #fff;
      -webkit-box-shadow: 0 0 0.25em rgb(0 0 0 / 30%);
      box-shadow: 0 0 0.25em rgb(0 0 0 / 30%);
      -webkit-transform: scale(.7);
      transform: scale(.7);
      top: 2px;
      left: 2px;
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
      box-sizing: border-box;
    }
  }
`
const Label = styled.label`
  ${body4}
  color: #807E7D;
`

function RadioInput({
  id, name, label, className, disabled,
  onChange, checked, itens, value, ...props
}) {
  return (
    <RadioInputStyled className={className} style={{ display: "block" }}>
      {label && (<Label for={id}>{label}</Label>)}
      {/* <div className="switch__container">
        <input className="switch__checkbox" type="checkbox" id={id} name={name} disabled={disabled} checked={checked} onChange={onChange} />
        <label className="switch" for={id}>
        </label>
      </div> */}
      <div id="paymentContainer" name="paymentContainer" class="flexOptions">
        {
          itens &&
          itens.map((item, key) =>
            <div className={'ml-1 mr-1'}>
              <label>
                <input name={name} key={`${id}_${key}`} type="radio" value={item.codigo} onChange={onChange} checked={item.codigo == value}  disabled={disabled} />
                <br />
                {item.display}
              </label>
            </div>
          )
        }
      </div>
    </RadioInputStyled>
  )
};

RadioInput.defaultProps = {
  type: 'text'
}

export default RadioInput;
