import React from 'react';
import { Redirect } from 'react-router-dom';

import { deleteData } from 'utils/token';

function LogoutPage() {
  deleteData();

  return (
    <>
      <Redirect to="/" />
    </>
  );
}

export default LogoutPage;
