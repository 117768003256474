import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { body3, body4 } from 'components/Type';

const InputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;

  .prefix {
    position: absolute;
    left: 20px;
    color: #B2B1B0;
    height: 56px;
    top: ${props => props.error ? 'calc(50% + 0px)' : 'calc(50% + 13px)'};
    transform: translateY(-50%);
    line-height: 56px;
  }

  .field-error {
    ${body4}
    white-space: normal;
    word-wrap: break-word;
    width: 100%;
    max-width: 635px;
    position: relative;
    color: #CE2C30;
    margin-top: 2px;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    display: block;
    overflow: hidden;
    opacity: ${props => props.error ? '1' : '0'};
    max-height: ${props => props.error ? '200px' : '0'};
  }
`

const DefaultInputStyled = styled.input`
  ${body3}
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  padding: 10px 20px;
  border: 1px solid #D0D0D0;
  border-color: ${props => props.error ? '#CE2C30' : '#D0D0D0'};
  border-radius: 5px;
  transition: border-color .3s ease-in-out, color .3s ease-in-out;
  outline: none;
  color: #4E4D4C;
  -moz-appearance:textfield;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  &:focus {
    outline: none;
    border-color: ${props => props.error ? '#CE2C30' : '#1A1A1A'};
    box-shadow: 0px 0px 1px #999;
    color: #1A1A1A;
  }

  &:disabled {
    color: #B2B1B0
  }

  ::placeholder {
    color: #B3B1B0;
  }
`
const Label = styled.label`
  ${body4}
  display: flex;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${props => !props.error ? `color: #807E7D;` : null}

  ${props => props.disabled ? `color: #B2B1B0;` : null}

  ${props => !!props.error ? `color: #CE2C30;` : null}
`

function DefaultInput({
  id, name, label, errorMessage,
  onChange, value, type, placeholder,
  className, prefix, inputEl, disabled, maxLength, ...props
}) {
  const ref = useRef(null);
  const [sufixWidth, setSufixWidth] = useState(0);

  useEffect(() => {
    if (prefix && ref.current.offsetWidth) {
      setTimeout(() => {
        setSufixWidth(ref.current.offsetWidth);
      }, 200)

      setTimeout(() => {
        setSufixWidth(ref.current.offsetWidth);
      }, 400)
    }
  }, [ref.current, prefix]);

  return (
    <InputWrapper className={className} error={!!errorMessage}>
      {label && (<Label error={!!errorMessage} disabled={disabled}>{label}</Label>)}
      <DefaultInputStyled
        style={{ paddingLeft: sufixWidth + 20 }}
        id={id}
        ref={inputEl}
        name={name}
        type={type}
        error={!!errorMessage}
        onChange={onChange}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onWheel={(e) => e.target.blur()}
        {...props}
        maxLength={maxLength}
      />
      {prefix && (<label ref={ref} for={name} className="prefix">{prefix}</label>)}
      <div className="field-error">{errorMessage}</div>
    </InputWrapper>
  )
};

DefaultInput.defaultProps = {
  type: 'text'
}

export default DefaultInput;
